import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { RouteNotFoundComponent } from './shared/components/route-not-found/route-not-found.component';
import { LandingPageLayoutComponent } from './layouts/landing-page-layout/landing-page-layout.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { WorkspacesResolverService } from './core/resolvers/workspaces-resolver.service';
import { DataDeletionInstructionsUrlComponent } from './shared/components/data-deletion-instructions-url/data-deletion-instructions-url.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';

const routes: Routes = [
    // { path: '', redirectTo: 'home', pathMatch: 'full' }, // Redirect accounts path to accounts/login
    // { path: 'login', redirectTo: 'home', pathMatch: 'full' }, // Redirect accounts path to accounts/login
    // { path: 'accounts/login', redirectTo: 'home', pathMatch: 'full' }, // Redirect accounts path to accounts/login
    {
        path: 'create',
        loadChildren: () => import('./modules/use-case-landing-pages/use-case-landing-pages.module').then(m => m.UseCaseLandingPagesModule),
    },
    {
        path: 'features',
        loadChildren: () => import('./modules/features-landing-pages/features-landing-pages.module').then(m => m.FeaturesLandingPagesModule),
    },
    {
        // path: 'hqbludygiblsdblqiuwgdqb',
        path: 'lifetime-deal',
        redirectTo: 'pricing',
        pathMatch: 'full'
        // loadChildren: () => import('./modules/ltd-landing-page/ltd-landing-page.module').then(m => m.LtdLandingPageModule),
    },
    {
        path: 'lifetime-deal/checkout',
        redirectTo: 'pricing',
        pathMatch: 'full'
    },
    {
        path: 'bg-remover',
        redirectTo: 'upload',
        pathMatch: 'full',
        // data: {
        //     canonical: 'https://pixelied.com/bg-remover'
        // },
        // component: MainLayoutComponent,
        // resolve: { message: WorkspacesResolverService },
        // loadChildren: () => import('./modules/bg-remover/bg-remover.module').then(m => m.BgRemoverModule),
    },
    {
        path: 'menu', // ONLY FOR MOBILE UI
        data: {
            canonical: 'https://pixelied.com/menu'
        },
        component: DashboardLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/mobile-menu-nav/mobile-menu-nav.module').then(m => m.MobileMenuNavModule),
    },
    {
        path: 'illustrations',
        data: {
            canonical: 'https://pixelied.com/illustrations'
        },
        component: MainLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/illustrations/illustrations.module').then(m => m.IllustrationsModule),
    },
    {
        path: 'privacy-policy',
        data: {
            title: 'Privacy Policy - Pixelied',
            canonical: 'https://pixelied.com/privacy-policy'
        },
        component: PrivacyPolicyComponent,
    },
    {
        path: 'terms-and-conditions',
        data: {
            title: 'Terms & Conditions - Pixelied',
            canonical: 'https://pixelied.com/terms-and-conditions'
        },
        component: TermsAndConditionsComponent,
    },
    {
        path: 'data-deletion-instructions',
        data: {
            title: 'Data Deletion Instructions - Pixelied',
            canonical: 'https://pixelied.com/data-deletion-instructions'
        },
        component: DataDeletionInstructionsUrlComponent,
    },
    {
        path: 'home',
        data: {
            canonical: 'https://pixelied.com/home'
        },
        component: DashboardLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'admin',
        component: MainLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
    },
    {
        path: 'my-designs',
        data: {
            canonical: 'https://pixelied.com/my-designs'
        },
        component: DashboardLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/my-designs/my-designs.module').then(m => m.MyDesignsModule),
    },
    {
        path: 'files',
        data: {
            canonical: 'https://pixelied.com/files'
        },
        component: DashboardLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/files/files.module').then(m => m.FilesModule)
        // loadChildren: () => import('./modules/my-designs/my-designs.module').then(m => m.MyDesignsModule),
    },
    {
        path: 'brand-presets',
        data: {
            canonical: 'https://pixelied.com/brand-presets'
        },
        component: DashboardLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/brand-presets/brand-presets.module').then(m => m.BrandPresetsModule)
        // loadChildren: () => import('./modules/my-designs/my-designs.module').then(m => m.MyDesignsModule),
    },
    {
        path: 'account-settings',
        component: MainLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/account-settings/account-settings.module').then(m => m.AccountSettingsModule),
    },
    {
        path: 'templates',
        // data: {
        //     canonical: 'https://pixelied.com/templates'
        // },
        component: MainLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/search-templates/search-templates.module').then(m => m.SearchTemplatesModule),
    },
    {
        path: 'mockups',
        // data: {
        //     canonical: 'https://pixelied.com/mockups'
        // },
        component: MainLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/mockups/mockups.module').then(m => m.MockupsModule),
    },
    // {
    //     path: 'svg',
    //     resolve: { message: WorkspacesResolverService },
    //     loadChildren: () => import('./modules/svg-landing-pages/svg-landing-pages.module').then(m => m.SvgLandingPagesModule),
    // },
    {
        path: 'editor',
        data: {
            canonical: 'https://pixelied.com/editor'
        },
        loadChildren: () => import('./modules/editor/editor.module').then(m => m.EditorModule),
    },
    {
        path: 'upload',
        data: {
            canonical: 'https://pixelied.com/upload'
        },
        loadChildren: () => import('./modules/editor/editor.module').then(m => m.EditorModule),
    },
    {
        path: 'preview',
        data: {
            canonical: 'https://pixelied.com/preview'
        },
        loadChildren: () => import('./modules/preview-design/preview-design.module').then(m => m.PreviewDesignModule),
    },
    {
        path: 'workspaces',
        component: DashboardLayoutComponent,
        loadChildren: () => import('./modules/workspaces/workspaces.module').then(m => m.WorkspacesModule),
    },
    {
        path: 'billing',
        component: MainLayoutComponent,
        resolve: { message: WorkspacesResolverService },
        loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule),
    },
    {
        path: '',
        component: LandingPageLayoutComponent,
        loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule),
    },
    // {
    //     path: 'fr-fr',
    //     component: LandingPageLayoutComponent,
    //     loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule),
    // },
    {
        path: '',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    },
    { path: '**', component: RouteNotFoundComponent }];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled', anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })], //, { useHash: true, onSameUrlNavigation: 'reload' }
    exports: [RouterModule]
})
export class AppRoutingModule { }
