<div class="onboarding-wrapper d-flex flex-column align-items-center">
  <div class="navigation-wrapper d-block w-100">
    <span
      class="nav-item"
      (click)="goOneStepBack()"
      [hidden]="onboardingService.prevStepDisabled"
      >< Back</span
    >
    <span
      *ngIf="
        onboardingService.currentOnboardingStep.stepType ==
        StepTypes.STEP_OPTIONS
      "
      class="nav-item float-right"
      (click)="goOneStepForward()"
      [hidden]="onboardingService.nextStepDisabled"
    >
      <span
        *ngIf="onboardingService.isSaving"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      Next ></span
    >

    <span
      *ngIf="
        onboardingService.currentOnboardingStep.stepNo === 4 &&
        onboardingService.currentOnboardingStep.stepType !=
          StepTypes.STEP_OPTIONS
      "
      class="nav-item float-right"
      (click)="goToHomePage()"
      [hidden]="onboardingService.nextStepDisabled"
      >Go To Home</span
    >
  </div>
  <div class="steps-numbers">
    <span>{{ onboardingService.currentOnboardingStep.stepNo }}</span>
    <span>/</span>
    <span>{{ onboardingService.totalSteps }}</span>
  </div>

  <div
    class="steps-lines"
    [class.first-step]="onboardingService.currentOnboardingStep.stepNo === 1"
    [class.second-step]="onboardingService.currentOnboardingStep.stepNo === 2"
    [class.third-step]="onboardingService.currentOnboardingStep.stepNo === 3"
    [class.fourth-step]="onboardingService.currentOnboardingStep.stepNo === 4"
    [class.fifth-step]="onboardingService.currentOnboardingStep.stepNo === 5"
  >
    <span class="line" *ngFor="let step of [1, 2, 3, 4, 5]"></span>
  </div>

  <div class="step-title d-flex flex-column align-items-center">
    <h3 [class.h3-sm]="onboardingService.currentOnboardingStep.stepNo == 4">
      {{ onboardingService.currentOnboardingStep.title }}
    </h3>
    <p>{{ onboardingService.currentOnboardingStep.description }}</p>
  </div>
  <div
    class="step-options"
    [class.source-step-styles]="
      onboardingService.currentOnboardingStep.stepId == 'source'
    "
    [class.usage-step-styles]="
      onboardingService.currentOnboardingStep.stepId == 'usage'
    "
    [class.social-media-mockups-step-styles]="
      onboardingService.currentOnboardingStep.stepId ==
        'social-media-formats' ||
      onboardingService.currentOnboardingStep.stepId == 'mockup-categories'
    "
    *ngIf="
      onboardingService.currentOnboardingStep.stepType == StepTypes.STEP_OPTIONS
    "
  >
    <app-onboarding-create-workspace
      *ngIf="
        onboardingService.currentOnboardingStep.stepId == 'create-workspace'
      "
    ></app-onboarding-create-workspace>
    <div
      class="option"
      [appOnboardingOptionStates]="option"
      [ngClass]="
        addClassesToOptions(option, onboardingService.currentOnboardingStep)
      "
      [class.hover-instagram]="option.option === 'Instagram'"
      (click)="selectOption(option)"
      *ngFor="let option of onboardingService.currentOnboardingStep.stepOptions"
    >
      <div *ngIf="option.imgURL" class="img-container">
        <img [src]="option.imgURL" alt="" />
      </div>
      <span>{{ option.option }}</span>
    </div>
  </div>

  <div class="other-input-form" *ngIf="onboardingService.inputFieldShown">
    <input
      (keyup.enter)="goOneStepForward()"
      class="form-control form-control-lg"
      type="text"
      [(ngModel)]="onboardingService.inputField"
      placeholder="Other"
    />
  </div>

  <div
    *ngIf="
      onboardingService.currentOnboardingStep.stepType ==
      StepTypes.TEMPLATE_SELECTION
    "
  >
    <div
      *ngIf="
        onboardingService.currentOnboardingStep.stepId ===
        StepIds.BANNER_TEMPLATES_SELECTION
      "
      class="banner-formats d-flex flex-row justify-content-center mb-3"
    >
      <div
        (click)="getTemplatesByBannerFormat(format)"
        class="format position-relative"
        *ngFor="
          let format of onboardingService.currentOnboardingStep.bannerFormats
        "
      >
        <span
          *ngIf="selectedBannerFormat.title == format.title"
          class="underline position-absolute"
        ></span>
        <span [class.active]="selectedBannerFormat.title == format.title">{{
          format.title
        }}</span>
      </div>
    </div>

    <div class="templates-list-wrapper">
      <div
        *ngIf="onboardingService.templatesLoading"
        class="text-center w-100 p-3"
      >
        <div class="data-loader"></div>
      </div>
      <div
        [style.width.px]="template.thumbnail_height < 310 ? 270 : 162"
        *ngFor="
          let template of onboardingService.currentOnboardingStep.templatesList
        "
      >
        <div
          style="position: relative; margin-bottom: 8px; margin-right: 8px"
          [style.padding-bottom.%]="
            (template.canvas_doc_json?.doc_height /
              template.canvas_doc_json?.doc_width) *
            100
          "
        >
          <div class="img-container">
            <a
              class="w-100 h-100"
              (click)="saveUserOnboardingDataForTemplateSelection()"
              [routerLink]="['/editor/template/' + template._id]"
            >
              <img
                class="img-opacity-fadein img-thumb"
                [lazyLoad]="template.thumbnail_url"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="onboardingService.currentOnboardingStep.stepType == StepTypes.UPLOAD"
    class="upload-wrapper"
  >
    <div
      (uploadImageModalCreate)="fileDrop($event)"
      class="files-upload-box position-relative d-flex flex-column align-items-center justify-content-center"
    >
      <input
        class="file-input"
        type="file"
        accept="image/*"
        (change)="readImageFile($event.target.files)"
      />
      <img
        *ngIf="
          onboardingService.currentOnboardingStep.stepId === 'image-editing' ||
          onboardingService.currentOnboardingStep.stepId === 'bg-remover'
        "
        src="https://static-assets.pixelied.com/onboarding/images/image-upload-graphic.svg"
        alt=""
      />
      <img
        *ngIf="onboardingService.currentOnboardingStep.stepId === 'svg-editing'"
        src="https://static-assets.pixelied.com/onboarding/images/svg-upload-graphic.svg"
        alt=""
      />
      <button
        [disabled]="onboardingService.imageUploading"
        class="upload-btn btn btn-primary"
      >
        <img
          style="width: 18px; height: 18px; margin-right: 4px"
          src="https://static-assets.pixelied.com/onboarding/images/upload-sign.svg"
          alt=""
        />
        <span
          *ngIf="onboardingService.imageUploading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Upload
        {{
          onboardingService.currentOnboardingStep.stepId === "image-editing" ||
          onboardingService.currentOnboardingStep.stepId === "bg-remover"
            ? "Image"
            : "SVG"
        }}
      </button>
      <span class="drag-text">or drop your image here</span>
    </div>
  </div>
</div>
