import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { UserService } from 'src/app/modules/auth/data/user.service';
import { FinalPlan } from 'src/app/modules/ltd-landing-page/ltd-checkout/data/ltd-billing.service';
import { Constants } from 'src/app/shared/data/constants';
import { WorkspacesStoreService } from './workspaces-store.service';

// declare let gtag: Function;
@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsEventsService {
    constructor(
        private _userService: UserService,
        private gtmService: GoogleTagManagerService,
        private _workspacesStoreService: WorkspacesStoreService) { }


    public ltdCheckoutStepsEvents(finalPlan: FinalPlan, stepNo: number) {
        let products = [];
        products.push(
            {
                'name': finalPlan.planSelected.priceId, // Name or ID is required.
                'id': finalPlan.planSelected.priceId,
                'price': finalPlan.planSelected.price,
                'brand': 'Pixelied',
                'category': 'LTD Plan',
                'quantity': 1,
                // 'coupon': '' // Optional fields may be omitted or set to empty string.
            }
        )
        this.gtmService.pushTag({ ecommerce: null }).catch((e) => { });
        this.gtmService.pushTag({
            'event': 'checkout',
            'ecommerce': {
                'currencyCode': 'USD',
                'checkout': {
                    'actionField': { 'step': stepNo },
                    'products': products
                }
            }
        }).catch((e) => { });
    }

    public sendPurchaseEvent(finalPlan: FinalPlan, paymentIntentId: string) {
        let products = [];
        products.push(
            {
                'name': finalPlan.planSelected.priceId,     // Name or ID is required.
                'id': finalPlan.planSelected.priceId,
                'price': finalPlan.planSelected.price,
                'brand': 'Pixelied',
                'category': 'LTD Plan',
                // 'variant': 'Gray',
                'quantity': 1,
                // 'coupon': '' // Optional fields may be omitted or set to empty string.
            }
        )
        if (finalPlan.extraWorkspaces > 0) {
            products.push(
                {
                    'name': 'addon:workspace',
                    'id': 'addon:workspace',
                    'price': Constants.PIXELIED_LTD_PLANS.LTD_WORKSPACE_ADDON.price,
                    'brand': 'Pixelied',
                    'category': 'LTD Addons',
                    'quantity': finalPlan.extraWorkspaces
                }
            )
        }
        if (finalPlan.extraBgRemoveCreditsCount > 0) {
            products.push(
                {
                    'name': 'addon:bg_remove_credits',
                    'id': 'addon:bg_remove_credits',
                    'price': Constants.PIXELIED_LTD_PLANS.LTD_BG_REMOVER_CREDITS_ADDON.price,
                    'brand': 'Pixelied',
                    'category': 'LTD Addons',
                    'quantity': finalPlan.extraBgRemoveCreditsCount
                }
            )
        }
        if (finalPlan.extraTeamMembers > 0) {
            products.push(
                {
                    'name': 'addon:team_members',
                    'id': 'addon:team_members',
                    'price': Constants.PIXELIED_LTD_PLANS.LTD_TEAM_MEMBER_ADDON.price,
                    'brand': 'Pixelied',
                    'category': 'LTD Addons',
                    'quantity': finalPlan.extraTeamMembers
                }
            )
        }
        this.gtmService.pushTag({ ecommerce: null }).catch((e) => { });
        this.gtmService.pushTag({
            'event': 'purchase',
            'ecommerce': {
                'currencyCode': 'USD',
                'purchase': {
                    'actionField': {
                        'id': paymentIntentId, // Transaction ID. Required for purchases and refunds.
                        // 'affiliation': 'Online Store',
                        'revenue': finalPlan.totalPrice, // Total transaction value (incl. tax and shipping)
                        // 'coupon': 'SUMMER_SALE'
                    },
                    'products': products // List of productFieldObjects.
                }
            }
        }).catch((e) => { });
        // this.gtmService.pushTag({
        //     'event': 'checkout',
        //     'ecommerce': {
        //         'checkout': {
        //             'actionField': { 'step': 1, 'option': 'Visa' },
        //             'products': [{
        //                 'name': 'Triblend Android T-Shirt',
        //                 'id': '12345',
        //                 'price': '15.25',
        //                 'brand': 'Google',
        //                 'category': 'Apparel',
        //                 'variant': 'Gray',
        //                 'quantity': 1
        //             }]
        //         }
        //     }
        // });
    }

    public eventEmitter(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null, showUserStatus: boolean = true) {
        // console.log('ga event called');
        let userSub = 'FREE';
        if (this._userService.isUserLifetimePro) userSub = 'LTD';
        else if (this._userService.getUserSubscription() === Constants.USER_NOT_LOGGED_IN) userSub = Constants.USER_NOT_LOGGED_IN;
        else if (this._workspacesStoreService.canUserAccessProFeatures()) userSub = 'PRO';
        // if (this._userService.getUserSubscription() === 'free') {
        //     userSub = 'FREE';
        // } else if (this._userService.getUserSubscription() === 'pro') {
        //     userSub = 'PRO';
        // } else if (this._userService.getUserSubscription() === Constants.USER_NOT_LOGGED_IN) {
        //     userSub = Constants.USER_NOT_LOGGED_IN;
        // }
        // gtag('event', `${userSub} - ${eventAction}`, {
        //     event_category: eventCategory,
        //     event_label: eventLabel,
        //     value: eventValue
        // });
        // let event = `${eventAction}`;
        // if (showUserStatus) event = `${userSub} - ${eventAction}`;
        let gtmTag = {
            // event: showUserStatus ? `${userSub} - ${eventAction}` : `${eventAction}`,
            event: `${userSub} - ${eventAction}`,
            // event: event,
            event_category: eventCategory,
            event_label: eventLabel,
            value: eventValue
        }
        // console.log(gtmTag);
        this.gtmService.pushTag(gtmTag).catch((e) => { });
    }
}
