import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {
    constructor(private _http: HttpClient) { }

    public getWorkspace(workspace_id: string, user_id: string) {
        return this._http.get(`${environment.apiUrl}/api/workspaces/${workspace_id}/${user_id}`);
    }

    public createWorkspace({ name, ownerId }: { name: string, ownerId: string }) {
        return this._http.post(`${environment.apiUrl}/api/workspaces`, { workspace: { name, owner: ownerId } });
    }

    public updateWorkspace(workspace_id: string, workspace_data: any) {
        return this._http.put(`${environment.apiUrl}/api/workspaces/${workspace_id}/details`, workspace_data);
    }

    public deleteWorkspace(workspace_id: string) {
        return this._http.delete(`${environment.apiUrl}/api/workspaces/${workspace_id}`);
    }

    public inviteTeamMembers(member_name: string, member_email: string, workspace_id: string, inviter_id: string) {
        return this._http.put(`${environment.apiUrl}/api/workspaces/${workspace_id}/member-invite/${inviter_id}`, { member_email, member_name });
    }

    public sendAnotherInvitation(member_name: string, member_email: string, workspace_id: string, inviter_id: string) {
        return this._http.post(`${environment.apiUrl}/api/workspaces/${workspace_id}/send-member-invite/${inviter_id}`, { member_email, member_name });
    }

    public updateMemberStatus(workspace_id: string, member_id: string, member_email: string, status: string) {
        return this._http.put(`${environment.apiUrl}/api/workspaces/${workspace_id}/members/${member_email}`, { member_id, status });
    }

    public deleteMemberFromWorkspace(workspace_id: string, member_email: string) {
        return this._http.delete(`${environment.apiUrl}/api/workspaces/${workspace_id}/members/${member_email}`);
    }
}
