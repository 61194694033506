export interface Onboarding {
  steps: OnboardingStep[];
}

export class UserOnboarding {
  _id?: string;
  user_id?: string;
  user_onboarding: UserOnboardingData[];
}

export interface UserOnboardingData {
  stepId: StepIds;
  values: StepOption[];
}

export class OnboardingStep {
  stepId: string;
  title: string;
  description?: string;
  multiSelection?: boolean = false;
  stepNo?: number;
  stepType: StepTypes = StepTypes.STEP_OPTIONS;
  bannerFormats?: Format[]; // will only be used when user selects BANNER TEMPLATES
  templatesList?: [];
  uploadType?: UploadTypes; // can be bg_remover, image_editing, svg_editing
  style?: string; // can be type_one or type_two
  stepOptions?: StepOption[];
  constructor() { }
}

export interface Format {
  title: string;
  format: string;
}

export interface StepOption {
  option: string;
  value: string;
  cssClass?: string;
  selected?: boolean;
  imgURL?: string;
  goToUrl?: string;
}

export enum StepIds {
  CREATE_WORKSPACE = 'create-workspace',
  USER_DESCRIPTION = 'user-description',
  SOURCE = 'source',
  USAGE = 'usage',
  SOCIAL_MEDIA_FORMATS = 'social-media-formats',
  MOCKUP_CATEGORIES = 'mockup-categories',
  IMAGE_EDITING = 'image-editing',
  BG_REMOVER = 'bg-remover',
  SVG_EDITING = 'svg-editing',
  // ECOMMERCE = 'e-commerce',
  // BANNERS = 'banners',
  OTHER = 'other',
  BANNER_TEMPLATES_SELECTION = 'banner-templates-selection',
  ECOMMERCE_TEMPLATES_SELECTION = 'ecommerce-templates-selection'
}

export enum UploadTypes {
  BG_REMOVER,
  IMAGE_EDITING,
  SVG_EDITING
}

export enum StepTypes {
  STEP_OPTIONS = 'step-options',
  UPLOAD = 'upload',
  TEMPLATE_SELECTION = 'template-selection'
}