import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "src/app/modules/auth/data/user.service";
import { CTAOptions, FeatureDataService, FeaturePageData } from "src/app/modules/features-landing-pages/data/feature-data.service";
import { Constants } from "src/app/shared/data/constants";
import { GoogleAnalyticsEventsService } from "./google-analytics-events.service";
import { HelpersService } from "./helpers.service";
import { LocalStorageService } from "./local-storage.service";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(
    private _router: Router,
    private _gaEventsService: GoogleAnalyticsEventsService,
    private _featureDataService: FeatureDataService,
    private _userService: UserService,
    private _localStorage: LocalStorageService,
    private _helpersService: HelpersService,
    private _toastService: ToastService,
    private _http: HttpClient) { }

  uploadUserImage = (body): Observable<any> => {
    return this._http.post("/api/landing-pages/uploads/image", body);
  }

  uploadUserSVG = (body): Observable<any> => {
    return this._http.post("/api/landing-pages/uploads/vector", body);
  }

  readImageFile = (fileInput: File, featurePageData: FeaturePageData): void => {
    // console.log(fileInput);
    // if (!featurePageData.ctaOptions.isFileUpload && !featurePageData.ctaOptions.svgFileUploadAllowed) {
    //   let queryParams: any = { width: 1000, height: 1000, units: 'px' };
    //   queryParams.refId = featurePageData.ctaOptions.queryParams.refId;
    //   if (featurePageData.ctaOptions.queryParams.toolRefId) queryParams.toolRefId = featurePageData.ctaOptions.queryParams.toolRefId;
    //   this._router.navigate(['/editor'], { queryParams: queryParams });
    //   return;
    // }
    if (!this.ifFileIsOfImageType(fileInput, featurePageData.ctaOptions.svgFileUploadAllowed)) {
      let msg = `Only PNG and JPG files are allowed`;
      if (featurePageData.ctaOptions.svgFileUploadAllowed) msg = `Only SVG files are allowed`;
      this._toastService.show(msg, { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    if (!this.checkFileSize(fileInput)) {
      this._toastService.show(`File size cannot be more than ${Constants.MAX_IMAGE_FILE_UPLOAD} MB`, { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    this._featureDataService.featurePageData.isUploading = true;
    let urlImageExtension = fileInput.type;
    this._gaEventsService.eventEmitter('feature-landing-pages', `${featurePageData.imagesUrlKeyword}`, `Upload image: ${urlImageExtension}`, null, false);
    if (urlImageExtension == "image/jpeg" || urlImageExtension == "image/png") {
      this.processPNGAndJPEGImageFile(fileInput, urlImageExtension, featurePageData.ctaOptions);
    } else if (urlImageExtension == "image/svg+xml") {
      this.processSVGFile(fileInput, urlImageExtension, featurePageData.ctaOptions);
    }
  }

  ifFileIsOfImageType(file: File, svgFileUploadAllowed: boolean): boolean {
    if (file) {
      let fileExtension = file.type;
      if ((fileExtension == 'image/jpeg' || fileExtension == 'image/png') && svgFileUploadAllowed) return false;
      if (fileExtension == 'image/svg+xml' && svgFileUploadAllowed) return true;
      if ((fileExtension != 'image/jpeg' && fileExtension != 'image/png')) return false;
      return true;
    }
    return false;
  }

  checkFileSize(file: File): boolean {
    if (file.size / 1000 / 1000 > Constants.MAX_IMAGE_FILE_UPLOAD) {
      return false;
    }
    return true;
  }

  processSVGFile(file: File, urlImageExtension: string, ctaOptions: CTAOptions) {
    const reader = new FileReader();
    reader.readAsText(file); // read file as text
    reader.onload = (event: any) => {
      // called once readAsText is completed
      let svgData = event.target.result;
      if (this._helpersService.isSVGString(svgData)) {
        // console.log(svgData);
        this.onSVGUploadSuccess(svgData, ctaOptions);
      } else {
        this._toastService.show(`Upload Error: SVG file may be corrupted`, { classname: 'bg-danger text-light', delay: 5000 });
      }
    };
  }

  onSVGUploadSuccess(svgData: string, ctaOptions: CTAOptions) {
    this._featureDataService.featurePageData.isUploading = false;
    this._localStorage.setItem(Constants.SVG_UPLOAD_FEATURE_PAGE_LOCALSTORAGE, JSON.stringify({
      svgData: svgData,
    }));
    let queryParams: any = { width: 1000, height: 1000, units: 'px' };
    queryParams.refId = ctaOptions.queryParams.refId;
    if (ctaOptions.queryParams.toolRefId) queryParams.toolRefId = ctaOptions.queryParams.toolRefId;
    this._router.navigate(['/editor'], { queryParams: queryParams });
  }

  processPNGAndJPEGImageFile(file: File, urlImageExtension: string, ctaOptions: CTAOptions) {
    const imageFile = <File>file;
    const formData = new FormData();
    formData.append("file", imageFile, imageFile.name);
    formData.append("image_type", urlImageExtension);
    // formData.append("user_id", this._userService.getUserDetailsFromJWT()?._id || "");
    this.uploadUserImage(formData).subscribe((data: any) => {
      this.onPNGAndJPEGImageUploadSuccess(data, ctaOptions);
    }, (error: any) => {
      this._featureDataService.featurePageData.isUploading = false;
      this._toastService.show(`Upload Error: Image file may be corrupted or not supported`, { classname: 'bg-danger text-light', delay: 5000 });
      // throw error;
    });
  }

  onPNGAndJPEGImageUploadSuccess(data: any, ctaOptions: CTAOptions) {
    // console.log(data);
    this._featureDataService.featurePageData.isUploading = false;
    this._localStorage.setItem(Constants.IMAGE_UPLOAD_FEATURE_PAGE_LOCALSTORAGE, JSON.stringify({
      url: data.imageURL,
      width: data.imageWidth,
      height: data.imageHeight,
      size: data.imageSize,
      mimetype: data.imageMimeType
    }));
    let queryParams: any = { width: data.imageWidth, height: data.imageHeight, units: 'px' };
    queryParams.refId = ctaOptions.queryParams.refId;
    if (ctaOptions.queryParams.toolRefId) queryParams.toolRefId = ctaOptions.queryParams.toolRefId;
    this._router.navigate(['/editor'], { queryParams: queryParams });
  }
}