import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { isPlatformBrowser } from '@angular/common';
import { User } from './user.model';
const helper = new JwtHelperService();
import * as Sentry from "@sentry/angular";
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Workspace } from 'src/app/shared/data/workspaces/workspace.model';
import { Constants } from 'src/app/shared/data/constants';
import { SocialUser } from 'angularx-social-login';

declare const FB: any;

@Injectable({
    providedIn: 'root'
})
export class UserService {

    // user: Subject<User> = new Subject();

    isUserLifetimePro: boolean = false;
    user: BehaviorSubject<User>;
    login: Subject<boolean> = new Subject();
    logout: Subject<boolean> = new Subject();

    // API_URL = environment.apiUrl;
    constructor(private _http: HttpClient,
        private _router: Router,
        private _localStorage: LocalStorageService,
        // private _socialAuthService: SocialAuthService,
        @Inject(PLATFORM_ID) private platformId) {
        this.user = new BehaviorSubject<User>(this.decodeAuthTokenAndReturnUser());
        if (Constants.LTD_PRO_TAGS.indexOf(this.user.getValue()?.user_subscription) !== -1) this.isUserLifetimePro = true;
        else this.isUserLifetimePro = false;
        // console.log('isUserLifetimePro: ', this.isUserLifetimePro)
        // FB.init({
        //     appId: '652666675581889',
        //     // status: false,
        //     cookie: false,
        //     xfbml: false,
        //     version: 'v7.0'
        // });
        // this._socialAuthService.authState.subscribe((user) => {
        //     console.log(user)
        //     if (!user) {
        //         _socialAuthService.signOut();
        //     }
        // })
        // this.user.subscribe((user: User) => {
        //     if (!user) {
        //         _socialAuthService.signOut();
        //     }
        // });
    }


    private decodeAuthTokenAndReturnUser = () => {
        if (!this._localStorage.getItem('token')) {
            return null;
        }
        return helper.decodeToken(this._localStorage.getItem('token'))['user'];
    }

    attachUserScopeToSentryLogging(user: User = null): void {
        if (!user) {
            user = this.getUserDetailsFromJWT();
        }
        if (user) {
            // this.setUser(user);
            Sentry.configureScope((scope) => {
                scope.setUser({
                    'id': user._id,
                    'email': user.email
                });
            });
        }
    }

    setUser(user: User): void {
        this.login.next(true);
        this.user.next(user);
        this._localStorage.setItem('design_created', String(user.design_created) || "false");
        if (Constants.LTD_PRO_TAGS.indexOf(this.user.getValue().user_subscription) !== -1) this.isUserLifetimePro = true;
        else this.isUserLifetimePro = false;
    }

    deleteUserData(): void {
        this.user = null;
    }

    hasUserData(): boolean {
        return this.user && this.user !== null;
    }

    getFirstTimeWalkthrough() {
        if (isPlatformBrowser(this.platformId)) {
            return JSON.parse(localStorage.getItem('firstTimeWalkthrough')) == null ? true : false;
        }
    }

    getMockupsWalkthrough() {
        if (isPlatformBrowser(this.platformId)) {
            return JSON.parse(localStorage.getItem('mockupsWalkthrough')) == null ? true : false;
        }
    }

    googleLogin(socialUser: SocialUser, registeredFromCheckoutPage: boolean = false) {
        return new Promise((resolve, reject) => {
            return this._http
                .post(`${environment.apiUrl}/api/accounts/auth/google`, { access_token: socialUser.authToken, id_token: socialUser.idToken, registeredFromCheckoutPage })
                .toPromise()
                .then((response: any) => {
                    const token = response.token;
                    if (token) {
                        if (isPlatformBrowser(this.platformId)) {
                            this._localStorage.setItem('token', token);
                        }
                    }
                    resolve(response);
                })
                .catch(() => reject());
        });
    }

    fbLogin(registeredFromCheckoutPage: boolean = false) {
        if (typeof FB === 'undefined') {
            return new Promise((resolve, reject) => {
                reject();
            });
        } else {
            FB.init({
                appId: '652666675581889',
                // status: false,
                cookie: false,
                xfbml: false,
                version: 'v12.0'
            });
            return new Promise((resolve, reject) => {
                FB.login(result => {
                    // console.log(result);
                    if (result.authResponse) {
                        return this._http
                            .post(`${environment.apiUrl}/api/accounts/auth/facebook`, { access_token: result.authResponse.accessToken, registeredFromCheckoutPage })
                            .toPromise()
                            .then((response: any) => {
                                const token = response.token;
                                if (token) {
                                    if (isPlatformBrowser(this.platformId)) {
                                        localStorage.setItem('token', token);
                                    }
                                    // localStorage.setItem('id_token', JSON.stringify(token));
                                }
                                resolve(response);
                            })
                            .catch(() => reject());
                    } else {
                        // console.log('reject');
                        reject();
                    }
                }, { scope: 'public_profile,email' });
            });
        }
    }

    public registerNewUser(user: any) {
        return this._http.post(`${environment.apiUrl}/api/accounts/register`, user);
    }

    public registerNewUserThroughInvitation(user: any, workspace_id: string, member_email: string) {
        return this._http.post(`${environment.apiUrl}/api/accounts/register/invite/${workspace_id}/${member_email}`, user);
    }

    public appsumoRegisterNewUser(user: any) {
        return this._http.post(`${environment.apiUrl}/api/accounts/register/appsumo`, user);
    }

    public vdRegisterNewUser(user: any) {
        return this._http.post(`${environment.apiUrl}/api/accounts/register/viededingue`, user);
    }

    public loginUser(email: string, password: string) {
        // console.log(environment.apiUrl);
        return this._http.post<any>(`${environment.apiUrl}/api/accounts/login`, { email, password });
    }

    public forgotPassword(email: string) {
        return this._http.post<any>('/api/accounts/forgot-password', { email });
    }

    public resetPassword(password: string, confirmPassword: string, forgetPasscode: string, emailValidated: string = null) {
        return this._http.post<any>('/api/accounts/reset-password', { password, confirmPassword, forgetPasscode, emVl: emailValidated });
    }

    public verifyUserEmail(forgetPasscode: string) {
        return this._http.get('/api/accounts/verify?id=' + forgetPasscode);
    }

    public dashboard() {
        return this._http.get(`${environment.apiUrl}/api/dashboard`);
    }

    public loggedIn() {
        if (isPlatformBrowser(this.platformId)) {
            return !!localStorage.getItem('token') && !helper.isTokenExpired(localStorage.getItem('token'));
        }
        return false;
    }

    public getToken() {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem('token')
        }
    }

    public logoutUserSession(navigateToLogin: boolean = true) {
        this._localStorage.removeItem('token');
        // this._localStorage.removeItem('currentWorkspace');
        // this._localStorage.removeItem('currentWorkspaceId');
        // this._localStorage.removeItem('currentWorkspaceName');
        // if(this._socialAuthService.authState)
        // this._socialAuthService.signOut();
        // this._workspacesStoreService.deleteUserWorkspaces();
        // this.deleteUserWorkspaces();
        // if (!navigateToLogin) this.user.next(null);
        if (navigateToLogin) this._router.navigate(['/login']);
        this.logout.next(true);
        this.user.next(null);
        try {
            FB.getLoginStatus(function (response) {
                // console.log(response)
                if (response.status == 'connected') {
                    FB.logout((res) => { })
                }
            });
        } catch (e) { }
    }

    public removeToken() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('token')
        }
    }

    public getUserDetailsFromJWT(): User {
        if (!this._localStorage.getItem('token')) {
            return null;
        }
        return helper.decodeToken(this._localStorage.getItem('token'))['user'];
    }

    public getUserRole() {
        if (isPlatformBrowser(this.platformId)) {
            if (!localStorage.getItem('token')) {
                // return 'not_logged_in';
                return Constants.USER_NOT_LOGGED_IN;
            }
            return helper.decodeToken(localStorage.getItem('token'))['user']['role'];
        }
    }

    public getUserSubscription() {
        if (isPlatformBrowser(this.platformId)) {
            if (!localStorage.getItem('token')) {
                return Constants.USER_NOT_LOGGED_IN;
            }
            return helper.decodeToken(localStorage.getItem('token'))['user']['user_subscription'];
        }
    }

    public getUserId() {
        if (isPlatformBrowser(this.platformId)) {
            if (!this._localStorage.getItem('token')) {
                return Constants.USER_NOT_LOGGED_IN;
            }
            return helper.decodeToken(this._localStorage.getItem('token'))['user']['_id'] || Constants.USER_NOT_LOGGED_IN;
        }
    }

    public getUserBgCredits(user_id: string) {
        return this._http.get(`${environment.apiUrl}/api/image-bg-remover/user-bg-remove-credits/${user_id}`);
    }

    public getUserWorkspaces(userId: String): Observable<Workspace[]> {
        return this._http.get<Workspace[]>(`${environment.apiUrl}/api/workspaces/users/${userId}/workspaces`); //?allInfo=${getAllInfo}
    }

    public changeUserWorkspace(workspace_id: String, user_id) {
        return this._http.put(`${environment.apiUrl}/api/workspaces/change-workspace`, { workspace_id, user_id });
    }

    public getUserDetails(userId: string) {
        return this._http.get(`/api/accounts/users/${userId}`);
    }

    public updateUserDetails(userId: string, first_name: string, last_name: string) {
        return this._http.put(`/api/accounts/users/${userId}`, { first_name, last_name }, { headers: { 'Content-Type': 'application/json' } });
    }

    public deleteAccount(userId: string) {
        return this._http.delete(`/api/accounts/users/${userId}/delete-account`);
    }

    public changeUserPassword(userId: string, old_password: string, new_password: string, confirm_new_password: string) {
        return this._http.put(`/api/accounts/users/change-password/${userId}`, { old_password, new_password, confirm_new_password }, { headers: { 'Content-Type': 'application/json' } });
    }

}

