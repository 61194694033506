import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkspacesStoreService } from 'src/app/core/services/workspaces-store.service';
import { UserService } from 'src/app/modules/auth/data/user.service';
import { ProCheckoutModalComponent } from 'src/app/shared/components/pro-checkout-modal/pro-checkout-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private _modalService: NgbModal,
    public _userService: UserService,
    public _workspacesStoreService: WorkspacesStoreService,
  ) { }

  ngOnInit(): void {
  }

  openProCheckoutModal() {
    this._modalService.open(ProCheckoutModalComponent, { size: 'lg', centered: true, modalDialogClass: 'modal-upgrade' }).componentInstance.selectedFeature = 'Upgrade button clicked from home navbar';
  }

}
