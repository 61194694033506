import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { WorkspaceBillingService } from 'src/app/core/services/workspace-billing.service';
import { Constants } from '../../data/constants';

@Component({
  selector: 'app-pro-checkout-modal',
  templateUrl: './pro-checkout-modal.component.html',
  styleUrls: ['./pro-checkout-modal.component.scss']
})
export class ProCheckoutModalComponent implements OnInit {

  @Input() downgradeToProDisabled: boolean = false;
  @Input() planTwoSelected: boolean = false;
  @Input() selectedFeature: string = '';
  constructor(
    public activeModal: NgbActiveModal,
    public workspaceBillingService: WorkspaceBillingService,
    private _localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.workspaceBillingService.selectedFeature = this.selectedFeature;
    // let invoiceTimePeriod = this._localStorageService.getItem('invoiceTimePeriod');
    // let currTime = new Date().getTime();
    // if (invoiceTimePeriod == null) {
    //   this.workspaceBillingService.clearCache();
    // } else {
    //   if (currTime > Number(invoiceTimePeriod)) {
    //     this.workspaceBillingService.clearCache();
    //   }
    // }

    /**
     * Clear previous cache if any
     */
    this.workspaceBillingService.clearCache();

    this.workspaceBillingService.resetUI();
    this.workspaceBillingService.setInitialUI();
    this.workspaceBillingService.setTotalActiveTeamMembers();

    if (this.planTwoSelected) {
      // this.workspaceBillingService.downgradeToProDisabled = true;
      this.workspaceBillingService.setSelectedPlan(this.workspaceBillingService.planTwoDetails);
    }
    if (this.downgradeToProDisabled) {
      this.workspaceBillingService.downgradeToProDisabled = true;
    }

    if (!this.workspaceBillingService._userService.user.getValue().stripe_customer_id) {
      this.workspaceBillingService.createStripeCustomer();
    }

    if (this.workspaceBillingService.isScriptLoaded(Constants.STRIPE_SCRIPT_URL)) {
      this.workspaceBillingService.initStripeElements();
    } else {
      this.workspaceBillingService.initStripe();
    }

    // console.log(this.workspaceBillingService.planSelected);
    // console.log(this.workspaceBillingService.planOneDetails);
    // console.log(this.workspaceBillingService.planTwoDetails);

    // console.log(this._localStorageService.getItem('promoCode'))
    // if (this._localStorageService.getItem('promoCode') != null) {
    // this.workspaceBillingService.promoCheck = true;
    // this.workspaceBillingService.promoCode = this._localStorageService.getItem('promoCode') || 'blackfriday70';
    // this.workspaceBillingService.applyPromoCode();
    // this._localStorageService.removeItem('promoCode');
    // }
  }

  closeModal() {
    this.activeModal.dismiss('Cross click');
  }

  ngOnDestroy() {
    this.planTwoSelected = false;
  }


}
