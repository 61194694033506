import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { WorkspacesStoreService } from '../services/workspaces-store.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspacesResolverService implements Resolve<any> {
  constructor(private _workspacesStoreService: WorkspacesStoreService) { }
  resolve(route: ActivatedRouteSnapshot): Promise<string> {
    return new Promise((resolve, reject) => {
      // console.log('Workspaces RESOLVER...', route);
      // setTimeout(() => {
      this._workspacesStoreService.initWorkspacesStore().then((data) => { return resolve(data) });
      // }, 10000)
    });
    // return this.product.getProducts().pipe(
    //   catchError(error => {
    //     return of('No data');
    //   })
    // );
  }
}