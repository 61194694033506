import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/modules/auth/data/user.service';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

    constructor(private _userService: UserService, private _router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('token interc called');
        // console.log(req.url);
        if (!req.url.startsWith('https://hooks.slack.com') &&
            !req.url.includes('img-utils-conversions.pixelied.com') &&
            !req.url.includes('cdn.pixelied.com') &&
            !req.url.includes('vl-graphics-bucket-s3.s3.amazonaws.com')
        ) { //&& !req.url.startsWith('https://cdn.pixelied.com')
            req = req.clone({
                setHeaders: {
                    'Authorization': `Bearer ${this._userService.getToken()}`,
                    // 'Content-Type': 'application/json'
                }
            });
        }
        return next.handle(req);
    }
}
