import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from 'src/app/modules/auth/data/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private _userService: UserService, private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this._userService.loggedIn()) {
            return true;
        }
        this._userService.logoutUserSession(false);
        this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
        return false;
    }

}
