<div
  [class.d-block]="workspaceBillingService.viewType == 'checkout'"
  [class.d-none]="workspaceBillingService.viewType != 'checkout'"
  class="modal-body position-relative"
>
  <button
    (click)="closeModal()"
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
  >
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.39107 11.7206C-0.130356 12.242 -0.130357 13.0874 0.39107 13.6088C0.912496 14.1302 1.7579 14.1302 2.27932 13.6088L7.00005 8.88808L11.721 13.609C12.2424 14.1304 13.0878 14.1304 13.6092 13.609C14.1306 13.0876 14.1306 12.2422 13.6092 11.7207L8.8883 6.99983L13.6088 2.27932C14.1303 1.75789 14.1303 0.912493 13.6088 0.391068C13.0874 -0.130357 12.242 -0.130356 11.7206 0.391069L7.00005 5.11158L2.27972 0.391246C1.75829 -0.13018 0.912894 -0.13018 0.391469 0.391246C-0.129957 0.912671 -0.129956 1.75807 0.39147 2.2795L5.1118 6.99983L0.39107 11.7206Z"
        fill="#A6ACBE"
      />
    </svg>
  </button>

  <div class="row no-gutters align-items-center">
    <div class="col-md-6">
      <div class="upgrade-left">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <div class="upgrade-tabs d-flex">
            <button
              (click)="
                workspaceBillingService.setSelectedPlan(
                  workspaceBillingService.planOneDetails
                )
              "
              [class.active]="
                workspaceBillingService.planSelected?.id ==
                workspaceBillingService.planOneDetails.id
              "
              class="btn btn-blue"
            >
              Pro
            </button>
            <button
              (click)="
                workspaceBillingService.setSelectedPlan(
                  workspaceBillingService.planTwoDetails
                )
              "
              [class.active]="
                workspaceBillingService.planSelected?.id ==
                workspaceBillingService.planTwoDetails.id
              "
              class="btn btn-yellow"
            >
              Pro+
            </button>
          </div>
          <button
            *ngIf="!workspaceBillingService.downgradeToProDisabled"
            (click)="workspaceBillingService.changeViewType('compare-plans')"
            class="btn btn-compare"
          >
            Compare Plans
            <img
              src="https://static-assets.pixelied.com/billing/forward-arrow.svg"
              alt="arrow"
            />
          </button>
        </div>

        <div class="upgrade-heading">
          <h2 class="d-flex align-items-center">
            <img
              [src]="
                workspaceBillingService.planSelected?.id ==
                workspaceBillingService.planOneDetails.id
                  ? 'https://static-assets.pixelied.com/billing/purple-pro-badge.svg'
                  : 'https://static-assets.pixelied.com/billing/yellow-pro-plus-badge.svg'
              "
              alt="icon"
            />
            {{ workspaceBillingService.planSelected?.title }}
          </h2>
          <p style="color: #101010">
            Make your designs stand out with no restrictions!
          </p>
        </div>
        <div class="upgrade-content mt-0">
          <ul>
            <li
              *ngIf="
                workspaceBillingService.planSelected.id ===
                workspaceBillingService.planTwoDetails.id
              "
            >
              <img
                src="https://static-assets.pixelied.com/billing/green-check-icon.svg"
                alt="check"
              />
              Everything in<strong>&nbsp;Pro</strong>, plus:
            </li>
            <li
              *ngFor="
                let feature of workspaceBillingService.planSelected
                  ?.featuresIncluded
              "
            >
              <img
                src="https://static-assets.pixelied.com/billing/green-check-icon.svg"
                alt="check"
              />
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="upgrade-right">
        <form class="checkout-form">
          <!-- <div class="form-group">
            <label>Choose Workspace to upgrade to <span>PRO</span></label>

            <div class="dropdown upgrade-dropdown">
              <button
                class="btn d-flex align-items-center dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="badge">FS</span> Fahad Workspace
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#">Fahad Workspace</a>
                <a class="dropdown-item" href="#">Fahad Workspace</a>
                <a class="dropdown-item" href="#">Fahad Workspace</a>
              </div>
            </div>
          </div> -->

          <div class="form-group">
            <!-- <div class="alert alert-success" style="font-weight: 600">
              Black Friday 70% promo code has been added
            </div> -->

            <label class="heading-text-color">Billing Period</label>

            <div class="row no-gutters">
              <div class="col-md-8 col-lg-6">
                <label class="radio-card mb-0 mr-2">
                  <input
                    [checked]="
                      workspaceBillingService.planDurationType == 'yearly'
                    "
                    type="radio"
                    name="pay"
                    hidden
                  />
                  <div
                    (click)="workspaceBillingService.setBillingPeriod('yearly')"
                    [class.pro-plan]="
                      workspaceBillingService.planSelected?.id ==
                      workspaceBillingService.planOneDetails.id
                    "
                    [class.pro-plus-plan]="
                      workspaceBillingService.planSelected?.id ==
                      workspaceBillingService.planTwoDetails.id
                    "
                    class="billing-period-box"
                  >
                    <p class="d-flex flex-row align-items-center">
                      Pay annually
                      <span class="yearly-discount"
                        >Save
                        {{
                          workspaceBillingService.yearlyDiscountPercentage
                        }}%</span
                      >
                    </p>
                    <div class="d-flex flex-row align-items-center mt-2">
                      <!-- <span class="old-price-slash"
                        >${{
                          workspaceBillingService.planSelected
                            ?.oldPricePerMonth
                        }}/mo
                      </span> -->
                      <strong
                        >${{
                          !workspaceBillingService.promoCodeDiscount
                            ? workspaceBillingService.yearlyPrice
                            : (
                                workspaceBillingService.yearlyPrice *
                                workspaceBillingService.promoCodeDiscount
                              ).toFixed(2)
                        }}/{{
                          workspaceBillingService.planDurationType == "yearly"
                            ? "yr"
                            : "yr"
                        }}</strong
                      >
                    </div>
                    <!-- </p> -->
                  </div>
                </label>
              </div>
              <!-- <div class="col-md-8 col-lg-6">
                <label class="radio-card mb-0 mr-2">
                  <input
                    [checked]="
                      workspaceBillingService.planDurationType == 'monthly'
                    "
                    type="radio"
                    name="pay"
                    hidden
                  />
                  <div
                    (click)="
                      workspaceBillingService.setBillingPeriod('monthly')
                    "
                    [class.pro-plan]="
                      workspaceBillingService.planSelected?.id ==
                      workspaceBillingService.planOneDetails.id
                    "
                    [class.pro-plus-plan]="
                      workspaceBillingService.planSelected?.id ==
                      workspaceBillingService.planTwoDetails.id
                    "
                    class="billing-period-box"
                  >
                    <p class="d-flex flex-row align-items-center">
                      Pay monthly
                    </p>
                    <div class="d-flex flex-row align-items-center mt-2">
                      <strong
                        >${{
                          !workspaceBillingService.promoCodeDiscount
                            ? workspaceBillingService.monthlyPrice
                            : (
                                workspaceBillingService.monthlyPrice *
                                workspaceBillingService.promoCodeDiscount
                              ).toFixed(2)
                        }}/{{
                          workspaceBillingService.planDurationType == "monthly"
                            ? "mo"
                            : "mo"
                        }}</strong
                      >
                    </div>
                  </div>
                </label>
              </div> -->

              <!-- <div class="col-6">
                <label class="radio-card mb-0 ml-2">
                  <input type="radio" name="pay" hidden />
                  <div>
                    <p>Pay Monthly <strong>$9.95</strong></p>
                  </div>
                </label>
              </div> -->
            </div>
          </div>

          <div class="form-group mb-3">
            <label class="black-text">Complete payment</label>

            <input
              type="text"
              placeholder="Full Name"
              class="form-control"
              [(ngModel)]="workspaceBillingService.billingName"
              (keypress)="workspaceBillingService.onBillingNameKeypress($event)"
              name="billingName"
              id="billingName"
            />
            <!-- <input type="text" class="form-control" placeholder="Full Name" /> -->
          </div>
          <div class="form-group mb-3">
            <div class="text-right mb-2">
              <img
                src="assets/img/svg/billing-payment-methods.svg"
                alt="payment"
                class="img-fluid"
              />
            </div>
            <div id="card-element" class="card-input"></div>
          </div>
          <div class="form-group custom-control custom-checkbox">
            <div class="d-flex flex-row justify-content-between">
              <input
                hidden
                type="checkbox"
                name="promoCheck"
                [(ngModel)]="workspaceBillingService.promoCheck"
                class="custom-control-input"
                id="customCheck1"
              />
              <label class="custom-control-label mb-0" for="customCheck1"
                >I have a promo code</label
              >
              <!-- <input
              hidden
              type="checkbox"
              [(ngModel)]="workspaceBillingService.promoCheck"
              name="promoCheck"
              class="form-check-input"
              id="promoCheck"
            />
            <label class="form-check-label" for="promoCheck"
              ><span></span> I have a promo code</label
            > -->
              <div
                *ngIf="
                  workspaceBillingService.promoCheck &&
                  !workspaceBillingService.promoCodeApplied
                "
                class="promo-check"
                (click)="workspaceBillingService.applyPromoCode()"
              >
                Apply Promo Code
              </div>
              <div
                *ngIf="
                  workspaceBillingService.promoCheck &&
                  workspaceBillingService.promoCodeApplied
                "
                style="font-size: 13px; font-weight: 500; color: #696871"
              >
                Promo Code Applied
              </div>
            </div>
          </div>
          <div *ngIf="workspaceBillingService.promoCheck" class="form-group">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="workspaceBillingService.promoCode"
              name="promoCode"
              placeholder="Promo Code"
              id="promoCode"
            />
          </div>
          <!-- <div class="form-group">
            <input type="text" class="form-control" placeholder="Promo Code" />
          </div> -->
          <div
            *ngIf="workspaceBillingService.errorMessage"
            id="card-errors"
            class="alert alert-danger"
            role="alert"
          >
            {{ workspaceBillingService.errorMessage }}
          </div>
          <button
            [disabled]="workspaceBillingService.isLoading"
            type="submit"
            (click)="workspaceBillingService.startPaymentProcess()"
            class="btn btn-form btn-lg btn-block"
            [class.btn-pro-plan]="
              workspaceBillingService.planSelected?.id ==
              workspaceBillingService.planOneDetails.id
            "
            [class.btn-pro-plus-plan]="
              workspaceBillingService.planSelected?.id ==
              workspaceBillingService.planTwoDetails.id
            "
          >
            <span
              *ngIf="workspaceBillingService.isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Pay ${{
              !workspaceBillingService.promoCodeDiscount
                ? workspaceBillingService.finalPrice
                : (
                    workspaceBillingService.finalPrice *
                    workspaceBillingService.promoCodeDiscount
                  ).toFixed(2)
            }}
          </button>
          <div
            class="extra-details mt-2"
            *ngIf="
              (workspaceBillingService.totalActiveTeamMembersInWorkspace
                | async) > 0
            "
          >
            <div>
              Subscription price:
              <span>
                ${{
                  !workspaceBillingService.promoCodeDiscount
                    ? workspaceBillingService.planSelected.price
                    : (
                        workspaceBillingService.planSelected.price *
                        workspaceBillingService.promoCodeDiscount
                      ).toFixed(2)
                }}</span
              >
            </div>
            <div>
              Active team members:
              <span>
                {{
                  workspaceBillingService.totalActiveTeamMembersInWorkspace
                    | async
                }}
                * ${{
                  !workspaceBillingService.promoCodeDiscount
                    ? workspaceBillingService.perTeamMemberPrice
                    : (
                        workspaceBillingService.perTeamMemberPrice *
                        workspaceBillingService.promoCodeDiscount
                      ).toFixed(2)
                }}
                = ${{
                  !workspaceBillingService.promoCodeDiscount
                    ? (
                        (workspaceBillingService.totalActiveTeamMembersInWorkspace
                          | async) * workspaceBillingService.perTeamMemberPrice
                      ).toFixed(2)
                    : (
                        (workspaceBillingService.totalActiveTeamMembersInWorkspace
                          | async) *
                        workspaceBillingService.perTeamMemberPrice *
                        workspaceBillingService.promoCodeDiscount
                      ).toFixed(2)
                }}</span
              >
            </div>
          </div>
          <!-- <button class="btn btn-primary mb-2 btn-block">Pay $95.5</button> -->
          <div
            class="text-center stripe-brand mt-3 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/svg/stripe-payments-check.svg"
              alt="secure"
              class="mr-1"
            />
            Secure Payments by&nbsp;<strong>Stripe</strong>
          </div>
        </form>
        <!-- <div
          class="text-center secure mt-3 d-flex align-items-center justify-content-center"
        >
          <p>
            <span class="mr-1"
              ><img src="assets/img/svg/stripe-payments-check.svg" alt="icon"
            /></span>
            Secure Payments by
            <a href="https://stripe.com" target="_blank">Stripe</a>
          </p>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div
  [class.d-block]="workspaceBillingService.viewType == 'compare-plans'"
  [class.d-none]="workspaceBillingService.viewType != 'compare-plans'"
  class="modal-body modal-body-compare-plans"
>
  <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.39107 11.7206C-0.130356 12.242 -0.130357 13.0874 0.39107 13.6088C0.912496 14.1302 1.7579 14.1302 2.27932 13.6088L7.00005 8.88808L11.721 13.609C12.2424 14.1304 13.0878 14.1304 13.6092 13.609C14.1306 13.0876 14.1306 12.2422 13.6092 11.7207L8.8883 6.99983L13.6088 2.27932C14.1303 1.75789 14.1303 0.912493 13.6088 0.391068C13.0874 -0.130357 12.242 -0.130356 11.7206 0.391069L7.00005 5.11158L2.27972 0.391246C1.75829 -0.13018 0.912894 -0.13018 0.391469 0.391246C-0.129957 0.912671 -0.129956 1.75807 0.39147 2.2795L5.1118 6.99983L0.39107 11.7206Z"
        fill="#A6ACBE"
      />
    </svg>
  </button> -->

  <div class="row align-items-center">
    <div class="col-md-4 col-lg-3 mb-md-0 mb-4">
      <div class="modal-plan-left">
        <h2 class="black-text">
          Choose <br />
          your plan
        </h2>

        <div class="form-group">
          <label>Set billing period</label>
          <label class="radio-card mb-3">
            <input
              [checked]="workspaceBillingService.planDurationType == 'yearly'"
              type="radio"
              name="pay"
              hidden
            />
            <div
              (click)="workspaceBillingService.setBillingPeriod('yearly')"
              class="billing-period-box pro-plan"
            >
              <p class="d-flex flex-row align-items-center">
                <span class="flex-grow-1 black-text">Annually</span>
                <span class="yearly-discount-green"
                  >Save
                  {{ workspaceBillingService.yearlyDiscountPercentage }}%</span
                >
              </p>
            </div>
          </label>
          <!-- <label class="radio-card mb-3">
            <input
              [checked]="workspaceBillingService.planDurationType == 'monthly'"
              type="radio"
              name="pay"
              hidden
            />
            <div
              (click)="workspaceBillingService.setBillingPeriod('monthly')"
              class="billing-period-box pro-plan"
            >
              <p class="d-flex flex-row align-items-center">
                <span class="flex-grow-1 black-text">Monthly</span>
              </p>
            </div>
          </label> -->
        </div>
      </div>
    </div>
    <div class="col-md-8 col-lg-9">
      <div class="row">
        <div class="col-sm-6 mb-sm-0 mb-4">
          <div class="plan-card">
            <div class="upgrade-heading text-center">
              <h2 class="d-flex justify-content-center align-items-center">
                <img
                  src="https://static-assets.pixelied.com/billing/purple-pro-badge.svg"
                  alt="icon"
                />
                {{ workspaceBillingService.planOneDetails.title }}
              </h2>
              <p class="mx-auto">
                Make your designs stand out with no restrictions!
              </p>
            </div>
            <div class="upgrade-price">
              <h2>
                ${{ workspaceBillingService.planOneDetails.price
                }}<span
                  >/{{
                    workspaceBillingService.planDurationType == "monthly"
                      ? "mo."
                      : "yr."
                  }}
                </span>
              </h2>
              <p>Everything in <strong>Free</strong>, Plus:</p>
            </div>
            <div class="upgrade-content mt-0">
              <ul>
                <li
                  *ngFor="
                    let feature of workspaceBillingService.planOneDetails
                      .featuresIncluded
                  "
                >
                  <img
                    src="https://static-assets.pixelied.com/billing/green-check-icon.svg"
                    alt="check"
                  />
                  {{ feature }}
                </li>
              </ul>
            </div>
            <button
              (click)="
                workspaceBillingService.selectPlanAndChangeViewType(
                  workspaceBillingService.planOneDetails.id
                )
              "
              class="btn btn-primary btn-block"
            >
              Upgrade to {{ workspaceBillingService.planOneDetails.title }}
            </button>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="plan-card">
            <div class="upgrade-heading text-center">
              <h2 class="d-flex justify-content-center align-items-center">
                <img
                  src="https://static-assets.pixelied.com/billing/yellow-pro-plus-badge.svg"
                  alt="icon"
                />
                Pro+
              </h2>
              <p class="mx-auto">
                Make your designs stand out with no restrictions!
              </p>
            </div>
            <div class="upgrade-price">
              <h2>
                ${{ workspaceBillingService.planTwoDetails.price
                }}<span
                  >/{{
                    workspaceBillingService.planDurationType == "monthly"
                      ? "mo."
                      : "yr."
                  }}
                </span>
              </h2>
              <p>Everything in <strong>Pro</strong>, Plus:</p>
            </div>
            <div class="upgrade-content mt-0">
              <ul>
                <li
                  *ngFor="
                    let feature of workspaceBillingService.planTwoDetails
                      .featuresIncluded
                  "
                >
                  <img
                    src="https://static-assets.pixelied.com/billing/green-check-icon.svg"
                    alt="check"
                  />
                  {{ feature }}
                </li>
              </ul>
            </div>
            <button
              (click)="
                workspaceBillingService.selectPlanAndChangeViewType(
                  workspaceBillingService.planTwoDetails.id
                )
              "
              class="btn btn-yellow btn-block"
            >
              Upgrade to {{ workspaceBillingService.planTwoDetails.title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
