import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingModalComponent } from './onboarding-modal/onboarding-modal.component';
import { OnboardingService } from './data/onboarding.service';
import { OnboardingOptionStatesDirective } from './shared/directives/onboarding-option-states.directive';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateWorkspaceComponent } from './onboarding-modal/create-workspace/create-workspace.component';



@NgModule({
  declarations: [
    OnboardingModalComponent,
    OnboardingOptionStatesDirective,
    CreateWorkspaceComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule
  ],
  providers: [
    OnboardingService
  ]
})
export class OnboardingModule { }
