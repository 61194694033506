import { Injectable } from '@angular/core';

export class FeaturePageData {
  imagesUrlKeyword: string;
  howToName: string;
  pageTitle: string;
  pageDescription: string;
  featurePageURL: string;
  heroSectionData: HeroSectionData;
  stepsSectionData: StepsSectionData;
  toolkitSectionData: ToolkitSectionData;
  subFeaturesSectionData: SubFeaturesSectionData;
  linksToOtherTools?: string[];
  faqSectionData: FAQSectionData[];
  otherFeaturesSection: TextSection;
  ctaOptions: CTAOptions;
  videoTutorialData?: VideoTutorialData;
  isUploading?: boolean = false;
}

export interface CTAOptions {
  isFileUpload: boolean;
  // fileTypeUpload: string;
  svgFileUploadAllowed: boolean;
  queryParams: { refId: string, toolRefId?: string };
}

export interface VideoTutorialData {
  youtubeVideoID: string;
  videoURL: string;
  embedURL: string;
  thumbnailURL: string;
  videoDuration: string;
  uploadDate: string;
}

export interface TextSection {
  mainText: string;
  subText: string;
}

export interface FAQSectionData extends TextSection {
  isCollapsed: boolean;
}

export interface StepsSectionData extends TextSection {
  // mainText: string;
  // subText: string;
  steps: Steps[];
}

export interface Steps {
  name: string;
  stepNo: string;
  text: string;
  img: string;
  imgWEBP: string;
  // bgColor: string;
}

export interface SubFeaturesSectionData {
  mainText: string;
  subFeaturesListSectionData: SubFeaturesListSectionData[];
}

export interface SubFeaturesListSectionData extends TextSection {
  // mainText: string;
  // subText: string;
  img: string;
}

export interface HeroSectionData {
  heroMainText: string;
  heroSubText: string;
  heroImgs: HeroImg;
  // heroImg: string;
}

export interface HeroImg {
  heroImgPNG?: string;
  heroImgPNGSmall?: string;
  heroImgWEBP?: string;
  heroImgWEBPSmall?: string;
  heroImgMediaQueries?: string;
  heroImgSmallMediaQueries?: string;
  heroImgWidth: number;
  heroImgHeight: number;
}

export interface ToolkitSectionData extends TextSection {
  // mainText: string;
  // subText: string;
  toolkitFeatures: ToolkitFeatures[];
}

export interface ToolkitFeatures {
  mainText: string;
  subTexts: string[];
  img: string;
  imgWEBP: string;
  imgWidth: number;
  imgHeight: number;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureDataService {

  featurePageData: FeaturePageData;

  constructor() { }

  setFeaturePageData = (featurePageData: FeaturePageData): void => {
    this.featurePageData = featurePageData;
  }

  getFeaturePageData = (): FeaturePageData => {
    return this.featurePageData;
  }

}
