import { Component, OnInit } from '@angular/core';
import { WorkspacesStoreService } from 'src/app/core/services/workspaces-store.service';
import { UserService } from 'src/app/modules/auth/data/user.service';

@Component({
  selector: 'app-menu-nav',
  templateUrl: './menu-nav.component.html',
  styleUrls: ['./menu-nav.component.scss']
})
export class MenuNavComponent implements OnInit {

  constructor(
    public _workspacesStoreService: WorkspacesStoreService,
    public _userService: UserService) { }

  ngOnInit(): void {
  }

}
