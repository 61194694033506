import { ApplicationInitStatus, APP_INITIALIZER, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, ActivatedRoute, NavigationStart, NavigationError, NavigationCancel } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpersService } from './core/services/helpers.service';
import { UserService } from './modules/auth/data/user.service';
// import { Constants } from './shared/data/constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnboardingModalComponent } from './modules/onboarding/onboarding-modal/onboarding-modal.component';
import { WorkspacesStoreService } from './core/services/workspaces-store.service';

// declare let gtag: Function;
// declare let fbq: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'Pixelied';
    loading: boolean;
    // appStarterLoader: boolean = true;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        @Inject(DOCUMENT) private dom,
        private _router: Router,
        private _route: ActivatedRoute,
        @Inject(APP_INITIALIZER) public appInit: ApplicationInitStatus,
        private _helpersService: HelpersService,
        private _modalService: NgbModal,
        private gtmService: GoogleTagManagerService,
        public _workspacesStoreService: WorkspacesStoreService,
        private _userService: UserService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer) {
        this.loading = false;

        this.matIconRegistry.addSvgIconSet(
            this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/dashboard-page-icons-v1.svg?v=3")
        );

        _router.events.subscribe(
            (ev: RouterEvent): void => {

                if (ev instanceof NavigationStart) {
                    // console.log('navigation started');
                    this.loading = true;
                }
                if (
                    ev instanceof NavigationEnd ||
                    ev instanceof NavigationCancel ||
                    ev instanceof NavigationError
                ) {
                    // console.log('navigation ended');
                    this.loading = false;
                }

                // if (event instanceof RouteConfigLoadStart) {
                //     this.loading = true;
                // } else if (event instanceof RouteConfigLoadEnd) {
                //     this.loading = false;
                // }
                // else if (event instanceof NavigationEnd) {
                //     let utm_source = this._route.snapshot.queryParams['utm_source'] || '';
                //     let utm_medium = this._route.snapshot.queryParams['utm_medium'];
                //     if (isPlatformBrowser(this.platformId)) {
                //         if (utm_source || utm_source.trim() != '') {
                //             if (!localStorage.getItem(Constants.UTM_SOURCE_MEDIUM)) {
                //                 localStorage.setItem(Constants.UTM_SOURCE_MEDIUM, `${utm_source}:${utm_medium}`);
                //             }
                //         }
                //         // console.log(event.urlAfterRedirects)
                //         // console.log(event.url)
                //         // const gtmTag = {
                //         //     event: 'page',
                //         //     pageName: item.url
                //         // };
                //         // this.gtmService.pushTag(gtmTag);
                //         // gtag('config', 'UA-165120692-1', { 'page_path': event.urlAfterRedirects });
                //         // fbq('track', 'PageView');
                //     }
                // }
            }
        );
    }

    ngOnInit() {
        // this._workspacesStoreService.initWorkspacesStore();
        // if (isPlatformBrowser(this.platformId)) {
        //     localStorage.setItem(Constants.REFERRER, document.referrer);
        // }
        // this._helpersService.initOptinMonsterEventListener();
        this.matIconRegistry.addSvgIconSet(
            this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/home-page-icons-v7.svg")
        );
        // setTimeout(() => {
        //     if (environment.production) {
        //         this._helpersService.initOptinMonster();
        //     }
        // }, 12000);
        // this.gtmService.addGtmToDom();
        this._userService.attachUserScopeToSentryLogging();
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this._router.routerState.root),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data))
            .subscribe((event) => {
                // console.log(event)
                // if (event.heroImg) this._helpersService.addPreloadImageLink(event.heroImg);
                if (this._modalService.hasOpenModals()) this._modalService.dismissAll();
                this._helpersService.setCanonicalUrls(event);
                this._helpersService.setSocialMediaMetaTags({
                    title: event.title, ogTitle: event.title, ogDescription: event.description,
                    description: event.description, ogImage: event['og:image']
                });
                // console.log(event.canonical)
                // console.log(event.pageURL)
                // internal pages, ltd and checkout should be instant
                // setTimeout(() => {
                // console.log(event);
                this.showOnboardingModal(event);
                // this.addOrRemoveOptinMonster(event);
                // }, 1000);
                // if (String(event.canonical).includes('editor')) console.log('rm:1'); this._helpersService.removeOptinMonsterClass();
                if (String(event.canonical).includes('features') ||
                    String(event.canonical).includes('mockups')
                ) {
                    // console.log('in features')
                    this.gtmService.addGtmToDom();
                } else {
                    // console.log('not in features')
                    setTimeout(() => {
                        // this.addGoogleAnalyticsScript();
                        this.gtmService.addGtmToDom();
                    }, 10000);
                }
            });
    }

    showOnboardingModal(event) {
        if (String(event.canonical).includes('home') ||
            String(event.canonical).includes('my-designs') ||
            String(event.canonical).includes('bg-remover') ||
            // String(event.canonical).includes('workspaces') ||
            String(event.canonical).includes('illustrations') ||
            String(event.canonical).includes('mockups') ||
            String(event.canonical).includes('templates')) {
            if (this._userService.getUserDetailsFromJWT()) {
                if (!this._userService.getUserDetailsFromJWT()?.onboarding_complete && (this._userService.getUserDetailsFromJWT() as any)?.showOnboarding) {
                    this._modalService.open(OnboardingModalComponent, { size: 'xl', centered: true, backdrop: 'static' });
                }
            }
        }
    }

    addOrRemoveOptinMonster(event) {
        if (
            String(event?.pageURL) === 'https://pixelied.com/lifetime-deal' ||
            String(event.canonical).includes('lifetime-deal/checkout') ||
            String(event.canonical).includes('pricing') ||
            String(event.canonical).includes('home') ||
            String(event.canonical).includes('my-designs') ||
            String(event.canonical).includes('illustrations')) {
            if (!(this._userService.getUserDetailsFromJWT() as any)?.showOnboarding) {
                // if (this._userService.getUserDetailsFromJWT().onboarding_complete) {
                console.log('initOP:1');
                if (environment.production) {
                    // this._helpersService.initOptinMonster();
                }
            }
        } else {
            setTimeout(() => {
                if (
                    String(event?.pageURL) === 'https://pixelied.com' ||
                    String(event.canonical).includes('features') ||
                    String(event.canonical).includes('create')) {
                    console.log('initOP:2');
                    if (environment.production) {
                        // this._helpersService.initOptinMonster();
                    }
                } else {
                    console.log('rm:2');
                    // this._helpersService.removeOptinMonsterClass();
                }
            }, 3000);
        }
        // if (
        //     String(event?.pageURL) === 'https://pixelied.com' ||
        //     String(event?.pageURL) === 'https://pixelied.com/lifetime-deal' ||
        //     String(event.canonical).includes('lifetime-deal/checkout') ||
        //     String(event.canonical).includes('features') ||
        //     String(event.canonical).includes('create') ||
        //     String(event.canonical).includes('pricing') ||
        //     String(event.canonical).includes('home') ||
        //     String(event.canonical).includes('my-designs') ||
        //     String(event.canonical).includes('illustrations')) {
        //     console.log('initOP');
        //     if (environment.production) {
        //         this._helpersService.initOptinMonster();
        //     }
        // } else {
        //     console.log('rm');
        //     this._helpersService.removeOptinMonsterClass();
        // }
    }

    // setTitleAndDescription(data) {
    //     // console.log('data')
    //     // console.log(data)
    //     this.titleService.setTitle(data.title || Constants.PIXELIED_TITLE);
    //     this.metaService.updateTag({ name: 'description', content: data.description || Constants.PIXELIED_DESCRIPTION });

    //     this.metaService.updateTag({ property: 'og:url', content: data.canonical || Constants.PIXELIED_HOST_NAME });
    //     this.metaService.updateTag({ property: 'og:title', content: data.title || Constants.OG_PIXELIED_TITLE });
    //     this.metaService.updateTag({ property: 'og:description', content: data.description || Constants.OG_PIXELIED_DESCRIPTION });

    //     this.metaService.updateTag({ property: 'twitter:url', content: data.canonical || Constants.PIXELIED_HOST_NAME });
    //     this.metaService.updateTag({ property: 'twitter:title', content: data.title || Constants.OG_PIXELIED_TITLE });
    //     this.metaService.updateTag({ property: 'twitter:description', content: data.description || Constants.OG_PIXELIED_DESCRIPTION });

    //     // OG:IMAGE TAG
    //     if (data['og:image']) {
    //         this.metaService.updateTag({ property: 'og:image', content: data['og:image'] });
    //         this.metaService.updateTag({ property: 'twitter:image', content: data['og:image'] });
    //     } else {
    //         this.metaService.updateTag({ property: 'og:image', content: Constants.OG_IMAGE });
    //         this.metaService.updateTag({ property: 'twitter:image', content: Constants.OG_IMAGE });
    //     }
    // }

    // addHotjarScript() {
    //     (function (h: any, o, t, j, a, r) {
    //         h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
    //         h._hjSettings = { hjid: 2064241, hjsv: 6 };
    //         a = o.getElementsByTagName('head')[0];
    //         r = o.createElement('script'); r.defer = 1;
    //         r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    //         a.appendChild(r);
    //     })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    // }

    // addGoogleAnalyticsScript() {
    //     const head = this.dom.getElementsByTagName('head')[0];
    //     var element: HTMLScriptElement = this.dom.querySelector(`.ga-script`) || null;
    //     if (element == null) {
    //         element = this.dom.createElement('script') as HTMLScriptElement;
    //         element.setAttribute('class', 'ga-script');
    //         head.appendChild(element);
    //     }
    //     element.setAttribute('src', Constants.GA_SCRIPT_URL);
    //     element.defer = true;
    // }

}
