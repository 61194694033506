import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'firstLetterFromName'
})
export class FirstLetterFromNamePipe implements PipeTransform {
    transform(user: any): string {
        if (!user || typeof (user) === 'string') return '';
        const userFullname = `${user.first_name} ${user.last_name || ''}`;
        let words = userFullname.split(' ');
        return words.map((item, index) => {
            if (index < 2) return item.charAt(0);
        }).join('').toUpperCase();
        // return userFullname.slice(0, 1);
    }
}