<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-9 col-xl-8">
      <h1 class="text-center">Data Deletion Instructions</h1>
      <p class="instruction-text mt-5">
        You can delete your account and personal data using these two following
        methods:
      </p>
      <ol>
        <li>
          By emailing us at
          <a href="mailto:support@pixelied.com">support@pixelied.com</a> and we
          will delete your account and personal data.
        </li>
        <li>
          Or, you can login and go to
          <a href="https://pixelied.com/account-settings"
            >account settings page</a
          >
          and delete your account yourself by clicking on "Delete Account"
          button which will delete everything related to that account on our
          website.
        </li>
      </ol>
    </div>
  </div>
</div>
