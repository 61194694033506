import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from 'src/app/core/services/helpers.service';
import { ImageUploadService } from 'src/app/core/services/image-upload.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { TemplatesService } from 'src/app/core/services/templates.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Constants } from 'src/app/shared/data/constants';
import { OnboardingStep, StepIds, StepOption, StepTypes, UserOnboarding, UserOnboardingData } from '../data/onboarding.model';
import { OnboardingService } from '../data/onboarding.service';

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class OnboardingModalComponent implements OnInit {

  StepTypes = StepTypes;
  StepIds = StepIds;
  selectedBannerFormat = { title: 'LinkedIn', format: 'linkedin-banner' };
  constructor(
    public onboardingService: OnboardingService,
    private _templateService: TemplatesService,
    private _toastService: ToastService,
    private _helpersService: HelpersService,
    private _imageUploadService: ImageUploadService,
    private _localStorage: LocalStorageService,
    private _modalService: NgbModal,
    private _router: Router,
    private _ngModal: NgbModal) { }

  ngOnInit(): void { }

  async selectOption(option: StepOption) {
    console.log('option1')
    console.log(option)
    let showInputField = false;
    for (let option of this.onboardingService.currentOnboardingStep.stepOptions) {
      if (option.option == 'Other' && option.selected) showInputField = true;
    }
    // if(this.onboardingService.currentOnboardingStep.stepOptions.forEach(a => a.option == 'Other'))
    this.onboardingService.inputFieldShown = showInputField;
    let index = this.onboardingService.getUserOnboardingIndexByStepId(this.onboardingService.currentOnboardingStep.stepId);
    if (this.onboardingService.currentOnboardingStep.multiSelection) {
      console.log('multi selection')
      option.selected = !option.selected;
      if (this.onboardingService.userOnboardingData.user_onboarding[index].values.findIndex(o => o.option == option.option) == -1) {
        this.onboardingService.userOnboardingData.user_onboarding[index].values.push(option);
      } else {
        let i = this.onboardingService.userOnboardingData.user_onboarding[index].values.findIndex(o => o.option == option.option);
        this.onboardingService.userOnboardingData.user_onboarding[index].values.splice(i, 1);
      }

      if (option.option == 'Other') {
        console.log('other')
        if (option.selected) this.onboardingService.inputFieldShown = true;
        else this.onboardingService.inputFieldShown = false;
      }

      if (this.onboardingService.userOnboardingData.user_onboarding[index].values.length > 0) this.onboardingService.nextStepDisabled = false;
      else { this.onboardingService.nextStepDisabled = true; }
    } else {
      console.log('single selection')
      /**
       * TODO: set selected == false for all values to reset it
       */
      this.onboardingService.inputFieldShown = false;
      this.unselectPreviousSelectedOptions();
      option.selected = true;
      this.onboardingService.userOnboardingData.user_onboarding[index].values[0] = option;
      if (this.onboardingService.currentOnboardingStep.stepId == StepIds.USER_DESCRIPTION) {
        if (option.option == 'Other') {
          this.onboardingService.inputField = "";
          this.onboardingService.inputFieldShown = true;
          this.onboardingService.nextStepDisabled = false;
        } else {
          this.onboardingService.prevStepDisabled = false;
          this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.SOURCE);
          await this.saveUserOnboardingData();
        }
      } else if (this.onboardingService.currentOnboardingStep.stepId == StepIds.SOURCE) {
        if (option.option == 'Other') {
          this.onboardingService.inputField = "";
          this.onboardingService.inputFieldShown = true;
          this.onboardingService.nextStepDisabled = false;
        } else {
          this.onboardingService.prevStepDisabled = false;
          this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.USAGE);
          await this.saveUserOnboardingData();
        }
      }
    }
    console.log(this.onboardingService.userOnboardingData.user_onboarding)
  }

  addClassesToOptions(option: StepOption, step: OnboardingStep) {
    // console.log('class option')
    // console.log(option)
    if (option.selected) {
      for (let item of ['Facebook', 'Instagram', 'Twitter', 'YouTube',
        'LinkedIn', 'Twitch', 'Pinterest', 'Small Business', 'Enterprise', 'Personal User', 'Non Profit',
        'Freelancer', 'Agency', 'Educator', 'Search Engine', 'Source Social Media', 'Online Review', 'Friend',
        'Other', 'Word of mouth',
        'Usage Social Media', 'Mockups', 'Photo Editing', 'BG Remover', 'SVG Editor', 'E-commerce', 'Banners', 'Usage Other',
        'Phone', 'Device', 'Packaging', 'Clothing', 'Print', 'Advertising', 'Mockup Social Media', 'User Description Other']) {
        if (option.cssClass ? option.cssClass : option.option == item) return `selected-${item.replace(/ /g, '-').toLowerCase()}`;
      }
    }
  }

  async goToHomePage() {
    // this._router.navigate(['/home']);
    await this.saveUserOnboardingData(null, true);
    window.location.href = '/home';
  }

  goOneStepBack() {
    if (this.onboardingService.isSaving || this.onboardingService.imageUploading) return;
    this.onboardingService.inputField = "";
    if (this.onboardingService.currentOnboardingStep.stepId === StepIds.SOURCE) {
      this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.USER_DESCRIPTION);
      this.onboardingService.prevStepDisabled = true;
    }

    else if (this.onboardingService.currentOnboardingStep.stepId === StepIds.SOCIAL_MEDIA_FORMATS || this.onboardingService.currentOnboardingStep.stepId === StepIds.MOCKUP_CATEGORIES ||
      this.onboardingService.currentOnboardingStep.stepId === StepIds.IMAGE_EDITING || this.onboardingService.currentOnboardingStep.stepId === StepIds.BG_REMOVER ||
      this.onboardingService.currentOnboardingStep.stepId === StepIds.SVG_EDITING || this.onboardingService.currentOnboardingStep.stepId === StepIds.ECOMMERCE_TEMPLATES_SELECTION ||
      this.onboardingService.currentOnboardingStep.stepId === StepIds.BANNER_TEMPLATES_SELECTION
    ) {
      this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.USAGE);
    }

    else if (this.onboardingService.currentOnboardingStep.stepId === StepIds.USAGE) this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.SOURCE);

    let index = this.onboardingService.getUserOnboardingIndexByStepId(this.onboardingService.currentOnboardingStep.stepId);
    let firstOption: StepOption = this.onboardingService.userOnboardingData.user_onboarding[index].values[0];
    if (firstOption.option == 'Other' && firstOption.selected) {
      this.onboardingService.inputFieldShown = true;
      this.onboardingService.inputField = firstOption.value;
    } else {
      this.onboardingService.inputFieldShown = false;
      this.onboardingService.inputField = "";
    }
  }

  async goOneStepForward() {
    if (this.onboardingService.isSaving || this.onboardingService.imageUploading) return;
    // console.log(this.onboardingService.currentOnboardingStep)
    let index = this.onboardingService.getUserOnboardingIndexByStepId(this.onboardingService.currentOnboardingStep.stepId);
    let firstOption: StepOption = this.onboardingService.userOnboardingData.user_onboarding[index].values[0];
    console.log(firstOption)
    if (this.onboardingService.currentOnboardingStep.stepId === StepIds.USER_DESCRIPTION) {
      if (firstOption.option == 'Other') {
        // if (this.onboardingService.inputField.trim() == "") return this._toastService.show("Please add text value", { classname: 'bg-warning text-dark', delay: 5000 });
        firstOption.value = this._helpersService.toTitleCase(this.onboardingService.inputField.trim() || "Other");
      }
      await this.saveUserOnboardingData();
      this.onboardingService.prevStepDisabled = false;
      this.onboardingService.inputFieldShown = false;
      this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.SOURCE);
    } else if (this.onboardingService.currentOnboardingStep.stepId === StepIds.SOURCE) {
      if (firstOption.option == 'Other') {
        // if (this.onboardingService.inputField.trim() == "") return this._toastService.show("Please add text value", { classname: 'bg-warning text-dark', delay: 5000 });
        firstOption.value = this._helpersService.toTitleCase(this.onboardingService.inputField.trim() || "Other");
      }
      await this.saveUserOnboardingData();
      this.onboardingService.prevStepDisabled = false;
      this.onboardingService.inputFieldShown = false;
      this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.USAGE);
    } else if (this.onboardingService.currentOnboardingStep.stepId === StepIds.USAGE) {
      await this.setOtherOptionValueForMultipleSelections();
      await this.saveUserOnboardingData();
      if (firstOption.value == 'Social Media') {
        this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.SOCIAL_MEDIA_FORMATS);
        this.onboardingService.nextStepDisabled = true;
      } else if (firstOption.option == 'Mockups') {
        this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.MOCKUP_CATEGORIES);
        this.onboardingService.nextStepDisabled = true;
      } else if (firstOption.option == 'Photo Editing') {
        this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.IMAGE_EDITING);
      } else if (firstOption.option == 'BG Remover') {
        this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.BG_REMOVER);
      } else if (firstOption.option == 'SVG Editor') {
        this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.SVG_EDITING);
      } else if (firstOption.option == 'E-commerce') {
        this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.ECOMMERCE_TEMPLATES_SELECTION);
        if (this.onboardingService.currentOnboardingStep.templatesList.length == 0) {
          this.onboardingService.templatesLoading = true;
          this._templateService.getTemplatesByTagsAndCategoryFromTemplatesPage(1, 50, '', '', 'ecommerce-hero-image', 'yes').subscribe((data) => {
            this.onboardingService.templatesLoading = false;
            this.onboardingService.currentOnboardingStep.templatesList = data.templates;
          });
        }
      } else if (firstOption.option == 'Banners') {
        this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.BANNER_TEMPLATES_SELECTION);
        if (this.onboardingService.currentOnboardingStep.templatesList.length == 0) {
          this.onboardingService.templatesLoading = true;
          this._templateService.getTemplatesByTagsAndCategoryFromTemplatesPage(1, 50, '', '', this.selectedBannerFormat.format, 'yes').subscribe((data) => {
            this.onboardingService.templatesLoading = false;
            this.onboardingService.currentOnboardingStep.templatesList = data.templates;
          });
        }
      } else if (firstOption.option == 'Other') {
        // firstOption.value = this._helpersService.toTitleCase(this.onboardingService.inputField.trim());
        this.onboardingService.prevStepDisabled = false;
        this.onboardingService.inputFieldShown = false;
        this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.IMAGE_EDITING);
      }
    } else if (this.onboardingService.currentOnboardingStep.stepId === StepIds.SOCIAL_MEDIA_FORMATS) {
      await this.setOtherOptionValueForMultipleSelections();
      await this.saveUserOnboardingData(null, true);
      let firstOption: StepOption = this.onboardingService.userOnboardingData.user_onboarding[index].values[0];
      this._ngModal.dismissAll();
      this._router.navigate([firstOption.goToUrl]);
    } else if (this.onboardingService.currentOnboardingStep.stepId === StepIds.MOCKUP_CATEGORIES) {
      await this.setOtherOptionValueForMultipleSelections();
      await this.saveUserOnboardingData(null, true);
      let firstOption: StepOption = this.onboardingService.userOnboardingData.user_onboarding[index].values[0];
      this._ngModal.dismissAll();
      this._router.navigate([firstOption.goToUrl]);
    }

    let index2 = this.onboardingService.getUserOnboardingIndexByStepId(this.onboardingService.currentOnboardingStep.stepId);
    let firstOption2: StepOption = this.onboardingService.userOnboardingData.user_onboarding[index2]?.values[0];
    console.log('firstOption2')
    console.log(firstOption2)
    if (firstOption2?.option == 'Other' && firstOption2?.selected) {
      this.onboardingService.inputFieldShown = true;
      this.onboardingService.inputField = firstOption2.value;
    } else {
      this.onboardingService.inputFieldShown = false;
      this.onboardingService.inputField = "";
    }
  }

  setOtherOptionValueForMultipleSelections() {
    for (let option of this.onboardingService.currentOnboardingStep.stepOptions) {
      if (option.option == 'Other' && option.selected) {
        option.value = this._helpersService.toTitleCase(this.onboardingService.inputField.trim() || "Other");
        this.onboardingService.inputFieldShown = false;
        this.onboardingService.inputField = "";
      }
    }
  }

  fileDrop(event) {
    this.readImageFile(event);
  }

  readImageFile(files) {
    if (files && files.length > 0) {
      const file: File = files[0];
      if (this.onboardingService.currentOnboardingStep.stepId == StepIds.BG_REMOVER || this.onboardingService.currentOnboardingStep.stepId == StepIds.IMAGE_EDITING) {
        if (['image/jpeg', 'image/png', 'image/webp'].indexOf(file.type) == -1) {
          return this._toastService.show(`Only PNG and JPG files are allowed`, { classname: 'bg-warning text-dark', delay: 5000 });
        }
      } else if (this.onboardingService.currentOnboardingStep.stepId == StepIds.SVG_EDITING) {
        if (file.type !== 'image/svg+xml') return this._toastService.show(`Only SVG files are allowed`, { classname: 'bg-warning text-dark', delay: 5000 });
      }
      if (!this._helpersService.checkFileSize(file)) {
        return this._toastService.show(`File size cannot be more than ${Constants.MAX_IMAGE_FILE_UPLOAD} MB`, { classname: 'bg-danger text-light', delay: 5000 });
      }
      if (this.onboardingService.currentOnboardingStep.stepId == StepIds.BG_REMOVER) {
        this.saveBase64ImageAndOpenInEditor(file);
      } else {
        let urlImageExtension = file.type;
        if (["image/png", "image/jpg", "image/jpeg", "image/webp"].indexOf(urlImageExtension) !== -1) {
          this.onboardingService.imageUploading = true;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            var image = new Image();
            image.src = (reader.result as string);
            image.onload = async () => {
              let width = image.width;
              let height = image.height;
              console.log(width)
              console.log(height)

              if (width > height && width > Constants.MAX_IMAGE_UPLOAD_WIDTH) {
                height = height * (Constants.MAX_IMAGE_UPLOAD_WIDTH / width);
                width = Constants.MAX_IMAGE_UPLOAD_WIDTH;
              } else if (height > width && height > Constants.MAX_IMAGE_UPLOAD_HEIGHT) {
                width = width * (Constants.MAX_IMAGE_UPLOAD_HEIGHT / height);
                height = Constants.MAX_IMAGE_UPLOAD_HEIGHT;
              }
              console.log(width)
              console.log(height)
              this._modalService.dismissAll();

              this.onboardingService.imageUploading = false;
              await this.saveUserOnboardingData({ uploadObj: { uploadType: StepIds.IMAGE_EDITING, fileType: file.type } }, true);
              this._localStorage.setItem(Constants.IMAGE_UPLOAD_FEATURE_PAGE_LOCALSTORAGE, JSON.stringify({
                url: image.src || "",
                width: image.width,
                height: image.height,
                // size: data.imageSize,
                // mimetype: data.imageMimeType
              }));
              let queryParams: any = { width: image.width, height: image.height, units: 'px', refId: Constants.EDITOR_PAGE_REF_ID, toolRefId: Constants.EDITOR_TOOLS_REF_IDS.IMAGE_FILTERS_EFFECTS };
              this._router.navigate(['/editor'], { queryParams: queryParams });
              // this._gaEventsService.eventEmitter('Image Upload Modal', 'Image Uploaded', `type: ${urlImageExtension}`);
            };
            image.onerror = () => {
              this.onboardingService.imageUploading = false;
              this._toastService.show(`Upload Error: Image file may be corrupted`, { classname: 'bg-danger text-light', delay: 5000 });
            }
          };
        } else if (urlImageExtension == "image/svg+xml") {
          this.onboardingService.imageUploading = true;
          const reader = new FileReader();
          reader.readAsText(file); // read file as text
          reader.onload = async (event: any) => {
            // called once readAsText is completed
            var image = new Image();
            let svgData = event.target.result;
            image.src = svgData;
            if (this._helpersService.isSVGString(svgData)) {
              await this.saveUserOnboardingData({ uploadObj: { uploadType: StepIds.SVG_EDITING, fileType: file.type.toUpperCase() } }, true);
              this._localStorage.setItem(Constants.SVG_UPLOAD_FEATURE_PAGE_LOCALSTORAGE, JSON.stringify({
                svgData: svgData,
              }));
              let queryParams: any = { width: 1000, height: 1000, units: 'px', refId: Constants.EDITOR_PAGE_REF_ID, toolRefId: Constants.EDITOR_TOOLS_REF_IDS.ICONS_SIDEBAR };
              this._router.navigate(['/editor'], { queryParams: queryParams });
              this._modalService.dismissAll();
              this.onboardingService.imageUploading = false;
              // this._gaEventsService.eventEmitter('Image Upload Modal', 'Image Uploaded', `type: ${urlImageExtension}`);
            } else {
              this.onboardingService.imageUploading = false;
              this._toastService.show(`Upload Error: SVG file may be corrupted`, { classname: 'bg-danger text-light', delay: 5000 });
            }
          };
        }
      }
    }
  }

  async saveUserOnboardingDataForTemplateSelection() {
    let extraData: any = { templateSelected: this.onboardingService.currentOnboardingStep.stepId };
    if (this.onboardingService.currentOnboardingStep.stepId === StepIds.BANNER_TEMPLATES_SELECTION) { extraData.bannerFormat = this.selectedBannerFormat.title; }
    await this.saveUserOnboardingData(extraData, true);
  }

  async saveBase64ImageAndOpenInEditor(file: File) {
    this.onboardingService.imageUploading = true;
    const imageFile = <File>file;
    const formData = new FormData();
    formData.append("file", imageFile, imageFile.name);
    formData.append("image_type", file.type);
    formData.append("user_id", this.onboardingService.userOnboardingData.user_id);
    this._imageUploadService.uploadUserImage(formData).subscribe(async (data: any) => {
      await this.saveUserOnboardingData({ uploadObj: { uploadType: StepIds.BG_REMOVER, fileType: file.type } }, true);
      this.onboardingService.imageUploading = false;
      this._localStorage.setItem(Constants.IMAGE_UPLOAD_FEATURE_PAGE_LOCALSTORAGE, JSON.stringify({
        url: data.imageURL,
        width: data.imageWidth,
        height: data.imageHeight,
        size: data.imageSize,
        mimetype: data.imageMimeType
      }));
      let queryParams: any = { width: data.imageWidth, height: data.imageHeight, units: 'px', refId: Constants.EDITOR_PAGE_REF_ID, toolRefId: Constants.EDITOR_TOOLS_REF_IDS.REMOVE_IMAGE_BACKGROUND_TOOL };
      this._router.navigate(['/editor'], { queryParams: queryParams });
    }, (error: any) => {
      this.onboardingService.imageUploading = false;
      this._toastService.show(`Upload Error: Image file may be corrupted or not supported`, { classname: 'bg-danger text-light', delay: 5000 });
      throw error;
    });
  }

  async saveUserOnboardingData(extraData: { uploadObj?: { uploadType, fileType }, templateSelected?} = null, onboardingCompleted: boolean = false) {
    return new Promise((resolve, reject) => {
      this.onboardingService.isSaving = true;
      let email = this.onboardingService.getUserDataFromUserService().email;
      let userOnboardingData: UserOnboarding = this.removeUnnecessaryDetailsFromUserOnboarding();
      this.onboardingService.saveOrUpdateUserOnboardingData(userOnboardingData, extraData, onboardingCompleted, email)
        .then((data: any) => {
          console.log('DATA SAVED!!!');
          console.log(data)
          // console.log(this.onboardingService.userOnboardingData)
          this._localStorage.setItem('token', data.token);
          resolve(data);
          this.onboardingService.isSaving = false;
        })
        .catch((error) => {
          reject(error);
          this.onboardingService.isSaving = false;
          throw error
        });
    })
  }

  getTemplatesByBannerFormat(format) {
    this.selectedBannerFormat = format;
    this.onboardingService.templatesLoading = true;
    this._templateService.getTemplatesByTagsAndCategoryFromTemplatesPage(1, 50, '', '', this.selectedBannerFormat.format, 'yes').subscribe((data) => {
      this.onboardingService.templatesLoading = false;
      this.onboardingService.currentOnboardingStep.templatesList = data.templates;
    });
  }

  private removeUnnecessaryDetailsFromUserOnboarding = () => {
    let userOnboardingData: UserOnboarding = JSON.parse(JSON.stringify(this.onboardingService.userOnboardingData));
    for (let step of userOnboardingData.user_onboarding) {
      for (let value of step.values) {
        delete value.goToUrl;
        delete value.imgURL;
        delete value.selected;
        delete value.cssClass;
      }
    }
    return userOnboardingData;
  }

  private unselectPreviousSelectedOptions = () => {
    for (let currentStep of this.onboardingService.currentOnboardingStep.stepOptions) {
      currentStep.selected = false;
    }
  }

}
