<div class="create-workspace-wrapper">
  <div class="w-100 pt-5">
    <div *ngIf="errorMsg" class="alert alert-danger">
      {{ errorMsg }}
    </div>
    <div class="form-group">
      <label for="workspace-name">Workspace Name</label>
      <input
        #workspaceNameInput
        (keyup.enter)="createWorkspace(workspaceNameInput.value)"
        class="form-control form-control-lg"
        type="text"
        name="workspace-name"
        id="workspace-name"
        value="My Workspace"
        placeholder="My Workspace"
      />
    </div>
    <button
      (click)="createWorkspace(workspaceNameInput.value)"
      [disabled]="loading"
      class="btn btn-primary btn-lg btn-block"
    >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      Create
    </button>
    <!-- <div class="form-group pt-1">
    <label for="workspace-description">Workspace Description</label>
    <input
      class="form-control form-control-lg"
      type="text"
      name="workspace-description"
      id="workspace-description"
    />
  </div> -->
  </div>
</div>
