<!-- <p>menu nav works!</p> -->
<div class="menu-nav-wrapper mt-3 ml-3 d-flex flex-column bg-white">
  <!-- <div class="w-100"> -->
  <div class="current-workspace-details d-flex flex-column">
    <div class="d-flex flex-row align-items-center">
      <div
        placement="right"
        ngbTooltip="{{
          (_workspacesStoreService.currentWorkspace$ | async).name
        }}"
        class="workspace-name d-flex flex-grow-1 fs-body-md fw-medium text-dark-600"
      >
        <span class="text-truncate">{{
          (_workspacesStoreService.currentWorkspace$ | async).name
        }}</span>
      </div>

      <div
        *ngIf="
          !(_workspacesStoreService.workspaceSubscriptionStatus$ | async).active
        "
        class="px-tag free-tag"
      >
        Free
      </div>

      <div
        *ngIf="
          !_userService.isUserLifetimePro &&
          (_workspacesStoreService.workspaceSubscriptionStatus$ | async)
            .active &&
          (_workspacesStoreService.currentWorkspace$ | async)
            ?.workspace_subscription?.plan_id === 'pro'
        "
        class="px-tag pro-tag"
      >
        Pro
      </div>

      <div
        *ngIf="
          !_userService.isUserLifetimePro &&
          (_workspacesStoreService.workspaceSubscriptionStatus$ | async)
            .active &&
          (_workspacesStoreService.currentWorkspace$ | async)
            ?.workspace_subscription?.plan_id === 'pro_plus'
        "
        class="px-tag pro-plus-tag"
      >
        Pro+
      </div>

      <div *ngIf="_userService.isUserLifetimePro" class="px-tag pro-plus-tag">
        LTD
      </div>
    </div>
    <span class="members-length fs-label-xs text-light-400"
      >{{
        (_workspacesStoreService.currentWorkspace$ | async)?.members?.length ||
          0
      }}
      members</span
    >
  </div>
  <div class="create-design-btn my-3">
    <a [routerLink]="['/upload']" class="px-btn px-btn-primary px-btn-md w-100">
      <mat-icon
        svgIcon="pencil"
        class="text-white w-20px h-20px fs-label-sm"
      ></mat-icon>
      <span class="ml-6px">Create a design</span>
    </a>
  </div>
  <ul class="navigation-list d-grid">
    <li [routerLinkActive]="['active']">
      <a
        [routerLink]="['/home']"
        class="px-btn px-btn-md w-100 justify-content-start"
      >
        <mat-icon
          svgIcon="home"
          class="text-dark-300 w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-8px">Home</span>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        [routerLink]="['/my-designs']"
        class="px-btn px-btn-md w-100 justify-content-start"
      >
        <mat-icon
          svgIcon="grid"
          class="text-dark-300 w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-8px">All Designs</span>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        [routerLink]="['/files']"
        class="px-btn px-btn-md w-100 justify-content-start"
      >
        <mat-icon
          svgIcon="folder"
          class="text-dark-300 w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-8px">All Files</span>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        [routerLink]="['/brand-presets']"
        class="px-btn px-btn-md w-100 justify-content-start"
      >
        <mat-icon
          svgIcon="brand-presets"
          class="text-dark-300 w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-8px">Brand Presets</span>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        [routerLink]="[
          '/workspaces/' +
            (_workspacesStoreService.currentWorkspace$ | async)._id +
            '/my-team'
        ]"
        class="px-btn px-btn-md w-100 justify-content-start"
      >
        <mat-icon
          svgIcon="users"
          class="text-dark-300 w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-8px">My Team</span>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        [routerLink]="[
          '/workspaces/' +
            (_workspacesStoreService.currentWorkspace$ | async)._id +
            '/settings'
        ]"
        class="px-btn px-btn-md w-100 justify-content-start"
      >
        <mat-icon
          svgIcon="cog-wheel"
          class="text-dark-300 w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-8px">Workspace Settings</span>
      </a>
    </li>
    <!-- <li [routerLinkActive]="['active']">
      <a
        [routerLink]="['/trash']"
        class="px-btn px-btn-md w-100 justify-content-start"
      >
        <mat-icon
          svgIcon="trash"
          class="text-dark-300 w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-8px">Trash</span>
      </a>
    </li> -->
  </ul>
</div>
