import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhotoroomAttributionService {

  cssClass: string = 'editor';
  show: boolean = false;

  private setShow = (boolValue: boolean) => {
    this.show = boolValue;
  }

  public showAttribution() {
    this.setShow(true);
    setTimeout(() => {
      this.setShow(false);
    }, 5000);
  }

  hideAttribution() {
    this.setShow(false);
  }
}