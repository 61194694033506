import { Directive, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[uploadImageModalCreate]'
})
export class UploadImageModalCreate {
  counter = 0;
  @Output() uploadImageModalCreate: EventEmitter<any> = new EventEmitter<any>();
  @HostBinding("class.drop-zone-active") dropZoneActive = false;

  constructor() { }

  @HostListener('dragenter', ['$event']) onDragEnter(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.counter++;
    this.dropZoneActive = true;
  };

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  };

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.counter--;
    if (this.counter == 0) this.dropZoneActive = false;
  }

  @HostListener('dragend', ['$event']) public onDragEnd(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropZoneActive = false;
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.counter = 0;
    this.dropZoneActive = false;
    // let files = evt.dataTransfer.files;
    if (evt.dataTransfer.files.length > 0) {
      this.uploadImageModalCreate.emit(evt.dataTransfer.files);
    }
  }
}