import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/services/toast.service';
import { WorkspacesStoreService } from 'src/app/core/services/workspaces-store.service';
import { UserService } from 'src/app/modules/auth/data/user.service';
import { WorkspaceService } from 'src/app/modules/workspaces/data/workspace.service';
import { Workspace } from '../../data/workspaces/workspace.model';

@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss']
})
export class CreateWorkspaceComponent implements OnInit {

  loading: boolean = false;
  addWorkspaceForm: FormGroup;
  submitted: boolean = false;
  constructor(
    private _modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _workspaceService: WorkspaceService,
    private _workspacesStoreService: WorkspacesStoreService,
    private _toastService: ToastService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.addWorkspaceForm = this._formBuilder.group({
      workspaceName: ['', [Validators.required]]
    });
  }

  get formFields() { return this.addWorkspaceForm.controls; }

  addWorkspace() {
    // console.log(this.formFields)
    this.submitted = true;
    if (this.addWorkspaceForm.invalid) return;
    this.loading = true;
    this._workspaceService.createWorkspace({ name: this.formFields.workspaceName.value, ownerId: this._userService.getUserId() })
      .subscribe(
        (data: Workspace) => {
          this.loading = false;
          this._workspacesStoreService.addWorkspaceToList(data);
          // this._workspacesStoreService.setCurrentWorkspace({
          //   ...this._workspacesStoreService.getCurrentWorkspace(), members: data.members
          // });
          this._modalService.dismissAll();
          this._router.navigate([`/workspaces/${data._id}/settings`]);
          this._toastService.show('Workspace created successfully!', { classname: 'bg-success text-light', delay: 5000 });
        }, (error: any) => {
          this.loading = false;
          this._modalService.dismissAll();
          this._toastService.show(error.error.message, { classname: 'bg-danger text-light', delay: 5000 });
          throw error;
        });
  }

  dismissModal() {
    this._modalService.dismissAll();
  }

}
