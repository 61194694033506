import { Directive, Renderer2, ElementRef, Input } from '@angular/core';
import { StepOption } from '../../data/onboarding.model';

@Directive({
    selector: '[appOnboardingOptionStates]'
})
export class OnboardingOptionStatesDirective {
    @Input() appOnboardingOptionStates: StepOption;
    constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        let option = this.appOnboardingOptionStates;
        // console.log(option.option)
        let cssClass = (option.cssClass) ? `hover-${option.cssClass.replace(/ /g, '-').toLowerCase()}` : `hover-${option.option.replace(/ /g, '-').toLowerCase()}`;
        this.renderer.addClass(this.elementRef.nativeElement, cssClass);
        // if (option.selected) this.renderer.addClass(this.elementRef.nativeElement, `selected-${option.option.replace(/ /g, '-').toLowerCase()}`);
    }
}