<div class="workspaces-list-nav-wrapper pl-24px pt-16px">
  <div class="workspaces-wrapper d-grid">
    <div
      *ngFor="let workspace of _workspacesStoreService.workspaces$ | async"
      (click)="changeWorkspace(workspace)"
      placement="right"
      ngbTooltip="{{ workspace.name }}"
      [class.active]="
        (_workspacesStoreService.currentWorkspace$ | async)._id ===
        workspace?._id
      "
      class="{{
        workspace.class
      }} workspace-thumbnail-box cursor-pointer fs-body-md fw-semibold"
    >
      <span>
        {{ workspace.name | extractLettersFromName }}
      </span>
    </div>

    <!-- <button class="px-btn">
      <svg width="56" height="56" viewBox="0 0 56 56">
        <path
          fill="#ED2F5B"
          stroke="none"
          d="
    M 0,28
    C 0,0 0,0 28,0
      56,0 56,0 56,28
      56,56 56,56 28,56
      0,56 0,56 0,28
    "
        ></path>
      </svg>
    </button> -->
    <div
      (click)="openCreateWorkspaceModal()"
      class="create-workspace-btn cursor-pointer d-flex align-items-center justify-content-center"
    >
      <mat-icon
        svgIcon="plus-thin"
        class="text-dark-300 fs-label-sm"
      ></mat-icon>
    </div>
  </div>
</div>
