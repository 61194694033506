<a
  href="https://photoroom.com/"
  rel="noopener"
  target="_blank"
  [class.show]="photoRoomAttributionService.show"
  [class.hide]="!photoRoomAttributionService.show"
  [class]="photoRoomAttributionService.cssClass"
  class="attribution-card position-absolute"
>
  <img
    src="https://static-assets.pixelied.com/images/photoroom-attribution-light.svg"
    alt=""
  />
</a>
