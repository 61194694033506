import { environment } from "src/environments/environment";

export abstract class Constants {
    static readonly PIXELIED_HOST_NAME: string = 'https://pixelied.com';

    static readonly STRUCTURED_DATA_FAQS: string = 'structured-data-faqs';
    static readonly STRUCTURED_DATA_HOWTOSTEPS: string = 'structured-data-howto';
    static readonly STRUCTURED_DATA_WEBPAGE: string = 'structured-data-webpage';
    static readonly STRUCTURED_DATA_ORG: string = 'structured-data-org';
    static readonly STRUCTURED_DATA_BREADCRUMBS: string = 'structured-data-breadcrumbs';
    static readonly STRUCTURED_DATA_VIDEO_SCHEMA: string = 'structured-data-video-schema';

    static readonly BASE64_TRANSPARENT_IMAGE: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    static readonly BASE64_LIGHT_GREY_IMAGE: string = 'data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN89B8AAskB44g04okAAAAASUVORK5CYII=';

    static readonly USER_NOT_LOGGED_IN: string = 'not_logged_in';
    static readonly PX_LIFETIME_PRO: string = 'px_lifetime_pro';
    static readonly APPSUMO_LIFETIME_PRO: string = 'appsumo_lifetime_pro';
    static readonly LIFETIME_PRO: string = 'lifetime_pro';
    static readonly VD_LIFETIME_PRO: string = 'vd_lifetime_pro';
    static readonly PERSONAL_WORKSPACE: string = 'Personal Workspace';
    static readonly PIXELIED_TITLE: string = 'Photo Editor: Free Online Graphic Design Suite - Pixelied';
    static readonly PIXELIED_DESCRIPTION: string = 'Boost your creativity with our lightning-fast image editor and versatile online graphics editor. Perfect for marketing, e-commerce, and social media designs.';

    static readonly OG_PIXELIED_TITLE: string = 'Photo Editor: Free Online Graphic Design Suite - Pixelied';
    static readonly OG_PIXELIED_DESCRIPTION: string = 'Boost your creativity with our lightning-fast image editor and versatile online graphics editor. Perfect for marketing, e-commerce, and social media designs.';
    static readonly OG_IMAGE: string = 'https://static-assets.pixelied.com/images/og-image.png';

    static readonly REFERRER: string = 'BLBRWPKzu22kMSFdqqBv';
    static readonly UTM_SOURCE_MEDIUM: string = 'u2fpWvzFQ2indrCetpDZ';

    static readonly MOCKUP_MAIN_PAGE_ID: string = 'MOCKUP-000-ABC-12YZ';
    static readonly SVG_MAIN_PAGE_ID: string = 'SVG-999-XYZ-34WX';

    static readonly SVG_FILES_PIXELIED_COM: string = 'https://svg-files.pixelied.com/';

    static readonly STRIPE_SCRIPT_URL: string = 'https://js.stripe.com/v3/';
    static readonly STRIPE_PUBLISHABLE_KEY: string = environment.stripePublishableKey;

    static readonly CANVAS_WIDTH_OFFSET_WHEN_RESIZING: number = 220;
    static readonly CANVAS_REMOVE_WATERMARK_BUTTON_WIDTH: number = 156;
    static readonly CANVAS_REMOVE_WATERMARK_BUTTON_HEIGHT: number = 32;

    static readonly LTD_PRO_TAGS = ['appsumo_lifetime_pro', 'px_lifetime_pro', 'vd_lifetime_pro', 'lifetime_pro'];

    static readonly COLORS_FILTER_LIST = [
        { color: 'red', colorCode: '#F95457' },
        { color: 'peach', colorCode: '#FD8E72' },
        { color: 'orange', colorCode: '#F9A034' },
        { color: 'yellow', colorCode: '#FEE17A' },
        { color: 'green', colorCode: '#51D197' },
        { color: 'cyan', colorCode: '#4BC9E4' },
        { color: 'blue', colorCode: '#1D77E8' },
        { color: 'purple', colorCode: '#5C309B' },
        { color: 'pink', colorCode: '#FD77BC' },
        { color: 'brown', colorCode: '#C1986B' },
        { color: 'grey', colorCode: '#D2CFCF' },
        { color: 'white', colorCode: '#FFF' },
        { color: 'black', colorCode: '#000' }
    ];

    static readonly POSITIONS_FILTER_LIST = [
        { position: 'front' },
        { position: 'top' },
        { position: 'isometric' },
    ]

    static readonly EDITOR_PARAM_TYPES = {
        'DESIGN_ID': 'design-id',
        'TEMPLATE_ID': 'template-id',
        'CUSTOM_SIZE': 'custom-size',
        'TEXT_TEMPLATE_ID': 'text-template-id',
        'ILLUSTRATION_URL': 'illustrationURL',
        'SVG_URL': 'svgURL',
        'MOCKUP_ID': 'mockup-id',
        'DEFAULT': 'default',
    }

    static readonly NOT_LOGGED_IN_DOWNLOADS_COUNT_LOCALSTORAGE = 'xy.quweoiu.op';
    static readonly IMAGE_UPLOAD_FEATURE_PAGE_LOCALSTORAGE = 'land.img';
    static readonly SVG_UPLOAD_FEATURE_PAGE_LOCALSTORAGE = 'land.svg';
    static readonly EDITOR_PAGE_REF_ID = '7b002d3e-900a-454e-af35-0a7dc52aef77';
    static readonly EDITOR_TOOLS_REF_IDS = {
        'IMAGE_SIDEBAR': '76a76778-50bb-421a-8f55-a6b00632779d',
        'TEXT_SIDEBAR': '655a723e-ac0e-453a-b83f-3af8e388666c',
        'ICONS_SIDEBAR': '94503d07-4881-40e3-a42f-3413c0a149c1',
        'ELEMENTS_SIDEBAR': '16bc1baa-8823-4978-8fee-4e5a708acfc7',
        'ELEMENTS_SPARKLE_SIDEBAR': '0ba481d5-7e8c-422d-a44d-5ec572b60965',
        'ELEMENTS_SPEECH_BUBBLE_SIDEBAR': '5c02046b-9a53-4efa-9135-012b1c395cda',
        'BLEND_MODES': '2539ace7-2579-44a8-b9bb-2b2c4015c848',
        'MY_FILES_SIDEBAR': '6b5dba91-12bb-4c73-9a33-eca2b931bc2c',
        'IMAGE_FILTERS_EFFECTS': '6ba2e0d4-ac5d-4b8a-9883-587ffaa470c0',
        'BLUR_BACKGROUND': '9196f92d-6f24-4f69-8a95-4c8a99b3e554',
        'PHOTO_ENHANCER': '426d7c75-fdc8-42ff-a3f5-bb73877398c1',
        'IMAGE_ENLARGER': '07d9eaa2-36a5-423c-9e31-949c43f4d326',
        'IMAGE_UPSCALER': '31f1a71d-881d-4f9e-8cf8-e6038a2c37db',
        'IMAGE_SHARPENER': 'fc00a986-cfea-482f-b4b5-9586ac4eaaf0',
        'UNBLUR': 'c0b43bb7-c6a9-42b3-b4a7-a4e9719b9bc3',
        'BRIGHTNESS': 'fee8aa78-39fb-4790-a509-3e19dbbc9383',
        'DARKEN': '79c9cc2e-f655-4510-b89d-f39a35a3ab81',
        'CROP_TOOL': '63fc78ae-1a9e-4d71-9dc0-4b2403a53640',
        'RESIZE_DESIGN_TOOL': 'a90dd182-e9ef-46ff-beec-5b3c95313fc8',
        'REMOVE_IMAGE_BACKGROUND_TOOL': 'da1ef52c-9c55-41ac-bc23-9537c4e45495',
        'BLUR_FACE': 'c8800400-77cf-415e-9b31-4b5c57e11d8f',
        'BLUR': '60e2872b-1c7c-409b-b68a-7d2a3a319d11',
        'CENSOR_IMAGE': '84db2bf6-28c9-4109-bb48-b5d666c9dc0c',
        'CHANGE_COLOR_OF_IMAGE': '8f7ac425-46ee-4320-9a70-0f1451a50cd7',
        'SATURATION': 'dc27a3a8-00cf-464a-95aa-1583d9875338',
        'EMBOSS': '5dac65d5-24ab-4152-97d8-82273bd0600d',
        'NOISE': 'f345e125-684a-4f23-968e-c24eef517519',
        'HUE': '2d256826-8c6e-43ab-8959-4d487abeffde',
        'CONTRAST': '8343910c-43d3-4e83-bb59-c49745a9842c',
        'PIXELATE': '28ef3139-2300-4a5c-a293-549ebc37346f',
        'TINT': '14a87395-afec-48d5-9c83-0b752a973cda',
        // 'NEGATIVE_IMAGE': 'b7b2501b-1e9d-4d57-aa5a-d7e4ea08215d',
        'GRAYSCALE_FILTER': '7f654df0-c9d2-49ec-9e7d-b7fd01fd7e98',
        'BLACKWHITE_FILTER': '1297bf7d-fa69-49b5-9f71-aca68820744c',
        'INVERT_FILTER': 'c5e79aff-f3b4-48e1-ab46-df3ccfd11b89',
        'KODACHROME_FILTER': 'e85b19fd-e735-4ca4-9b2a-8bf23a3782d7',
        'SEPIA_FILTER': 'ef12e5d1-7a4c-444d-a13e-5c6de64e9afe',
        'TECHNICOLOR_FILTER': 'c70081f2-5b15-4790-aeb6-bea031b9478e',
        'VIGNETTE_FILTER': '6de04fc0-92e2-4938-b86f-097c8e08acac',
        'VINTAGE_FILTER': '81b21c24-5029-463f-be39-2d72fd34233a',
        'BORDER_TOOL': 'e1f7fdea-76c0-49b9-bedc-f65b790342ac',
        'SHADOW_TOOL': '426b5852-e8a7-4a86-8db9-3ba6f9169607',
        'OPACITY_TOOL': '17904b48-14a3-4865-96fd-c26f1831edad',
        'ALIGNMENT_TOOL': '973b65f1-2226-44fd-bdba-55c98d7473c3',
        'LAYERS_TOOL': '8730aebd-c0a2-47e6-b555-ff2fd9231a24',
        'FLIP_TOOL': '9090bcb9-5f2d-40e8-8089-ee284dafaccf',
        'LOCK_TOOL': 'b63f4f59-1cf7-464c-b617-591b378d0d38',
        'DUPLICATE_TOOL': 'f2547ea3-4a19-4fcd-b5d0-efc3943813f8',
        'ZOOM_TOOL': 'a5642340-306e-49fe-b509-686f011f0563',
        'MOCKUP_TOOL': 'dc0b9155-6070-4e24-a0bb-1c7b2fbf1c6d',
        'PRINT_TOOL': '0cc6adae-3ab5-463b-9b81-1b19baf608dc'
    };

    static readonly EDITOR_IMAGE_TOOLS_REF_IDS = [
        Constants.EDITOR_TOOLS_REF_IDS.CROP_TOOL,
        Constants.EDITOR_TOOLS_REF_IDS.BLUR_BACKGROUND,
        Constants.EDITOR_TOOLS_REF_IDS.PHOTO_ENHANCER,
        Constants.EDITOR_TOOLS_REF_IDS.IMAGE_ENLARGER,
        Constants.EDITOR_TOOLS_REF_IDS.IMAGE_UPSCALER,
        Constants.EDITOR_TOOLS_REF_IDS.IMAGE_SHARPENER,
        Constants.EDITOR_TOOLS_REF_IDS.UNBLUR,
        Constants.EDITOR_TOOLS_REF_IDS.BRIGHTNESS,
        Constants.EDITOR_TOOLS_REF_IDS.SATURATION,
        Constants.EDITOR_TOOLS_REF_IDS.CONTRAST,
        Constants.EDITOR_TOOLS_REF_IDS.EMBOSS,
        Constants.EDITOR_TOOLS_REF_IDS.BLUR_FACE,
        Constants.EDITOR_TOOLS_REF_IDS.BLUR,
        Constants.EDITOR_TOOLS_REF_IDS.CENSOR_IMAGE,
        Constants.EDITOR_TOOLS_REF_IDS.CHANGE_COLOR_OF_IMAGE,
        Constants.EDITOR_TOOLS_REF_IDS.NOISE,
        Constants.EDITOR_TOOLS_REF_IDS.HUE,
        Constants.EDITOR_TOOLS_REF_IDS.TINT,
        Constants.EDITOR_TOOLS_REF_IDS.GRAYSCALE_FILTER,
        Constants.EDITOR_TOOLS_REF_IDS.INVERT_FILTER,
        Constants.EDITOR_TOOLS_REF_IDS.KODACHROME_FILTER,
        Constants.EDITOR_TOOLS_REF_IDS.SEPIA_FILTER,
        Constants.EDITOR_TOOLS_REF_IDS.TECHNICOLOR_FILTER,
        Constants.EDITOR_TOOLS_REF_IDS.VIGNETTE_FILTER,
        Constants.EDITOR_TOOLS_REF_IDS.VINTAGE_FILTER,
        Constants.EDITOR_TOOLS_REF_IDS.BLACKWHITE_FILTER,
        Constants.EDITOR_TOOLS_REF_IDS.BLEND_MODES,
        Constants.EDITOR_TOOLS_REF_IDS.DARKEN,
    ]

    static readonly LTD_LANDING_AB_TESTING = {
        'LTD_LANDING_V1': 'LTD_LANDING_V1',
        'LTD_LANDING_V2': 'LTD_LANDING_V2',
    };

    static readonly LTD_CHECKOUT_AB_TESTING = {
        'LTD_CHECKOUT_V1': 'LTD_CHECKOUT_V1',
        'LTD_CHECKOUT_V2': 'LTD_CHECKOUT_V2',
    };

    static readonly PLANS = {
        MONTHLY_PRO_PLAN: {
            priceId: 'MONTHLY_PRO_PLAN',
            price: '9.95',
            duration: 'm',
            desc: 'At US$9.95/month'
        },
        YEARLY_PRO_PLAN: {
            priceId: 'YEARLY_PRO_PLAN',
            price: '84',
            duration: 'y',
            desc: 'At US$/month (30% discount)'
        },
        // MONTHLY_PRO_PLUS_PLAN: {
        //     priceId: 'MONTHLY_PRO_PLUS_PLAN',
        //     price: '19.95',
        //     duration: 'm',
        //     desc: 'At US$19.95/month'
        // },
        // YEARLY_PRO_PLUS_PLAN: {
        //     priceId: 'YEARLY_PRO_PLUS_PLAN',
        //     price: '168',
        //     duration: 'y',
        //     desc: 'At US$14/month (30% discount)'
        // },
    };

    static readonly TEAM_MEMBER_SUBSCRIPTION_PLANS = {
        "MONTHLY_TEAM_MEMBER_PRO_PLAN": {
            id: "9d613196-9370-4e56-8333-94cdadc16704"
        },
        "MONTHLY_TEAM_MEMBER_PRO_PLUS_PLAN": {
            id: "66ca68fd-7352-482d-8e0b-92988f7bd4a0"
        },
        "YEARLY_TEAM_MEMBER_PRO_PLAN": {
            id: "bfcf031f-7af2-4df2-a352-43d29d8234fa"
        },
        "YEARLY_TEAM_MEMBER_PRO_PLUS_PLAN": {
            id: "aca155c3-8203-465a-905b-2f2c754b0499"
        },
    }

    static readonly PIXELIED_SUBSCRIPTION_PLANS = {
        "MONTHLY_PRO_PLAN": {
            title: "Pro",
            id: "91e85001-a4c7-4fc2-9660-95f428303795",
            // priceId: "MONTHLY_PRO_PLAN",
            price: 9.95,
            pricePerMonth: 9.95,
            pricePerTeamMember: 5,
            plans: {
                bg_remove_credits: '50',
                ai_image_generation_credits: '100',
            },
            features: [
                // [
                "100 AI image generation credits",
                "50 BG remover credits",
                "Access to Pro templates",
                "500,000+ premium icons and illustrations",
                "Resize designs to 30+ formats",
                // ],
                // [
                "Unlimited custom fonts upload",
                "Unlimited folders",
                "Unlimited brand presets",
                "Priority customer support"
                // ],
            ]
        },
        "MONTHLY_PRO_PLUS_PLAN": {
            title: "Pro+",
            id: "5a205fab-723a-4234-9eca-76df56a16b10",
            // priceId: "MONTHLY_PRO_PLUS_PLAN",
            price: 16.95,
            pricePerMonth: 16.95,
            pricePerTeamMember: 7,
            plans: {
                bg_remove_credits: 'unlimited',
                ai_image_generation_credits: '300',
            },
            features: [
                // [
                "300 AI image generation credits",
                "Unlimited BG remover credits",
                'AI photo sharpener',
                'AI photo enhancer',
                'AI face cutout',
                // ],
                // [
                '2000+ realistic product mockups',
                'AI photo colorizer',
                'AI cartoon selfie',
                'Priority customer support',
                // ],
            ]
        },
        "YEARLY_PRO_PLAN": {
            id: "8deb0cd6-39e2-49b7-abb5-0583769e2a19",
            pricePerMonth: 3.9,
            oldPricePerMonth: 9.95,
            price: 47,
            pricePerTeamMember: 48,
        },
        "YEARLY_PRO_PLUS_PLAN": {
            id: "a0240777-af0a-4d79-9277-cba7560bb60b",
            pricePerMonth: 6.5,
            oldPricePerMonth: 16.95,
            price: 79,
            pricePerTeamMember: 67,
        }
    }

    static readonly PIXELIED_LTD_PLANS = {
        'LTD_PLAN_ONE': {
            title: 'Starter',
            priceId: 'LTD_PLAN_ONE',
            price: 97,
            strikethroughPrice: 1164,
            plans: {
                workspaces: 5,
                team_members: 2,
                bg_remove_credits: 100
            }
        },
        'LTD_PLAN_TWO': {
            title: 'Growth',
            priceId: 'LTD_PLAN_TWO',
            price: 197,
            strikethroughPrice: 2328,
            plans: {
                workspaces: 10,
                team_members: 5,
                bg_remove_credits: 200
            }
        },
        'LTD_PLAN_THREE': {
            title: 'Premiere',
            priceId: 'LTD_PLAN_THREE',
            price: 297,
            strikethroughPrice: 3492,
            plans: {
                workspaces: 20,
                team_members: 10,
                bg_remove_credits: 350
            }
        },
        'LTD_PLAN_FOUR': {
            title: 'Agency',
            priceId: 'LTD_PLAN_FOUR',
            price: 397,
            strikethroughPrice: 4656,
            plans: {
                workspaces: 35,
                team_members: 15,
                bg_remove_credits: 500
            }
        },
        'LTD_TEAM_MEMBER_ADDON': {
            priceId: 'LTD_TEAM_MEMBER_ADDON',
            price: 39
        },
        'LTD_BG_REMOVER_CREDITS_ADDON': {
            priceId: 'LTD_BG_REMOVER_CREDITS_ADDON',
            price: 29,
            quantity: 50
        },
        'LTD_WORKSPACE_ADDON': {
            priceId: 'LTD_WORKSPACE_ADDON',
            price: 19
        },
    }

    static readonly FACEBOOK_CONNECT_SCRIPT_URL: string = '//connect.facebook.net/en_US/sdk.js';
    static readonly ZENDESK_CHAT_SCRIPT_URL: string = 'https://static.zdassets.com/ekr/snippet.js?key=54917e1f-d917-4819-a92a-74f773472e71';
    static readonly CRISP_SCRIPT_URL: string = 'https://client.crisp.chat/l.js';
    static readonly GA_SCRIPT_URL: string = 'https://www.googletagmanager.com/gtag/js?id=UA-165120692-1';

    static readonly MAIN_CANVAS: string = 'main_canvas';
    static readonly MOCKUP_CANVAS: string = 'mockup_canvas';

    static readonly DESIGN_SAVING: string = 'Saving...';
    static readonly DESIGN_SAVED: string = 'Saved!';
    static readonly DESIGN_UNSAVED: string = 'Unsaved';

    static readonly MIN_CANVAS_WIDTH: number = 50;
    static readonly MIN_CANVAS_HEIGHT: number = 50;

    static readonly MAX_IMAGE_UPLOAD_WIDTH: number = 3840; // ultra hd width in pixels
    static readonly MAX_IMAGE_UPLOAD_HEIGHT: number = 2160; // ultra hd height in pixels

    static readonly ONE_HOUR: number = 1000 * 60 * 60;
    static readonly ONE_DAY: number = 1000 * 60 * 60 * 24;

    static readonly MAX_IMAGE_FILE_UPLOAD: number = 25; // mb
    static readonly MAX_BG_REMOVE_IMAGE_FILE_UPLOAD: number = 12; // mb
    static readonly MAX_FONT_FILE_UPLOAD: number = 12; // mb
    static readonly MAX_MULTIPLE_FILES_AT_ONCE: number = 20; // no more than 20 files can be uploaded at once

    static readonly SIDEBAR_ITEM_TYPES = {
        DefaultSidebarItem: 'defaultSidebarItem',
        ObjectEditingSidebarItem: 'objectEditingSidebarItem',
        IntegrationsSidebarItem: 'integrationsSidebarItem',
    }

    static readonly SIDEBAR_ITEMS = {
        TEMPLATES: {
            id: 'templates',
            icon: 'search_templates',
            title: 'Templates',
            type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        },
        IMAGES: {
            id: 'images',
            icon: 'search_images',
            title: 'Images',
            type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        },
        AI_IMAGE_GENERATOR: {
            id: 'ai_image_generator',
            icon: 'ai_image_generator',
            title: 'Image AI',
            type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        },
        TEXT_TEMPLATES: {
            id: 'text_templates',
            icon: 'add_text',
            title: 'Text',
            type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        },
        // AICOPY: {
        //     id: 'ai-copy',
        //     icon: 'add_text',
        //     title: 'AI Copy',
        //     type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        // },
        MOCKUPS: {
            id: 'mockups',
            icon: 'mockups',
            title: 'Mockups',
            type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        },
        // BRANDIFY: {
        //     id: 'brandify',
        //     icon: 'brand_presets',
        //     title: 'Brandify',
        //     type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        // },
        ELEMENTS: {
            id: 'elements',
            icon: 'elements2',
            title: 'Elements',
            type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        },
        // ICONS: {
        //     id: 'icons',
        //     icon: 'search-icons',
        //     title: 'Icons',
        //     type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        // },
        ILLUSTRATIONS: {
            id: 'illustrations',
            icon: 'illustrations',
            title: 'Illustrations',
            type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        },
        BLEND: {
            id: 'blend',
            icon: 'color_palettes',
            title: 'Blend',
            type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        },
        MY_FILES: {
            id: 'my_files',
            icon: 'folder',
            title: 'My Files',
            type: Constants.SIDEBAR_ITEM_TYPES.DefaultSidebarItem
        },
        // INTEGRATIONS: {
        //     id: 'integrations',
        //     icon: 'integrations',
        //     title: 'Icons',
        //     type: Constants.SIDEBAR_ITEM_TYPES.IntegrationsSidebarItem
        // }
    }

    // static readonly OBJECT_EDITING_MODES = {
    //     TEXTBOX_FONTFAMILY_MODE: 'textbox_fontfamily_mode',
    //     OBJECT_FILL_BORDER_MODE: 'object_fill_border_mode',
    // }

    static readonly IMAGE_DEFAULT_PROPS: fabric.IImageOptions = {
        angle: 0,
        lockUniScaling: true,
        objectCaching: false,
        noScaleCache: false,
        perPixelTargetFind: true
        // crossOrigin: 'anonymous',
    }

    static readonly TEXT_DEFAULT_PROPS: fabric.ITextOptions = {
        fontFamily: "Lato",
        fill: "#000000",
        lineHeight: 0.9,
        scaleX: 1,
        scaleY: 1,
        fontWeight: "bold",
        textAlign: "center",
        fontStyle: "",
        objectCaching: false,
        perPixelTargetFind: false
    }

    static readonly KEYBOARD_SHORTCUTS = {
        LOCK: 'Lock (Ctrl/⌘ + 2)',
        UNLOCK: 'Unlock (Ctrl/⌘ + Shift + 2)',
        DUPLICATE: 'Duplicate (Ctrl/⌘ + D)',
        DELETE: 'Delete Objects(Delete OR Backspace Key)',
        GROUP: 'Group Objects (Ctrl/⌘ + G)',
        UNGROUP: 'Ungroup Objects (Ctrl/⌘ + Shift + G)',
        ZOOM_IN: 'Zoom In (Ctrl/⌘ + Plus Key)',
        ZOOM_OUT: 'Zoom Out (Ctrl/⌘ + Minus Key)',
        FIT_ZOOM: 'Fit Zoom (Shift + 1)',
        UNDO: 'Undo (Ctrl/⌘ + Z)',
        REDO: 'Redo (Ctrl/⌘ + Shift + Z)'
    }

    static readonly DEFAULT_CANVAS_OPTIONS = {
        renderOnAddRemove: false,
        controlsAboveOverlay: true,
        backgroundColor: '#ffffff',
        hoverCursor: "default",
        selection: true,
        selectionBorderColor: "white",
        preserveObjectStacking: true,
        selectionColor: "rgba(95, 88, 183, 0.3)",
        selectionLineWidth: 1,
        fireRightClick: true,
        stopContextMenu: true,
        enableRetinaScaling: true,
        skipOffscreen: true
        /**
         * not blurry after this property,
         * TODO: maybe only use this property for certain objects
         */
        // imageSmoothingEnabled: false,
        // enableRetinaScaling: false
        // allowTouchScrolling: false
    }

    static readonly COLORS = {
        artboardDarkBGColor: 'rgba(48, 53, 68, 1)',
        artboardDarkBGColorOpacity: 'rgba(48, 53, 68, 0.8)',
        artboardLightBGColor: 'rgba(225, 227, 233, 1)',
        artboardLightBGColorOpacity: 'rgba(225, 227, 233, 0.8)',
        artboardDarkStrokeColor: 'rgba(255, 255, 255, 0.25)',
        artboardLightStrokeColor: 'rgba(0, 0, 0, 0.25)',
        // placeholderImageLightBase64: 'data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN89B8AAskB44g04okAAAAASUVORK5CYII=',
        placeholderImageLightBase64: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN89+HbfwAJfQPVIyALpwAAAABJRU5ErkJggg==`,
        placeholderImageDarkBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNUVjf5DwACcgF/x29siwAAAABJRU5ErkJggg=='
    }

    static readonly SHARPEN_MATRIX_VALUES = {
        '0.5': [
            0, -1, 0,
            -1, 5, -1,
            0, -1, 0
        ],
        '1': [
            -1, -1, -1,
            -1, 9, -1,
            -1, -1, -1
        ],
        // '1': [
        //     -1, -1, -1, -1, -1,
        //     -1, -1, -1, -1, -1,
        //     -1, -1, 25, -1, -1,
        //     -1, -1, -1, -1, -1,
        //     -1, -1, -1, -1, -1
        // ]
    }

    static readonly EMBOSS_MATRIX_VALUES = {
        '0.5': [
            1, 1, 1,
            1, 0.7, -1,
            -1, -1, -1
        ],
        '1': [
            1, 1, 1,
            1, 0, -1,
            -1, -1, -1
        ],
        // '1': [
        //     2, 0, 0,
        //     0, -1, 0,
        //     0, 0, -1,
        // ],
    }
}