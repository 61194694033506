import { Component, Input, OnInit } from '@angular/core';
import { PhotoroomAttributionService } from 'src/app/core/services/photoroom-attribution.service';

@Component({
  selector: 'app-photoroom-attribution',
  templateUrl: './photoroom-attribution.component.html',
  styleUrls: ['./photoroom-attribution.component.scss']
})
export class PhotoroomAttributionComponent implements OnInit {

  constructor(public photoRoomAttributionService: PhotoroomAttributionService) { }

  ngOnInit(): void {
  }

}
