import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { UserService } from 'src/app/modules/auth/data/user.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private _userService: UserService, private _router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            // retry(1),
            catchError((error, caught) => {
                if (error.status === 401) { // 401 == Unauthorized, 403 == Restricted || Forbidden
                    /**
                     * TODO: Maybe refresh the token, if NOT then logout user.
                     */
                    this._userService.logoutUserSession(false);
                    // this._userService.removeToken();
                    this._router.navigate([`/login`]);
                    return of(error);
                }
                // else if (error.status == 403) { // 403 == Forbidden Request, auto logout
                //     this._userService.removeToken();
                //     this._router.navigate([`/login`]);
                //     return of(error);
                // } // Handle 4xx-->ClientSide & 5xx-->ServerSide errors and then rethrow error to the
                else {
                    // console.log(error);
                    // throw error;
                    return throwError(error);
                }
            }));
    }
}