<div class="bottom-sticky-navbar">
  <div class="new-design-btn-wrapper position-absolute">
    <a [routerLink]="['/upload']" class="px-btn px-btn-primary only-icon-btn">
      <mat-icon
        style="width: 32px; height: 32px"
        svgIcon="plus-thin"
        class="fs-body-sm"
      ></mat-icon>
    </a>
  </div>
  <div>
    <footer class="d-grid">
      <button
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <mat-icon
          svgIcon="home"
          style="width: 32px; height: 32px"
          class="text-dark-300 fs-label-md"
        ></mat-icon>
      </button>
      <a
        [routerLink]="['/home']"
        [routerLinkActive]="['active']"
        class="navlink d-flex flex-column justify-content-center align-items-center"
      >
        <mat-icon
          svgIcon="home"
          style="width: 26px; height: 26px"
          class="text-dark-300 fs-label-md"
        ></mat-icon>
        <span class="fs-label-xs text-dark-600 fw-medium">Home</span>
      </a>
      <a
        [routerLink]="['/templates']"
        [routerLinkActive]="['active']"
        class="navlink d-flex flex-column justify-content-center align-items-center"
      >
        <mat-icon
          svgIcon="web-layout"
          style="width: 26px; height: 26px"
          class="text-dark-300 fs-label-md"
        ></mat-icon>
        <span class="fs-label-xs text-dark-600 fw-medium">Templates</span>
      </a>
      <a
        [routerLink]="['/menu']"
        [routerLinkActive]="['active']"
        class="navlink d-flex flex-column justify-content-center align-items-center"
      >
        <mat-icon
          svgIcon="bars-menu"
          style="width: 26px; height: 26px"
          class="text-dark-300 fs-label-md"
        ></mat-icon>
        <span class="fs-label-xs text-dark-600 fw-medium">Menu</span>
      </a>
    </footer>
  </div>
</div>
