import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/services/toast.service';
import { WorkspacesStoreService } from 'src/app/core/services/workspaces-store.service';
import { Workspace } from 'src/app/shared/data/workspaces/workspace.model';
import { CreateWorkspaceComponent } from 'src/app/shared/modals/create-workspace/create-workspace.component';

@Component({
  selector: 'app-workspaces-list-nav',
  templateUrl: './workspaces-list-nav.component.html',
  styleUrls: ['./workspaces-list-nav.component.scss']
})
export class WorkspacesListNavComponent implements OnInit {

  constructor(
    private _location: Location,
    private _router: Router,
    public _workspacesStoreService: WorkspacesStoreService,
    private _toastService: ToastService,
    private _modalService: NgbModal
  ) { }

  ngOnInit(): void { }

  changeWorkspace(workspace: Workspace) {
    this._workspacesStoreService.setCurrentWorkspace(workspace);
    // console.log(this._location.path())
    if (this._location.path().includes('/workspaces')) {
      let lastPath = this._location.path().split('/')[3];
      // console.log(this._location.path().split('/'))
      this._router.navigate([`/workspaces/${this._workspacesStoreService.getCurrentUserWorkspaceId()}/${lastPath}`]);
      // this._location.go(`/workspaces/${this._workspacesStoreService.getCurrentUserWorkspaceId()}/${lastPath}`);
      // location.reload();
    }
    this._toastService.show(`You have switched to ${workspace.name} workspace`, { classname: 'bg-success text-light', delay: 5000 });
  }

  openCreateWorkspaceModal() {
    this._modalService.open(CreateWorkspaceComponent);
  }

}
