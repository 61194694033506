<!-- <ngx-loading-bar [color]="'white'" [height]="'2px'"></ngx-loading-bar> -->
<router-outlet>
  <span class="app-loader" *ngIf="loading"></span>
</router-outlet>

<!-- somewhere in your root component template -->
<app-toasts-container
  aria-live="polite"
  aria-atomic="true"
></app-toasts-container>

<app-photoroom-attribution></app-photoroom-attribution>
