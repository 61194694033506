<div class="px-modal-card">
  <div class="header d-flex flex-row align-items-center">
    <span class="flex-grow-1 fs-body-lg fw-medium text-dark-600"
      >Create Workspace</span
    >
    <button
      (click)="dismissModal()"
      class="px-btn px-btn-tertiary only-icon-btn"
    >
      <mat-icon
        aria-label="Close"
        svgIcon="cross"
        class="text-dark-300 fs-label-sm cursor-pointer"
      ></mat-icon>
    </button>
  </div>
  <form role="form" [formGroup]="addWorkspaceForm" (ngSubmit)="addWorkspace()">
    <div
      [class.warning]="submitted && formFields.workspaceName.errors"
      class="px-form-group d-flex flex-column mt-32px"
    >
      <label for="workspaceName" class="px-form-label">Workspace name</label>
      <input
        #workspaceNameInput
        formControlName="workspaceName"
        class="px-form-control px-form-control-lg"
        type="text"
        placeholder="e.g. Pixelied"
        name="workspaceName"
        id="workspaceName"
      />
    </div>
    <div
      *ngIf="submitted && formFields.workspaceName.errors"
      class="px-form-control-hint-text"
    >
      <div *ngIf="formFields.workspaceName.errors.required">
        Workspace name is required
      </div>
    </div>

    <div
      class="d-grid mt-32px"
      style="grid-template-columns: 1fr 1fr; gap: 12px"
    >
      <button
        type="button"
        (click)="dismissModal()"
        class="px-btn px-btn-lg px-btn-secondary-outline"
      >
        Cancel
      </button>
      <button
        type="submit"
        [disabled]="loading || workspaceNameInput.value.length == 0"
        class="px-btn px-btn-lg px-btn-primary align-items-center"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Create
      </button>
    </div>
  </form>
</div>

<!-- <div class="px-card px-modal-card">
  <button
    type="button"
    class="close close-btn"
    aria-label="Close"
    (click)="dismissModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="px-card-header d-flex flex-column">
    <span class="title">Create A Workspace</span>
  </div>
  <div class="px-card-body">
    <form
      role="form"
      [formGroup]="createWorkspaceForm"
      (ngSubmit)="createWorkspace()"
      class="modal-form"
    >
      <div class="form-group">
        <label for="name">Workspace Name</label>
        <input
          formControlName="name"
          type="text"
          class="form-control form-control-lg"
          id="name"
        />
        <div
          *ngIf="submitted && formFields.name.errors"
          style="display: block"
          class="invalid-feedback"
        >
          <div *ngIf="formFields.name.errors.required">
            Workspace name is required
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="workpsaceDescription">Workspace Description</label>
        <textarea
          formControlName="description"
          cols="30"
          rows="3"
          class="form-control form-control-lg"
          id="workpsaceDescription"
        ></textarea>
        <div
          *ngIf="submitted && formFields.description.errors"
          style="display: block"
          class="invalid-feedback"
        >
          <div *ngIf="formFields.description.errors.required">
            Workspace description is required
          </div>
        </div>
      </div>
      <div class="btn-area d-flex" style="justify-content: flex-end">
        <button
          [disabled]="isLoading"
          type="button"
          class="btn btn-outline-dark"
          (click)="dismissModal()"
        >
          <span
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Cancel
        </button>
        <button [disabled]="isLoading" type="submit" class="btn btn-primary">
          <span
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Create
        </button>
      </div>
    </form>
  </div>
</div> -->
