import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Workspace } from "src/app/shared/data/workspaces/workspace.model";
import { environment } from "src/environments/environment";
import { UserService } from "../../auth/data/user.service";
import { Onboarding, OnboardingStep, StepIds, StepTypes, UploadTypes, UserOnboarding, UserOnboardingData } from "./onboarding.model";

@Injectable()
export class OnboardingService {

  API_URL = environment.apiUrl;
  totalSteps: number = 5;
  selectedStepId: string = StepIds.CREATE_WORKSPACE;
  userOnboardingData: UserOnboarding;
  currentOnboardingStep: OnboardingStep;
  onboardingData: Onboarding = {
    steps: [
      {
        stepId: StepIds.CREATE_WORKSPACE,
        multiSelection: false,
        stepType: StepTypes.STEP_OPTIONS,
        stepNo: 1,
        title: 'Create a new workspace',
        description: 'Create, Invite & Collaborate'
      },
      {
        stepId: StepIds.USER_DESCRIPTION,
        multiSelection: false,
        stepType: StepTypes.STEP_OPTIONS,
        stepNo: 2,
        title: 'What best describes you?',
        description: 'This helps us tailor your experience to your needs',
        stepOptions: [
          {
            option: 'Small Business',
            value: 'Small Business'
          },
          {
            option: 'Enterprise',
            value: 'Enterprise'
          },
          {
            option: 'Personal User',
            value: 'Personal User'
          },
          {
            option: 'Non Profit',
            value: 'Non Profit'
          },
          {
            option: 'Freelancer',
            value: 'Freelancer'
          },
          {
            option: 'Agency',
            value: 'Agency'
          },
          {
            option: 'Educator',
            value: 'Educator'
          },
          {
            option: 'Other',
            value: 'Other'
          },

        ]
      },
      {
        stepId: StepIds.SOURCE,
        multiSelection: false,
        stepType: StepTypes.STEP_OPTIONS,
        stepNo: 3,
        title: 'From where did you hear about Pixelied?',
        // description: '',
        stepOptions: [
          {
            option: 'Search Engine',
            value: 'Search Engine'
          },
          {
            cssClass: 'Source Social Media',
            option: 'Social Media',
            value: 'Social Media'
          },
          {
            option: 'Online Review',
            value: 'Online Review'
          },
          {
            option: 'Friend',
            value: 'Friend'
          },
          {
            option: 'Word of mouth',
            value: 'Word of mouth'
          },
          {
            option: 'Other',
            value: 'Other'
          }
        ]
      },
      {
        stepId: StepIds.USAGE,
        multiSelection: true,
        stepType: StepTypes.STEP_OPTIONS,
        stepNo: 4,
        title: 'What do you want to use Pixelied for?',
        description: `Based on your selection, we'll provide the best option to help you get started.`,
        stepOptions: [
          {
            cssClass: 'Usage Social Media',
            option: 'Social Media',
            value: 'Social Media',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/social-media-graphic.png'
          },
          {
            option: 'Mockups',
            value: 'Mockups',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/mockups-graphic.png'
          },
          {
            option: 'Photo Editing',
            value: 'Photo Editing',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/photo-editing-graphic.png'
          },
          {
            option: 'BG Remover',
            value: 'BG Remover',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/bg-remover-graphic.png'
          },
          {
            option: 'SVG Editor',
            value: 'SVG Editor',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/svg-editor-graphic.png'
          },
          {
            option: 'E-commerce',
            value: 'E-commerce',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/ecom-graphic.png'
          },
          {
            option: 'Banners',
            value: 'Banners',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/banners-graphic.png'
          },
          {
            cssClass: 'Usage Other',
            option: 'Other',
            value: 'Other',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/usage-other-graphic.png'
          }
        ]
      },
      {
        stepId: StepIds.SOCIAL_MEDIA_FORMATS,
        multiSelection: true,
        stepType: StepTypes.STEP_OPTIONS,
        stepNo: 5,
        title: 'Select platforms you would like to use for your designs',
        // description: '',
        stepOptions: [
          {
            option: 'Facebook',
            value: 'Facebook',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/facebook-graphic.svg',
            goToUrl: '/templates/facebook-post'
          },
          {
            option: 'Instagram',
            value: 'Instagram',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/instagram-graphic.svg',
            goToUrl: '/templates/instagram-post'
          },
          {
            option: 'Twitter',
            value: 'Twitter',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/twitter-graphic.svg',
            goToUrl: '/templates/twitter-post'
          },
          {
            option: 'YouTube',
            value: 'YouTube',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/youtube-graphic.svg',
            goToUrl: '/templates/youtube-banner'
          },
          {
            option: 'LinkedIn',
            value: 'LinkedIn',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/linkedin-graphic.svg',
            goToUrl: '/templates/linkedin-post'
          },
          {
            option: 'Twitch',
            value: 'Twitch',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/twitch-graphic.svg',
            goToUrl: '/templates/twitch-panel'
          },
          {
            option: 'Pinterest',
            value: 'Pinterest',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/pinterest-graphic.svg',
            goToUrl: '/templates/pinterest-pin'
          },
          {
            option: 'Other',
            value: 'Other',
            // imgURL: 'https://static-assets.pixelied.com/onboarding/images/usage-other-graphic.svg',
            goToUrl: '/templates'
          }
        ]
      },

      {
        stepId: StepIds.MOCKUP_CATEGORIES,
        multiSelection: true,
        stepType: StepTypes.STEP_OPTIONS,
        stepNo: 5,
        title: 'Select mockup categories you would like to use for your designs',
        // description: '',
        stepOptions: [
          {
            option: 'Phone',
            value: 'Phone',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/phone-graphic.svg',
            goToUrl: '/mockups/phone',
          },
          {
            option: 'Device',
            value: 'Device',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/device-graphic.svg',
            goToUrl: '/mockups/device',
          },
          {
            option: 'Packaging',
            value: 'Packaging',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/packaging-graphic.svg',
            goToUrl: '/mockups/packaging',
          },
          {
            option: 'Clothing',
            value: 'Clothing',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/clothing-graphic.svg',
            goToUrl: '/mockups',
          },
          {
            option: 'Print',
            value: 'Print',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/print-graphic.svg',
            goToUrl: '/mockups/print',
          },
          {
            option: 'Advertising',
            value: 'Advertising',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/advertising-graphic.svg',
            goToUrl: '/mockups/advertising',
          },
          {
            cssClass: 'Mockup Social Media',
            option: 'Social Media',
            value: 'Social Media',
            imgURL: 'https://static-assets.pixelied.com/onboarding/images/social-media-mockup-graphic.svg',
            goToUrl: '/mockups',
          },
          {
            option: 'Other',
            value: 'Other',
            goToUrl: '/mockups',
            // imgURL: 'assets/img/svg/add-pages.svg'
          }
        ]
      },

      {
        stepId: StepIds.IMAGE_EDITING,
        stepNo: 5,
        title: 'Upload your photo to start editing',
        stepType: StepTypes.UPLOAD,
        uploadType: UploadTypes.IMAGE_EDITING
      },

      {
        stepId: StepIds.BG_REMOVER,
        stepNo: 5,
        title: 'Upload your image to remove background',
        stepType: StepTypes.UPLOAD,
        uploadType: UploadTypes.BG_REMOVER
      },

      {
        stepId: StepIds.SVG_EDITING,
        stepNo: 5,
        title: 'Upload your SVG file to start editing',
        stepType: StepTypes.UPLOAD,
        uploadType: UploadTypes.SVG_EDITING
      },

      {
        stepId: StepIds.BANNER_TEMPLATES_SELECTION,
        stepNo: 5,
        title: 'Select a banner template to get started',
        stepType: StepTypes.TEMPLATE_SELECTION,
        bannerFormats: [
          { title: 'LinkedIn', format: 'linkedin-banner' },
          { title: 'Twitch', format: 'twitch-profile-banner' },
          { title: 'SoundCloud', format: 'soundcloud' },
          { title: 'Etsy', format: 'etsy-banner' },
          { title: 'Medium Rectangle', format: 'medium-rectangle-ad' },
        ],
        templatesList: []
      },
      {
        stepId: StepIds.ECOMMERCE_TEMPLATES_SELECTION,
        stepNo: 5,
        title: 'Select an eCommerce template to get started',
        stepType: StepTypes.TEMPLATE_SELECTION,
        templatesList: []
      },
    ]
  };
  inputField: string = '';
  inputFieldShown: boolean = false;
  imageUploading: boolean = false;
  isSaving: boolean = false;
  templatesLoading: boolean = false;
  nextStepDisabled: boolean = true;
  prevStepDisabled: boolean = true;
  currentStep: number = 1;
  constructor(private _http: HttpClient, private _userService: UserService) {
    this.init();
  }

  private init() {
    this.currentOnboardingStep = this.getStepByStepId(StepIds.CREATE_WORKSPACE);
    this.userOnboardingData = {
      user_id: this._userService.getUserId(),
      user_onboarding: [
        {
          stepId: StepIds.USER_DESCRIPTION,
          values: []
        },
        {
          stepId: StepIds.SOURCE,
          values: []
        },
        {
          stepId: StepIds.USAGE,
          values: []
        },
        {
          stepId: StepIds.SOCIAL_MEDIA_FORMATS,
          values: []
        },
        {
          stepId: StepIds.MOCKUP_CATEGORIES,
          values: []
        },
      ]
    }
  }

  getUserDataFromUserService = () => {
    return this._userService.getUserDetailsFromJWT();
  }

  getStepByStepId = (stepId: string): OnboardingStep => {
    return this.onboardingData.steps.filter(o => o.stepId == stepId)[0];
  }

  getUserOnboardingIndexByStepId = (stepId: string): number => {
    return this.userOnboardingData.user_onboarding.findIndex(o => o.stepId == stepId);
  }

  saveOrUpdateUserOnboardingData(onboardingData: UserOnboarding, extraData: any, onboardingCompleted: boolean, email: string) {
    return this._http.post("/api/onboarding", { onboardingData, extraData, onboardingCompleted, email }).toPromise();
  }

  createOrUpdateWorkspace(workspace: Workspace) {
    return this._http.post(`/api/workspaces/create-or-update`, { workspace });
  }

}