import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, Inject, ErrorHandler, PLATFORM_ID, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { LandingPageLayoutComponent } from './layouts/landing-page-layout/landing-page-layout.component';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
// import * as path from "path";

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { RewriteFrames } from '@sentry/integrations';
import { Router } from '@angular/router';
import { UseCaseLandingPageLayoutComponent } from './layouts/use-case-landing-page-layout/use-case-landing-page-layout.component';
import { HelpersService } from './core/services/helpers.service';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { OnboardingModule } from './modules/onboarding/onboarding.module';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { WorkspacesListNavComponent } from './layouts/dashboard-layout/workspaces-list-nav/workspaces-list-nav.component';
import { MenuNavComponent } from './layouts/dashboard-layout/menu-nav/menu-nav.component';
import { HeaderComponent } from './layouts/dashboard-layout/header/header.component';
import { BottomNavbarComponent } from './layouts/dashboard-layout/bottom-navbar/bottom-navbar.component';

const ignoreErrors = [
    // Avast extension error
    "_avast_submit",
    // HTML5 video
    "The play() request was interrupted by",
    "play() failed because the user",
    "The fetching process for the media resource was aborted by the user agent at the user's request",
    "not allowed by the user agent or the platform in the current context, possibly because the user denied permission",
    // Network errors such as going offline or being blocked by a proxy
    "Failed to fetch",
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // NotAllowedError play method
    "NotAllowedError: The play method is not allowed by the user agent.",
    "Object Not Found Matching Id",
    "Error retrieving icon",
    /**
     * TODO: remove 'renderTopLayer' when loading images first and when error occurs, remove it or maybe place a rect instead of it
     */
    "renderTopLayer"
];

const denyUrls = [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // Firefox extensions
    /^resource:\/\//i,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i
];

Sentry.init({
    ignoreErrors: ignoreErrors,
    denyUrls: denyUrls,
    dsn: "https://114ac0102a85456da1a95c461cfcfaac@o493175.ingest.sentry.io/5561737",
    autoSessionTracking: true,
    release: environment.releaseName,
    environment: environment.production ? 'production' : 'development',
    attachStacktrace: true,
    // debug: !environment.production ? true : false,
    integrations: [
        //     /**
        //      * Comment out Browser tracing integration to send errors in development to sentry
        //      */
        new Integrations.BrowserTracing({
            tracingOrigins: ["https://pixelied.com"],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new RewriteFrames(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.3,
    // beforeSend(event) {
    //     // for (let frame of event.exception.values[0].stacktrace.frames) {
    //     //     if (frame.function.startsWith("Runtime")) {
    //     //         frame.in_app = false;
    //     //     } else {
    //     //         console.log(frame.filename)
    //     //         frame.filename = `ng:///${frame.filename}`;
    //     //     }
    //     // }
    //     return event;
    // }
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor(@Inject(PLATFORM_ID) private platformId, private _helpersService: HelpersService) { }

    handleError(err: any): void {
        console.error(err);
        if (!environment.production) {
            if (isPlatformBrowser(this.platformId)) {
                // alert(JSON.stringify(err, null, 2));
            }
        }
        if (err instanceof HttpErrorResponse) {
            console.log('http err');
            err.error.Message + " (" + err.message + ")";
            err = err.error;
        } else {
            // const chunkFailedMessage = /Loading chunk [\d]+ failed/;
            const chunkFailedMessage = /Loading chunk/; // OR err.name === 'ChunkLoadError'

            if (chunkFailedMessage.test(err.message)) {
                if (isPlatformBrowser(this.platformId)) {
                    window.location.reload();
                }
                // Sentry.captureException(err.originalError || err);
                return;
            }
        }
        Sentry.withScope((scope) => {
            scope.setExtra('fabricObject', this._helpersService.fabricObject || { object: null });
            Sentry.captureException(err?.originalError || err);
        });
        // Sentry.captureException(err.originalError || err);
    }
}

@NgModule({
    declarations: [
        AppComponent,
        MainLayoutComponent,
        LandingPageLayoutComponent,
        UseCaseLandingPageLayoutComponent,
        DashboardLayoutComponent,
        WorkspacesListNavComponent,
        MenuNavComponent,
        HeaderComponent,
        BottomNavbarComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        HttpClientModule,

        // Core & Shared Modules
        CoreModule,
        SharedModule,
        OnboardingModule,

        SocialLoginModule,

        BrowserAnimationsModule
    ],
    providers: [
        // { provide: LocationStrategy, useClass: PathLocationStrategy},
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
            useValue: undefined
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: 'googleTagManagerId',
            useValue: environment.gtmId
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('779262888106-8k17ji8i941cp65gg8pt5vokcjke3d0c.apps.googleusercontent.com')
                    }
                ]
            } as SocialAuthServiceConfig
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
