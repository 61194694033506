<!-- <app-maintenance-alert></app-maintenance-alert> -->
<header class="dashboard-header">
  <nav class="w-100 d-flex align-items-center">
    <a [routerLink]="['/home']">
      <img
        alt="Pixelied Logo Black"
        src="https://static-assets.pixelied.com/logos-v2/px-logo-black.svg"
        width="40"
        height="40"
      />
    </a>

    <div class="left-nav d-flex align-items-center ml-32px">
      <a class="fs-body-sm text-dark-300" href="/templates">Templates</a>
      <a class="fs-body-sm text-dark-300" href="/mockups">Mockups</a>
      <a
        class="fs-body-sm text-dark-300"
        href="https://pixelied.com/convert/"
        target="_blank"
        >Convert</a
      >
    </div>

    <div class="right-nav d-flex align-items-center">
      <button
        *ngIf="
          (_userService.user | async) &&
          !(_workspacesStoreService.workspaceSubscriptionStatus$ | async).active
        "
        (click)="openProCheckoutModal()"
        class="px-btn px-btn-secondary px-btn-md"
      >
        <mat-icon
          svgIcon="bolt"
          class="text-yellow-300 w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-4px">Upgrade Plan</span>
      </button>
      <div
        *ngIf="
          (_userService.user | async) &&
          !(_workspacesStoreService.workspaceSubscriptionStatus$ | async).active
        "
        class="vr-separator ml-12px mr-12px"
      ></div>

      <div
        class="user-settings-dropdown"
        *ngIf="_userService.user | async"
        ngbDropdown
        container="body"
        display="dynamic"
        placement="bottom-right"
      >
        <div
          class="no-caret-dropdown d-flex align-items-center cursor-pointer"
          ngbDropdownToggle
        >
          <div
            class="px-avatar"
            [class.profile-image]="
              (_userService.user | async).profile_thumbnail_url
            "
            [style.background-image]="
              (_userService.user | async).profile_thumbnail_url
                ? 'url(' +
                  (_userService.user | async).profile_thumbnail_url +
                  ')'
                : 'none'
            "
          >
            <span
              *ngIf="!(_userService.user | async).profile_thumbnail_url"
              class="letter"
              >{{ _userService.user | async | firstLetterFromName }}</span
            >
          </div>
          <mat-icon
            svgIcon="down-arrow"
            class="item-icon text-light-600 ml-6px"
          ></mat-icon>
        </div>

        <div ngbDropdownMenu class="px-dropdown-menu">
          <div class="header-area d-flex">
            <div
              class="px-avatar"
              [class.profile-image]="
                (_userService.user | async).profile_thumbnail_url
              "
              [style.background-image]="
                (_userService.user | async).profile_thumbnail_url
                  ? 'url(' +
                    (_userService.user | async).profile_thumbnail_url +
                    ')'
                  : 'none'
              "
            >
              <span
                *ngIf="!(_userService.user | async).profile_thumbnail_url"
                class="letter"
                >{{ _userService.user | async | firstLetterFromName }}</span
              >
            </div>

            <div class="d-flex flex-column ml-12px" style="max-width: 200px">
              <span class="fs-body-sm fw-medium text-dark-300 text-truncate">{{
                _userService.user | async | fullNameOfUser
              }}</span>
              <span class="fs-label-sm text-dark-100 text-truncate">{{
                (_userService.user | async).email
              }}</span>
            </div>
          </div>

          <hr class="divider" />

          <a
            ngbDropdownItem
            [routerLink]="['/account-settings']"
            class="dropdown-item d-flex"
          >
            <mat-icon svgIcon="cog-wheel" class="item-icon"></mat-icon>
            <span class="item-text text-dark-300 fs-body-sm"
              >Account Settings</span
            >
          </a>
          <a
            ngbDropdownItem
            href="https://help.pixelied.com"
            rel="noopener"
            target="_blank"
            class="dropdown-item d-flex"
          >
            <mat-icon svgIcon="help-circle" class="item-icon"></mat-icon>
            <span class="item-text text-dark-300 fs-body-sm">Help</span>
          </a>
          <a
            ngbDropdownItem
            href="https://www.facebook.com/groups/417169315953663/"
            rel="noopener"
            target="_blank"
            class="dropdown-item d-flex"
          >
            <mat-icon svgIcon="help-circle" class="item-icon"></mat-icon>
            <span class="item-text text-dark-300 fs-body-sm"
              >Join Facebook Group</span
            >
          </a>
          <hr class="divider" />
          <a
            href="javascript:void(0)"
            (click)="_userService.logoutUserSession()"
            ngbDropdownItem
            class="dropdown-item d-flex"
          >
            <mat-icon svgIcon="logout" class="item-icon"></mat-icon>
            <span class="item-text text-dark-300 fs-body-sm">Logout</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</header>
