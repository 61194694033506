export type TemplateFormats = {
    formats: {
        title: string;
        visible: boolean;
        parent_type: string;
        bgColor: string;
        sizes: {
            title: string;
            format_category: string;
            seo_url?: string;
            visible: boolean;
            graphic_img?: string;
            icon: string;
            type: {
                parent_type: string;
                group: string;
                platform: string;
            },
            dimensions: {
                doc_height: number;
                doc_width: number;
                units: string;
            }
        }[]
    }[]
}

let formatsArr: TemplateFormats =
{
    "formats": [
        // {
        //     "title": "Default",
        //     "visible": false,
        //     "parent_type": "default",
        //     "sizes": [
        //         {
        //             title: 'Default',
        //             format_category: 'default',
        //             icon: "home-default-format",
        //             type: {
        //                 parent_type: 'Default',
        //                 group: 'Default',
        //                 // platform: 'General',
        //             },
        //             dimensions: {
        //                 doc_height: 1000,
        //                 doc_width: 1000,
        //                 units: 'px'
        //             }
        //         },
        //     ],
        // },
        // {
        //     "title": "Logo",
        //     "visible": false,
        //     "parent_type": "logo",
        //     "sizes": [
        //         {
        //             title: 'Logo',
        //             format_category: 'logo',
        //             icon: "home-logo-format",
        //             type: {
        //                 parent_type: 'Logo',
        //                 group: 'Logo',
        //                 // platform: 'General',
        //             },
        //             dimensions: {
        //                 doc_height: 500,
        //                 doc_width: 500,
        //                 units: 'px'
        //             }
        //         },
        //     ],
        // },
        {
            "title": "Facebook",
            "visible": true,
            "parent_type": "social-media",
            "bgColor": "#D1E4FC",
            "sizes": [
                {
                    title: 'Facebook Post',
                    format_category: 'facebook-post',
                    visible: true,
                    graphic_img: '/assets/img/dashboard/fb-post-cover.png',
                    icon: "home-facebook",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Post',
                        group: 'Facebook',
                        platform: 'Facebook',
                    },
                    dimensions: {
                        doc_height: 788,
                        doc_width: 940,
                        units: 'px'
                    }
                },
                {
                    title: 'Facebook Page Cover',
                    format_category: 'facebook-page-cover',
                    graphic_img: '/assets/img/dashboard/fb-page-cover.png',
                    seo_url: 'facebook-cover-photo',
                    visible: true,
                    icon: "home-facebook",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Page-Cover',
                        group: 'Facebook',
                        platform: 'Facebook',
                    },
                    dimensions: {
                        doc_height: 315,
                        doc_width: 851,
                        units: 'px'
                    }
                }, {
                    title: 'Facebook Profile Cover',
                    format_category: 'facebook-profile-cover',
                    graphic_img: '/assets/img/dashboard/fb-profile-cover.png',
                    visible: true,
                    icon: "home-facebook",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Profile-Cover',
                        group: 'Facebook',
                        platform: 'Facebook',
                    },
                    dimensions: {
                        doc_height: 315,
                        doc_width: 851,
                        units: 'px'
                    }
                },
                {
                    title: 'Facebook Story',
                    format_category: 'facebook-story',
                    graphic_img: '/assets/img/dashboard/fb-story-cover.png',
                    visible: true,
                    icon: "home-facebook",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Facebook-Story',
                        group: 'Facebook',
                        platform: 'Facebook',
                    },
                    dimensions: {
                        doc_height: 1920,
                        doc_width: 1080,
                        units: 'px'
                    }
                }, {
                    title: 'Facebook Ad',
                    format_category: 'facebook-ad',
                    graphic_img: '/assets/img/dashboard/fb-ad-cover.png',
                    visible: true,
                    icon: "home-facebook",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Facebook-Ad',
                        group: 'Facebook',
                        platform: 'Facebook',
                    },
                    dimensions: {
                        doc_height: 628,
                        doc_width: 1200,
                        units: 'px'
                    }
                }, {
                    title: 'Facebook Event Cover',
                    visible: true,
                    format_category: 'facebook-event-cover',
                    graphic_img: '/assets/img/dashboard/fb-event-cover.png',
                    icon: "home-facebook",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Facebook-Event-Cover',
                        group: 'Facebook',
                        platform: 'Facebook',
                    },
                    dimensions: {
                        doc_height: 1080,
                        doc_width: 1920,
                        units: 'px'
                    }
                }, {
                    title: 'Facebook Group Cover',
                    visible: true,
                    format_category: 'facebook-group-cover',
                    graphic_img: '/assets/img/dashboard/fb-group-cover.png',
                    icon: "home-facebook",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Facebook-Group-Cover',
                        group: 'Facebook',
                        platform: 'Facebook',
                    },
                    dimensions: {
                        doc_height: 856,
                        doc_width: 1640,
                        units: 'px'
                    }
                }
            ],
        },
        {
            "title": "Instagram",
            "visible": true,
            "parent_type": "social-media",
            "bgColor": "#D800B9",
            "sizes": [
                {
                    title: 'Instagram Post',
                    format_category: 'instagram-post',
                    visible: true,
                    icon: "home-instagram",
                    graphic_img: '/assets/img/dashboard/instagram-post-cover.png',
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Instagram-Post',
                        group: 'Instagram',
                        platform: 'Instagram',
                    },
                    dimensions: {
                        doc_height: 1080,
                        doc_width: 1080,
                        units: 'px'
                    }
                },
                {
                    title: 'Instagram Story',
                    format_category: 'instagram-story',
                    graphic_img: '/assets/img/dashboard/instagram-story.png',
                    visible: true,
                    icon: "home-instagram",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Instagram-Story',
                        group: 'Instagram',
                        platform: 'Instagram',
                    },
                    dimensions: {
                        doc_height: 1920,
                        doc_width: 1080,
                        units: 'px'
                    }
                }, {
                    title: 'Instagram Ad',
                    format_category: 'instagram-ad',
                    graphic_img: '/assets/img/dashboard/instagram-ad.png',
                    visible: true,
                    icon: "home-instagram",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Instagram-Ad',
                        group: 'Instagram',
                        platform: 'Instagram',
                    },
                    dimensions: {
                        doc_height: 1080,
                        doc_width: 1080,
                        units: 'px'
                    }
                },
            ],
        },
        {
            "title": "Twitter",
            "visible": true,
            "parent_type": "social-media",
            "bgColor": "#CDEEFD",
            "sizes": [
                {
                    title: 'Twitter Post',
                    format_category: 'twitter-post',
                    graphic_img: '/assets/img/dashboard/twitter-post.png',
                    visible: true,
                    icon: "home-twitter",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Twitter-Post',
                        group: 'Twitter',
                        platform: 'Twitter',
                    },
                    dimensions: {
                        doc_height: 900,
                        doc_width: 1600,
                        units: 'px'
                    }
                },
                {
                    title: 'Twitter Header',
                    format_category: 'twitter-header',
                    graphic_img: '/assets/img/dashboard/twitter-header.png',
                    visible: true,
                    icon: "home-twitter",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Twitter-Header',
                        group: 'Twitter',
                        platform: 'Twitter',
                    },
                    dimensions: {
                        doc_height: 500,
                        doc_width: 1500,
                        units: 'px'
                    }
                }, {

                    title: 'Twitter Ad',
                    format_category: 'twitter-ad',
                    graphic_img: '/assets/img/dashboard/twitter-ad.png',
                    visible: false,
                    icon: "home-twitter",
                    type: {
                        parent_type: 'social-media',
                        // child_type: 'Twitter-Ad',
                        group: 'Twitter',
                        platform: 'Twitter',
                    },
                    dimensions: {
                        doc_height: 900,
                        doc_width: 1600,
                        units: 'px'
                    }
                },
            ],
        },
        {
            "title": "LinkedIn",
            "visible": true,
            "parent_type": "social-media",
            "bgColor": "#CEE0F3",
            "sizes": [
                {
                    title: 'LinkedIn Post',
                    format_category: 'linkedin-post',
                    graphic_img: '/assets/img/dashboard/linkedin-post.png',
                    visible: true,
                    icon: "home-linkedin",
                    type: {
                        parent_type: 'social-media',
                        group: 'LinkedIn',
                        platform: 'LinkedIn',
                    },
                    dimensions: {
                        doc_height: 1200,
                        doc_width: 1200,
                        units: 'px'
                    }
                },
                {
                    title: 'LinkedIn Banner',
                    format_category: 'linkedin-banner',
                    graphic_img: '/assets/img/dashboard/linkedin-banner.png',
                    visible: true,
                    icon: "home-linkedin",
                    type: {
                        parent_type: 'social-media',
                        group: 'LinkedIn',
                        platform: 'LinkedIn',
                    },
                    dimensions: {
                        doc_height: 396,
                        doc_width: 1584,
                        units: 'px'
                    }
                },
            ],
        },
        {
            "title": "Pinterest",
            "visible": true,
            "parent_type": "social-media",
            "bgColor": "#F6D5D5",
            "sizes": [
                {
                    title: 'Pinterest Pin',
                    format_category: 'pinterest-pin',
                    graphic_img: '/assets/img/dashboard/pinterest-pin.png',
                    icon: "home-pinterest",
                    visible: true,
                    type: {
                        parent_type: 'social-media',
                        group: 'Pinterest',
                        platform: 'Pinterest',
                    },
                    dimensions: {
                        doc_height: 1500,
                        doc_width: 1000,
                        units: 'px'
                    }
                },
            ],
        },
        {
            "title": "Ecommerce",
            "visible": true,
            "parent_type": "ecommerce",
            "bgColor": "#EAE8FC",
            "sizes": [
                {
                    title: 'Ecommerce Hero Image',
                    format_category: 'ecommerce-hero-image',
                    graphic_img: '/assets/img/dashboard/ecommerce-hero-image.png',
                    visible: true,
                    icon: "home-ecommerce-hero-image",
                    type: {
                        parent_type: 'ecommerce',
                        group: 'Ecommerce',
                        platform: 'Ecommerce',
                    },
                    dimensions: {
                        doc_height: 2000,
                        doc_width: 2000,
                        units: 'px'
                    }
                },
                {
                    title: 'Shopify Image',
                    format_category: 'shopify-image',
                    visible: false,
                    icon: "home-ecommerce-hero-image",
                    type: {
                        parent_type: 'ecommerce',
                        group: 'Ecommerce',
                        platform: 'Shopify',
                    },
                    dimensions: {
                        doc_height: 2048,
                        doc_width: 2048,
                        units: 'px'
                    }
                }
            ]
        },
        {
            "title": "Youtube",
            "visible": true,
            "parent_type": "content-marketing",
            "bgColor": "#FFCCCC",
            "sizes": [
                {
                    title: 'Youtube Channel Art',
                    format_category: 'youtube-channel-art',
                    seo_url: 'youtube-banner',
                    icon: "home-youtube",
                    visible: true,
                    type: {
                        parent_type: 'content-marketing',
                        group: 'Youtube',
                        platform: 'Youtube',
                    },
                    dimensions: {
                        doc_height: 1440,
                        doc_width: 2560,
                        units: 'px'
                    }
                },
                {
                    title: 'Youtube End Screen',
                    format_category: 'youtube-end-screen',
                    seo_url: 'youtube-outro',
                    icon: "home-youtube",
                    visible: true,
                    type: {
                        parent_type: 'content-marketing',
                        group: 'Youtube',
                        platform: 'Youtube',
                    },
                    dimensions: {
                        doc_height: 1080,
                        doc_width: 1920,
                        units: 'px'
                    }
                },
                {
                    title: 'Youtube Thumbnails',
                    format_category: 'youtube-thumbnails',
                    icon: "home-youtube",
                    visible: true,
                    type: {
                        parent_type: 'content-marketing',
                        group: 'Youtube',
                        platform: 'Youtube',
                    },
                    dimensions: {
                        doc_height: 720,
                        doc_width: 1280,
                        units: 'px'
                    }
                },
            ],
        },
        {
            "title": "Twitch",
            "visible": true,
            "parent_type": "online-streaming",
            "bgColor": "#DFD6EE",
            "sizes": [
                {
                    title: 'Twitch Panel',
                    format_category: 'twitch-panel',
                    icon: "home-twitch",
                    visible: true,
                    type: {
                        parent_type: 'online-streaming',
                        group: 'Twitch',
                        platform: 'Twitch',
                    },
                    dimensions: {
                        doc_height: 160,
                        doc_width: 320,
                        units: 'px'
                    }
                },
                {
                    title: 'Twitch Offline Banner',
                    format_category: 'twitch-offline-banner',
                    icon: "home-twitch",
                    visible: true,
                    type: {
                        parent_type: 'online-streaming',
                        group: 'Twitch',
                        platform: 'Twitch',
                    },
                    dimensions: {
                        doc_height: 1080,
                        doc_width: 1920,
                        units: 'px'
                    }
                }, {
                    title: 'Twitch Banner',
                    format_category: 'twitch-profile-banner',
                    seo_url: 'twitch-banner',
                    icon: "home-twitch",
                    visible: true,
                    type: {
                        parent_type: 'online-streaming',
                        group: 'Twitch',
                        platform: 'Twitch',
                    },
                    dimensions: {
                        doc_height: 480,
                        doc_width: 1920,
                        units: 'px'
                    }
                },
            ],
        },
        {
            "title": "Blog",
            "visible": true,
            "parent_type": "content-marketing",
            "bgColor": "#EAE8FC",
            "sizes": [
                {
                    title: 'Blog Featured Image',
                    format_category: 'blog-featured-image',
                    visible: true,
                    icon: "home-blog-featured-image",
                    type: {
                        parent_type: 'content-marketing',

                        group: 'Blog',
                        platform: 'Blog',
                    },
                    dimensions: {
                        doc_height: 600,
                        doc_width: 1200,
                        units: 'px'
                    }
                },
            ],
        },
        {
            "title": "Headers",
            "visible": true,
            "parent_type": "headers",
            "bgColor": "#FFDCBE",
            "sizes": [
                {
                    title: 'Product Hunt',
                    format_category: 'product-hunt',
                    visible: false,
                    icon: "home-product-hunt",
                    type: {
                        parent_type: 'headers',

                        group: 'Headers',
                        platform: 'Product Hunt',
                    },
                    dimensions: {
                        doc_height: 760,
                        doc_width: 1270,
                        units: 'px'
                    }
                },
                {
                    title: 'Soundcloud Banner',
                    format_category: 'soundcloud',
                    graphic_img: '/assets/img/dashboard/soundcloud-banner.png',
                    seo_url: 'soundcloud-banner',
                    visible: true,
                    icon: "home-soundcloud",
                    type: {
                        parent_type: 'headers',

                        group: 'Headers',
                        platform: 'Soundcloud',
                    },
                    dimensions: {
                        doc_height: 520,
                        doc_width: 2480,
                        units: 'px'
                    }
                },
                {
                    title: 'Email Header',
                    format_category: 'email-header',
                    graphic_img: '/assets/img/dashboard/email-header.png',
                    visible: true,
                    icon: "home-email-header",
                    type: {
                        parent_type: 'headers',

                        group: 'Headers',
                        platform: 'Email',
                    },
                    dimensions: {
                        doc_height: 200,
                        doc_width: 600,
                        units: 'px'
                    }
                },
                {
                    title: 'Podcast Cover Art',
                    format_category: 'podcast-cover-art',
                    graphic_img: '/assets/img/dashboard/podcast-cover-art.png',
                    visible: true,
                    icon: "home-podcast-cover-art",
                    type: {
                        parent_type: 'headers',

                        group: 'Headers',
                        platform: 'Podcast',
                    },
                    dimensions: {
                        doc_height: 3000,
                        doc_width: 3000,
                        units: 'px'
                    }
                },
                {
                    title: 'Etsy Banner',
                    format_category: 'etsy-banner',
                    graphic_img: '/assets/img/dashboard/etsy-banner.png',
                    visible: true,
                    icon: "home-etsy-banner",
                    type: {
                        parent_type: 'headers',

                        group: 'Headers',
                        platform: 'Etsy',
                    },
                    dimensions: {
                        doc_height: 840,
                        doc_width: 3360,
                        units: 'px'
                    }
                },
                {
                    title: 'Patreon Banner',
                    format_category: 'patreon-banner',
                    graphic_img: '/assets/img/dashboard/patreon-banner.png',
                    visible: true,
                    icon: "home-patreon-banner",
                    type: {
                        parent_type: 'headers',

                        group: 'Headers',
                        platform: 'Patreon',
                    },
                    dimensions: {
                        doc_height: 400,
                        doc_width: 1600,
                        units: 'px'
                    }
                },
                {
                    title: 'Album Cover',
                    format_category: 'album-cover',
                    visible: false,
                    icon: "home-spotify-playlist-cover",
                    type: {
                        parent_type: 'headers',
                        group: 'Headers',
                        platform: 'Album',
                    },
                    dimensions: {
                        doc_height: 3000,
                        doc_width: 3000,
                        units: 'px'
                    }
                }
            ]
        },
        {
            "title": "Book Covers",
            "visible": true,
            "parent_type": "book-covers",
            "bgColor": "#EAE8FC",
            "sizes": [
                {
                    title: 'Ebook Cover',
                    format_category: 'ebook-cover',
                    visible: true,
                    icon: "home-ebook-cover",
                    type: {
                        parent_type: 'book-covers',
                        group: 'Book Covers',
                        platform: 'Ebook',
                    },
                    dimensions: {
                        doc_height: 2560,
                        doc_width: 1600,
                        units: 'px'
                    }
                },
                {
                    title: 'Kindle Cover',
                    format_category: 'amazon-kindle',
                    seo_url: 'kindle-cover',
                    visible: true,
                    icon: "home-amazon-kindle",
                    type: {
                        parent_type: 'book-covers',
                        group: 'Book Covers',
                        platform: 'Amazon',
                    },
                    dimensions: {
                        doc_height: 2560,
                        doc_width: 1600,
                        units: 'px'
                    }
                },
                {
                    title: 'Wattpad Cover',
                    format_category: 'wattpad-cover',
                    visible: true,
                    icon: "home-wattpad-cover",
                    type: {
                        parent_type: 'book-covers',
                        group: 'Book Covers',
                        platform: 'Wattpad',
                    },
                    dimensions: {
                        doc_height: 800,
                        doc_width: 512,
                        units: 'px'
                    }
                }
            ]
        },
        {
            "title": "Banner Ads",
            "visible": true,
            "parent_type": "banner-ads",
            "bgColor": "#EAE8FC",
            "sizes": [
                {
                    title: 'Medium Rectangle Ad',
                    format_category: 'medium-rectangle-ad',
                    visible: true,
                    icon: "home-ads",
                    type: {
                        parent_type: 'banner-ads',
                        group: 'Banner Ads',
                        platform: 'Ad',
                    },
                    dimensions: {
                        doc_height: 250,
                        doc_width: 300,
                        units: 'px'
                    }
                },
                {
                    title: 'Leaderboard Ad',
                    format_category: 'leaderboard-ad',
                    visible: false,
                    icon: "home-ads",
                    type: {
                        parent_type: 'banner-ads',
                        group: 'Banner Ads',
                        platform: 'Ad',
                    },
                    dimensions: {
                        doc_height: 90,
                        doc_width: 728,
                        units: 'px'
                    }
                },
                {
                    title: 'Wide Skyscraper Ad',
                    format_category: 'wide-skyscraper-ad',
                    visible: true,
                    icon: "home-ads",
                    type: {
                        parent_type: 'banner-ads',
                        group: 'Banner Ads',
                        platform: 'Ad',
                    },
                    dimensions: {
                        doc_height: 600,
                        doc_width: 160,
                        units: 'px'
                    }
                },
                {
                    title: 'Half Page Ad',
                    format_category: 'half-page-ad',
                    visible: false,
                    icon: "home-ads",
                    type: {
                        parent_type: 'banner-ads',
                        group: 'Banner Ads',
                        platform: 'Ad',
                    },
                    dimensions: {
                        doc_height: 600,
                        doc_width: 300,
                        units: 'px'
                    }
                }
            ]
        },
        {
            "title": "Quotes",
            "visible": true,
            "parent_type": "quotes",
            "bgColor": "#EAE8FC",
            "sizes": [
                {
                    title: 'Image Quote',
                    format_category: 'image-quote',
                    visible: true,
                    icon: "home-quotes",
                    type: {
                        parent_type: 'quotes',
                        group: 'Quotes',
                        platform: 'Quotes',
                    },
                    dimensions: {
                        doc_height: 1080,
                        doc_width: 1080,
                        units: 'px'
                    }
                }
            ]
        },
        {
            "title": "Infographics",
            "visible": false,
            "parent_type": "infographics",
            "bgColor": "#D1E4FC",
            "sizes": [
                {
                    title: 'Infographic',
                    format_category: 'infographic',
                    visible: false,
                    icon: "home-infographics",
                    type: {
                        parent_type: 'infographics',
                        group: 'Infographics',
                        platform: 'Infographics',
                    },
                    dimensions: {
                        doc_height: 2000,
                        doc_width: 800,
                        units: 'px'
                    }
                }
            ]
        },
        {
            "title": "Spotify",
            "visible": true,
            "parent_type": "spotify",
            "bgColor": "#D1E4FC",
            "sizes": [
                {
                    title: 'Spotify Playlist Cover',
                    format_category: 'spotify-playlist-cover',
                    visible: false,
                    icon: "home-spotify-playlist-cover",
                    type: {
                        parent_type: 'spotify',
                        group: 'Spotify',
                        platform: 'Spotify',
                    },
                    dimensions: {
                        doc_height: 300,
                        doc_width: 300,
                        units: 'px'
                    }
                },
                {
                    title: 'Spotify Cover Art',
                    format_category: 'spotify-cover-art',
                    visible: true,
                    icon: "home-spotify-playlist-cover",
                    type: {
                        parent_type: 'spotify',
                        group: 'Spotify',
                        platform: 'Spotify',
                    },
                    dimensions: {
                        doc_height: 640,
                        doc_width: 640,
                        units: 'px'
                    }
                },
            ]
        }
    ]
};


export default { formatsArr };