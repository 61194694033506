import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { UserDesign } from 'src/app/modules/editor/data/editor.model';
import { UserFolder } from 'src/app/modules/my-designs/data/folder.model';
import { Constants } from 'src/app/shared/data/constants';

export enum CrudTypes {
    Create,
    Update,
    Delete,
    DeleteMultiple
}

// export enum CrudFeatureModal {
//     Folder,
//     Design,
//     Workspace
// }

// export type CrudOperations = {
//     loading: boolean
//     design?: UserDesign
//     folder?: UserFolder
//     idsSelected?: string[]
//     crudType: CrudTypes
//     crudFeatureModal: CrudFeatureModal
// }

export interface MetaTags {
    // url: string;
    title: string;
    description: string;
    ogTitle: string;
    ogDescription: string;
    ogImage: string;
    ogImageAltText?: string;
    // robots?: string;
}


export class PreloadLink {
    className: string;
    imgUrl: string;
    mediaQueries: string;
}

@Injectable({
    providedIn: 'root'
})
export class HelpersService {

    optinMonsterCampaignIds: string[] = [];
    fabricObject: fabric.Object;
    zendeskChatScriptURL = 'https://static.zdassets.com/ekr/snippet.js?key=54917e1f-d917-4819-a92a-74f773472e71';
    optinMonsterScriptURL = 'https://a.omappapi.com/app/js/api.min.js';
    numericJsScriptURL = 'https://cdnjs.cloudflare.com/ajax/libs/numeric/1.2.6/numeric.min.js';
    constructor(@Inject(DOCUMENT) private dom: Document,
        private titleService: Title,
        private metaService: Meta) { }


    isSVGString(svgString: string) {
        return (svgString.includes('xmlns="http://www.w3.org/2000/svg"') || svgString.includes("xmlns='http://www.w3.org/2000/svg'"));
    }

    toTitleCase = function (text) {
        return text.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    // Detect if library loaded
    isLoadedScript(lib) {
        return this.dom.querySelectorAll('[src="' + lib + '"]').length > 0
    }

    setSentrySelectedFabricObjectDetails(object: fabric.Object) {
        this.fabricObject = object;
    }

    removeSentrySelectedFabricObjectDetails() {
        this.fabricObject = null;
    }

    initZendeskChat() {
        (() => {
            var d = this.dom;
            var s = d.createElement("script");
            s.src = this.zendeskChatScriptURL;
            s.id = 'ze-snippet';
            // (s as any).async = 1;
            (s as any).defer = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
        })();
    }

    addPreloadImageLink(imgUrl: string) {
        const head = this.dom.getElementsByTagName('head')[0];
        var firstLink: HTMLLinkElement = this.dom.querySelector('.hero-preload-link') || null;
        if (firstLink == null) {
            firstLink = this.dom.createElement('link') as HTMLLinkElement;
            firstLink.setAttribute('href', 'https://static-assets.pixelied.com/use-cases/ebook-covers/hero-img.png');
            // element.setAttribute('imagesrcset', `https://static-assets.pixelied.com/use-cases/ebook-covers/hero-img-small.png?ts=123  500w,
            //                                 https://static-assets.pixelied.com/use-cases/ebook-covers/hero-img.png              1200w`);
            // element.setAttribute('imagesizes', '(min-width: 991px) 50vw, 100vw');
            firstLink.setAttribute('rel', 'preload');
            firstLink.setAttribute('as', 'image');
            firstLink.setAttribute('class', 'preload-link');
            head.appendChild(firstLink);
        }
    }

    addPreloadImageLinkWithMediaQueries(list: PreloadLink[]) {
        const head = this.dom.getElementsByTagName('head')[0];
        for (let item of list) {
            let link: HTMLLinkElement = this.dom.querySelector(item.className) || null;
            if (link == null) {
                link = this.dom.createElement('link') as HTMLLinkElement;
                link.setAttribute('rel', 'preload');
                link.setAttribute('href', item.imgUrl);
                link.setAttribute('as', 'image');
                link.setAttribute('media', item.mediaQueries);
                link.setAttribute('class', item.className);
                head.appendChild(link);
            }
        }
        // var firstLink: HTMLLinkElement = this.dom.querySelector('.hero-preload-link') || null;
        // var secondLink: HTMLLinkElement = this.dom.querySelector('.small-hero-preload-link') || null;
        // if (firstLink == null) {
        //     firstLink = this.dom.createElement('link') as HTMLLinkElement;
        //     firstLink.setAttribute('href', 'https://static-assets.pixelied.com/use-cases/ebook-covers/hero-img.png');
        //     // element.setAttribute('imagesrcset', `https://static-assets.pixelied.com/use-cases/ebook-covers/hero-img-small.png?ts=123  500w,
        //     //                                 https://static-assets.pixelied.com/use-cases/ebook-covers/hero-img.png              1200w`);
        //     // element.setAttribute('imagesizes', '(min-width: 991px) 50vw, 100vw');
        //     firstLink.setAttribute('rel', 'preload');
        //     firstLink.setAttribute('as', 'image');
        //     firstLink.setAttribute('class', 'preload-link');
        //     head.appendChild(firstLink);

        //     // <link rel="preload" href = "small_cat.jpg" as="image" media = "(max-width: 400px)" >
        //     //     <link rel="preload" href = "medium_cat.jpg" as="image" media = "(min-width: 400.1px) and (max-width: 800px)" >
        //     //         <link rel="preload" href = "large_cat.jpg" as="image" media = "(min-width: 800.1px)" >
        // }
    }

    setCanonicalUrls(data) {
        if (typeof data.canonical !== 'undefined') {
            this.updateCanonicalUrl(data.canonical);
            this.metaService.updateTag({ property: 'og:url', content: data.canonical });
            this.metaService.updateTag({ property: 'twitter:url', content: data.canonical || Constants.PIXELIED_HOST_NAME });
        } else {
            try {
                var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
                if (element != null) {
                    element.remove();
                }
                this.metaService.removeTag('og:url');
                this.metaService.removeTag('twitter:url');
            } catch (e) { }
        }

        if (data.alternateLinks) this.setAlternateLinks(data.alternateLinks);
        if (data.htmlLang) this.setHtmlLang(data.htmlLang);
    }

    setHtmlLang(htmlLang) {
        const html = this.dom.getElementsByTagName('html')[0];
        html.setAttribute("lang", htmlLang);
    }

    setAlternateLinks(alternateLinks) {
        const head = this.dom.getElementsByTagName('head')[0];
        for (let link of alternateLinks) {
            let element = this.dom.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
            element.setAttribute('rel', 'alternate');
            element.setAttribute('href', link.href);
            element.setAttribute('hreflang', link.hrefLang);
        }
    }

    updateCanonicalUrl(url: string) {
        const head = this.dom.getElementsByTagName('head')[0];
        var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
        if (element == null) {
            element = this.dom.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', url);
    }

    removeCanonicalLink() {
        let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
        if (element) element.remove();
    }

    addNumericJsScript() {
        const head = this.dom.getElementsByTagName('head')[0];
        var element: HTMLScriptElement = this.dom.querySelector(`.numeric-js-script`) || null;
        if (element == null) {
            element = this.dom.createElement('script') as HTMLScriptElement;
            element.setAttribute('class', 'numeric-js-script');
            element.setAttribute('integrity', 'sha512-BK/c8SKG4PMGMg8wo/56FdGMkgQFJgFl8rnVdF1wqIZW73Vw6M/Tde9GmwibV8MoBSBJcwurXGg6B9yvd/Fvcw==');
            element.setAttribute('crossorigin', 'anonymous');
            element.setAttribute('referrerpolicy', 'no-referrer');
            element.setAttribute('src', this.numericJsScriptURL);
            head.appendChild(element);
        }
    }

    initOptinMonster() {
        // this.dom.querySelector('html').style.paddingBottom = '0px';
        const head = this.dom.getElementsByTagName('head')[0];
        var element: HTMLScriptElement = this.dom.querySelector(`.optin-monster-script`) || null;
        if (element == null) {
            element = this.dom.createElement('script') as HTMLScriptElement;
            element.setAttribute('class', 'optin-monster-script');
            element.setAttribute('data-account', '98950');
            element.setAttribute('data-user', '88358');
            // element.setAttribute('async', '1');
            element.setAttribute('defer', '1');
            element.setAttribute('type', 'text/javascript');
            element.setAttribute('src', this.optinMonsterScriptURL);
            head.appendChild(element);
        }
    }

    addOptinMonsterClass() {
        let optinMonsterEl: HTMLElement = this.dom.querySelector(`.bayhill-campaign`) || null;
        if (optinMonsterEl != null) {
            optinMonsterEl.setAttribute('class', 'd-block');
        }
    }

    removeGoogleAdSection() {
        let googleAds: NodeListOf<Element> = this.dom.querySelectorAll('.adsbygoogle') || null;
        let body: HTMLElement = this.dom.body;
        console.log(googleAds);
        console.log(googleAds.length);
        if (googleAds) googleAds.forEach(ad => ad.remove());
        // if (googleAds) googleAds.remove();
        console.log(body);
        // console.log(body?.style?.padding);
        if (body?.style?.padding) body.style.padding = null;
    }

    removeOptinMonsterClass() {
        // let optinMonsterHolderEl: HTMLElement = this.dom.querySelector(`#om-zod4iwx3nydiamjji6p2-holder`) || null;
        let optinMonsterEl: HTMLElement = this.dom.querySelector(`.Campaign.CampaignType--floating`) || null;
        let optinMonsterScript: HTMLScriptElement = this.dom.querySelector(`.optin-monster-script`) || null;
        if (optinMonsterEl != null) {
            optinMonsterEl.setAttribute('class', 'd-none');
            optinMonsterEl.remove();
            optinMonsterScript.remove();
            // optinMonsterHolderEl.remove();
            this.dom.querySelector('html').style.paddingBottom = '0px';
            this.dom.querySelector('html').style.paddingTop = '0px';
        }

        for (let id of this.optinMonsterCampaignIds) {
            const optinCampaign = this.dom.querySelector(id) || null;
            if (optinCampaign) optinCampaign.parentNode.removeChild(optinCampaign);
        }
    }

    initOptinMonsterEventListener() {
        this.dom.addEventListener('om.Campaign.startShow', (event: any) => {
            console.log('op-ev');
            // console.log(event);
            this.optinMonsterCampaignIds.push('#om-' + event.detail.Campaign.id);
            // if (window.location.href.includes('/editor') {
            //     const optinCampaign = this.dom.querySelector('#om-' + event.detail.Campaign.id) || null;
            //     if (optinCampaign) optinCampaign.parentNode.removeChild(optinCampaign);
            // }
        });
    }

    addVimeoIframe(videoSrc: string, iframeWrapper: string, videoIframe: string, type: string = 'testimonial') {
        // console.log(iframeWrapper + ': found 1')
        let vimeoIframeWrapperEl: HTMLScriptElement = this.dom.querySelector(`.${iframeWrapper}`) || null;
        let iframeEl: HTMLIFrameElement = this.dom.querySelector(`.${videoIframe}`) || null;
        if (iframeEl == null) {
            if (vimeoIframeWrapperEl !== null) {
                // console.log(iframeWrapper + ': found 2')
                iframeEl = this.dom.createElement('iframe') as HTMLIFrameElement;
                iframeEl.setAttribute('title', `Pixelied Video`);
                iframeEl.setAttribute('class', `${videoIframe} vimeo_embed`);
                iframeEl.setAttribute('src', videoSrc);
                iframeEl.setAttribute('frameborder', '0');
                iframeEl.setAttribute('allow', 'autoplay; fullscreen');
                iframeEl.setAttribute('allowfullscreen', null);
                iframeEl.setAttribute('msallowfullscreen', null);
                iframeEl.setAttribute('width', '100%');
                iframeEl.setAttribute('height', '100%');
                iframeEl.style.position = 'absolute';
                iframeEl.style.top = '0';
                iframeEl.style.left = '0';
                if (videoIframe === 'hero_vimeo_px_iframe') {
                    iframeEl.style.borderRadius = '6px';
                    iframeEl.style.border = 'none';
                    iframeEl.style.boxShadow = '-2px -2px 20px rgba(0,0,0,.1), 20px 20px 30px -2px rgba(0,0,0,.2)';
                    iframeEl.style.backgroundColor = 'rgba(0,0,0,0.05)';
                } else {
                    // vimeoIframeWrapperEl.style.padding = '60% 0 0 0';
                    // vimeoIframeWrapperEl.style.position = 'relative';
                }

                if (type === 'testimonial') {
                    iframeEl.style.borderRadius = '2px';
                    iframeEl.style.border = 'none';
                    iframeEl.style.boxShadow = '-15px 0px 15px 0 rgb(0, 0, 0, 0.2)';
                    // iframeEl.style.backgroundColor = 'rgba(0,0,0,0.05)';
                }
                vimeoIframeWrapperEl.appendChild(iframeEl);
            }
        }
    }

    addWistiaIframe() {
        // const head = this.dom.getElementsByTagName('head')[0];
        let wistiaIframeWrapperEl: HTMLScriptElement = this.dom.querySelector(`.wistia_px_iframe_wrapper`) || null;
        let iframeEl: HTMLIFrameElement = this.dom.querySelector(`.wistia_px_iframe`) || null;
        if (iframeEl == null) {
            if (wistiaIframeWrapperEl !== null) {
                iframeEl = this.dom.createElement('iframe') as HTMLIFrameElement;
                iframeEl.setAttribute('class', 'wistia_px_iframe wistia_embed');
                // element.appendChild(iframeEl);

                iframeEl.setAttribute('src', 'https://player.vimeo.com/video/712029492?h=a827243889&badge=0&autopause=0&player_id=0&app_id=58479');
                // iframeEl.setAttribute('src', 'https://fast.wistia.net/embed/iframe/gdne9oayom?videoFoam=true');
                iframeEl.title = 'Pixelied Testimonials Motion Graphics Video';
                iframeEl.setAttribute('allow', 'autoplay; fullscreen');
                iframeEl.setAttribute('allowtransparency', 'true');
                iframeEl.setAttribute('frameborder', '0');
                iframeEl.setAttribute('scrolling', 'no');
                // iframeEl.setAttribute('class','wistia_embed');
                iframeEl.setAttribute('name', 'wistia_embed');
                iframeEl.setAttribute('allowfullscreen', null);
                iframeEl.setAttribute('msallowfullscreen', null);
                iframeEl.setAttribute('width', '100%');
                iframeEl.setAttribute('height', '100%');
                wistiaIframeWrapperEl.appendChild(iframeEl);
            }
        }
    }

    /**
     * TODO: used in editor and preview components only, change it to fn setSocialMediaMetaTags()
     * @param title
     * @param description
     */
    setTitleAndDescription(title, description) {
        this.titleService.setTitle(`${title} - Pixelied`);
        this.metaService.updateTag({ name: 'description', content: description || Constants.PIXELIED_DESCRIPTION });
        // this.metaService.updateTag({ property: 'og:title', content: `${title} - Pixelied` });
        // this.metaService.updateTag({ property: 'og:description', content: description || Constants.OG_PIXELIED_DESCRIPTION });
    }

    setSocialMediaMetaTags(data: MetaTags) {
        // console.log(data)
        this.metaService.updateTag({ name: 'description', content: data.description || Constants.PIXELIED_DESCRIPTION });

        this.titleService.setTitle(data.title || Constants.PIXELIED_TITLE);
        this.metaService.updateTag({ property: 'og:title', content: data.ogTitle || Constants.OG_PIXELIED_TITLE });
        this.metaService.updateTag({ property: 'twitter:title', content: data.ogTitle || Constants.OG_PIXELIED_TITLE });
        this.metaService.updateTag({ property: 'og:description', content: data.ogDescription || Constants.OG_PIXELIED_DESCRIPTION });
        this.metaService.updateTag({ property: 'twitter:description', content: data.ogDescription || Constants.OG_PIXELIED_DESCRIPTION });

        // OG:IMAGE TAG
        this.metaService.updateTag({ property: 'og:image', content: data.ogImage || Constants.OG_IMAGE });
        this.metaService.updateTag({ property: 'twitter:image', content: data.ogImage || Constants.OG_IMAGE });

        if (data.ogImageAltText) this.metaService.updateTag({ property: 'og:image:alt', content: data.ogImageAltText });
        if (data.ogImageAltText) this.metaService.updateTag({ property: 'twitter:image:alt', content: data.ogImageAltText });

        // if (data.robots) this.metaService.updateTag({ property: 'robots', content: data.robots });
    }

    setOgImage(ogImage: string) {
        this.metaService.updateTag({ property: 'og:image', content: ogImage || Constants.OG_IMAGE });
        this.metaService.updateTag({ property: 'twitter:image', content: ogImage || Constants.OG_IMAGE });
    }

    setRobotsContent(robotsContent: string) {
        if (robotsContent) this.metaService.updateTag({ property: 'robots', content: robotsContent });
    }

    extractNameFromURL(text: string, isTypeURL: boolean = false): string {
        if (!text) return text;
        if (isTypeURL) return text.split('/').pop().replace(/\w\S*/g, function (txt) { return txt.toLowerCase() });
        if (text == 'source-over') return 'Normal';
        if (text == 'ipad') return 'iPad';
        if (text == 'iphone') return 'iPhone';
        if (text == 'a-frame') return 'A-Frame';
        if (text == 'nyc') return 'NYC';
        if (text == 'cd') return 'CD';
        if (text == 't-shirt') return 'T-Shirt';
        if (text == 'front-and-back-t-shirt') return 'Front And Back T-Shirt';
        if (text == 'gray-t-shirt') return 'Gray T-Shirt';
        if (text == 'black-t-shirt') return 'Black T-Shirt';
        if (text == 'white-t-shirt') return 'White T-Shirt';
        if (text == 'blank-t-shirt') return 'Blank T-Shirt';
        if (text == 'pocket-t-shirt') return 'Pocket T-Shirt';
        if (text == 'vintage-t-shirt') return 'Vintage T-Shirt';
        return text.split('/').pop().replace(/-/g, ' ').replace(/\w\S*/g, function (txt) {
            if (['png', 'jpg', 'jpeg', 'svg', 'tv'].indexOf(txt) !== -1) return txt.toUpperCase();
            if (txt == 'ipad') return 'iPad';
            if (txt == 'iphone') return 'iPhone';
            if (txt == 'xr') return 'XR';
            if (txt == 'imac') return 'iMac';
            if (txt == 'nyc') return 'NYC';
            if (txt == 'ebook') return 'eBook';
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
        });
    }

    ifFileIsOfImageType(file: File): boolean {
        if (file) {
            let fileExtension = file.type;
            if (['image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'].indexOf(fileExtension) !== -1) return true;
            return false;
        }
        return false;
    }

    checkFileSize(file: File): boolean {
        if (file.size / 1000 / 1000 > Constants.MAX_IMAGE_FILE_UPLOAD) {
            return false;
        }
        return true;
    }

    copyDataToClipboard = (data: any) => {
        return new Promise((resolve, reject) => {
            navigator.clipboard.writeText(data)
                .then((data) => { resolve(data); })
                .catch((error) => { reject(error) });
        })
    }

    // getDataFromClipboard = () => {
    //     return new Promise((resolve, reject) => {
    //         navigator.clipboard.readText()
    //             .then((data) => { resolve(data); })
    //             .catch((error) => { reject(error) });
    //     })
    // }
}