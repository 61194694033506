import { Injectable } from '@angular/core';
import data from '../mocks/templates.mock';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormatCategory, TemplateDesign, TemplatesByCategory } from 'src/app/modules/editor/data/editor.model';
import { GoogleAnalyticsEventsService } from './google-analytics-events.service';

@Injectable({
    providedIn: 'root'
})
export class TemplatesService {

    API_URL = environment.apiUrl;
    selectedFormatCategoryObj: FormatCategory; // selectedFormatCategoryObj's scope is limited to search-templates-sidebar

    templatesByCategories: TemplatesByCategory[] = [];
    indexView: BehaviorSubject<boolean> = new BehaviorSubject(true); // if (indexView), then show mockups by categories, otherwise mockups list view
    // selectedColorFilter: string = '';
    // selectedPositionFilter: string = '';
    searchQuery: string = '';
    // filters: string[] = [];
    templates: TemplateDesign[] = [];
    isLoading: boolean = false;
    noDataToLoad: boolean = false;
    pageNum: number = 1;
    pageSize: number = 30;
    constructor(
        private _http: HttpClient,
        private _gaEventsService: GoogleAnalyticsEventsService
    ) { }

    setIndexView(value: boolean) {
        this.indexView.next(value);
    }

    getIndexView() {
        return this.indexView.getValue();
    }

    clearFilters(callApiForTemplatesList: boolean = false) {
        this.searchQuery = "";
        // this.filters = [];
        // this.selectedColorFilter = '';
        // this.selectedPositionFilter = '';
        this.resetValues();
        if (callApiForTemplatesList) this.getTemplatesByTagsAndCategory();
    }

    private resetValues() {
        this.templates = [];
        this.pageNum = 1;
        this.noDataToLoad = false;
    }

    public getAllFormats(): any {
        let formatsList = [];
        data.formatsArr.formats.forEach((format) => {
            let tempFormat = format;
            let sizes = [];
            for (var i = 0; i < tempFormat.sizes.length; i++) {
                let size = tempFormat.sizes[i];
                if (size.visible) {
                    sizes.push(size);
                    // format.sizes.splice(format.sizes.indexOf(size), 1);
                }
            }
            tempFormat.sizes = sizes;
            if (tempFormat.visible) {
                formatsList.push(tempFormat);
            }
        });
        return formatsList;
    }

    public getVisibleFormatsByParentType(parentType: string): any {
        let formatsList = data.formatsArr.formats.reduce((acc, obj) => {
            let sizes = obj.sizes.filter(size => size.visible);
            acc.push({ ...obj, sizes: sizes });
            return acc;
        }, []).filter((format) => {
            return format.parent_type == parentType && format.visible;
        });

        return formatsList;
    }

    public getAllVisibleFormatsList(): any {
        let formatsList = [];
        data.formatsArr.formats.forEach((format) => {
            format.sizes.forEach((size) => {
                if (size.visible) {
                    formatsList.push(size);
                    // format.sizes.splice(format.sizes.indexOf(size), 1);
                }
                // formatsList.push(size);
            })
        });
        return formatsList;
    }

    public getTemplatesBySearchQuery() {
        if (this.searchQuery.trim() == "") return;
        this.getTemplatesByTags();
        this._gaEventsService.eventEmitter('Editor Templates Sidebar Search', 'Search Keyword', `${this.searchQuery}`);
    }

    public getTemplatesByTags() {
        // this.setIndexView(false);
        this.resetValues();
        this.getTemplatesByTagsAndCategory();
        // this.getMockups("", [...this.filters, this.searchQuery].join(' '), this.keywordTagOrQuery);
        // this._gaEventsService.eventEmitter('Editor Mockups Sidebar Search', 'Search Keyword', `${this.searchQuery}`);
    }

    public seeAllTemplatesByFormatCategory(format_category: string) {
        this.setIndexView(false);
        this.selectedFormatCategoryObj = this.getFormatByformatId(format_category);
        // this._templatesService.searchQuery = format_category.toLowerCase();
        this.getTemplatesByTagsAndCategory();
        this._gaEventsService.eventEmitter('Editor Templates Sidebar See All', 'See All', `${format_category}`);
    }

    public getTemplatesByTagsAndCategory() {
        this.isLoading = true;
        this.getTemplatesByTagsAndCategoryAPI(this.pageNum, this.pageSize, this.searchQuery, 'All', this.selectedFormatCategoryObj.format_category, 'yes').subscribe((data: TemplateDesign[]) => {
            if (data.length == 0) {
                this.noDataToLoad = true;
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.templates = [...this.templates, ...data];
        }, (error) => {
            this.isLoading = false;
            throw error;
        });
    }

    public getTemplatesByPopularCategories() {
        return this._http.get(`/api/templates/popular-templates-by-categories`);
    }

    public getTemplatesByTagsAndCategoryAPI(pageNum, pageSize, tagsArray, isProTemplate, category, fromEditorPage: string): Observable<any> {
        if (tagsArray == null) {
            return this._http.get(`${this.API_URL}/api/templates?pageNum=${pageNum}&pageSize=${pageSize}&format-category=${category}&isProTemplate=${isProTemplate}&fromEditorPage=${fromEditorPage}`);
        }
        return this._http.get(`${this.API_URL}/api/templates?pageNum=${pageNum}&pageSize=${pageSize}&format-category=${category}&tags=${encodeURIComponent(tagsArray)}&isProTemplate=${isProTemplate}&fromEditorPage=${fromEditorPage}`);
    }

    public getTemplatesByTagsAndCategoryFromTemplatesPage(pageNum, pageSize, tagsArray, isProTemplate, category, fromEditorPage: string): Observable<any> {
        if (tagsArray == null) {
            return this._http.get(`${this.API_URL}/api/templates/search?pageNum=${pageNum}&pageSize=${pageSize}&format-category=${category}&isProTemplate=${isProTemplate}&fromEditorPage=${fromEditorPage}`);
        }
        return this._http.get(`${this.API_URL}/api/templates/search?pageNum=${pageNum}&pageSize=${pageSize}&format-category=${category}&tags=${encodeURIComponent(tagsArray)}&isProTemplate=${isProTemplate}&fromEditorPage=${fromEditorPage}`);
    }

    public getTemplateByTemplateId(id): Observable<any> {
        return this._http.get(`/api/templates/${id}`);
    }

    public getFormatByFormatCategory(category): Observable<any> {
        let formatObj: any;
        for (var i = 0; i < data.formatsArr.formats.length; i++) {
            // console.log(data.formatsArr.formats[i].sizes)
            for (var j = 0; j < data.formatsArr.formats[i].sizes.length; j++) {
                let size = data.formatsArr.formats[i].sizes[j];
                if (size.format_category == category) {
                    formatObj = size;
                }
            }
        }
        return of(formatObj);
    }

    public getFormatByformatId(id): FormatCategory {
        let formatObj: any;
        data.formatsArr.formats.forEach((format) => {
            format.sizes.forEach((size) => {
                if (size.format_category == id) {
                    formatObj = size;
                }
            })
        })
        return formatObj;
        // return of(formatObj);
    }

    public getAllFormatsList(): any {
        let formatsList = [];
        data.formatsArr.formats.forEach((format) => {
            format.sizes.forEach((size) => {
                formatsList.push(size);
            })
        });
        return formatsList;
    }

    public addDesignerTemplate(templateDesign, thumbnailImageData) {
        return this._http.post('/api/templates', { templateDesign, thumbnailImageData });
    }

    public addDesignerTextTemplate(templateDesign, thumbnailImageData) {
        return this._http.post('/api/text-templates', { templateDesign, thumbnailImageData });
    }

    public updateDesignerTemplate(template_id, templateDesign, thumbnailImageData): Observable<any> {
        return this._http.put(`/api/templates/${template_id}`, { templateDesign, thumbnailImageData }, { headers: { 'Content-Type': 'application/json' } });
    }

    public updateDesignerTextTemplate(template_id, templateDesign, thumbnailImageData): Observable<any> {
        return this._http.put(`/api/text-templates/${template_id}`, { templateDesign, thumbnailImageData }, { headers: { 'Content-Type': 'application/json' } });
    }

    public deleteDesignerTemplate(template_id): Observable<any> {
        return this._http.delete(`/api/templates/${template_id}`);
    }

    public getUserDesigns(user_id, pageNum, pageSize): Observable<any> {
        return this._http.get(`/api/designs/users/${user_id}/designs?pageNum=${pageNum}&pageSize=${pageSize}`);
    }

    public duplicateUserDesign(designId) {
        return this._http.get(`/api/designs/duplicate/${designId}`);
    }

    public deleteUserDesign(design_id): Observable<any> {
        return this._http.delete(`/api/designs/${design_id}`);
    }

    public dashboardTest(): Observable<any> {
        return this._http.get(`/api/dashboard`);
    }

    private toTitleCase = function (text) {
        return text.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

}
