<div class="dashboard-layout">
  <div class="header">
    <app-header></app-header>
  </div>
  <div class="workspaces-list-nav">
    <app-workspaces-list-nav></app-workspaces-list-nav>
  </div>
  <div class="menu-nav">
    <app-menu-nav></app-menu-nav>
  </div>
  <router-outlet></router-outlet>
  <app-bottom-navbar></app-bottom-navbar>
</div>
