<div class="site-wrapper overflow-hidden">
  <div class="terms-page">
    <main class="terms-page-main pt--30 pb--30 pt-md--110 pb-md--110">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-xl-8">
            <div class="omga-terms-contents">
              <h1 class="title-main text-center mb--30 mb-lg--55">
                Privacy Policy
              </h1>
              <p>
                At Pixelied, accessible from
                <a href="https://pixelied.com">https://pixelied.com</a>, one of
                our main priorities is the privacy of our visitors. This Privacy
                Policy document contains types of information that is collected
                and recorded by Pixelied and how we use it.
              </p>
              <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>
              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in Pixelied. This
                policy is not applicable to any information collected offline or
                via channels other than this website.
              </p>
              <p>Our Privacy Policy was last updated on: 24th Januray, 2023</p>
              <!-- <div class="article-block mt--40 mt-md--50">
                                <h2 class="block-title mb--30">Cookies</h2>
                                <p>We employ the use of cookies. By accessing Pixelied, you agreed to use cookies in agreement with the Pixelied's Privacy Policy.</p>
                                <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                            </div> -->
              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">Consent</h2>
                <p>
                  By using our website, you hereby consent to our Privacy Policy
                  and agree to its terms.
                </p>
              </div>
              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">Information we collect</h2>
                <p>
                  The personal information that you are asked to provide, and
                  the reasons why you are asked to provide it, will be made
                  clear to you at the point we ask you to provide your personal
                  information.
                </p>
                <p>
                  If you contact us directly, we may receive additional
                  information about you such as your name, email address, phone
                  number, the contents of the message and/or attachments you may
                  send us, and any other information you may choose to provide.
                </p>
                <p>
                  When you register for an account, we may ask for your contact
                  information such as name and email address.
                </p>
              </div>
              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">How we use your information</h2>
                <p>
                  We use the information we collect in various ways, including
                  to:
                </p>
                <ul class="term-list">
                  <li>Provide, operate, and maintain our webste</li>
                  <li>Improve, personalize, and expand our webste</li>
                  <li>Understand and analyze how you use our webste</li>
                  <li>
                    Develop new products, services, features, and functionality
                  </li>
                  <li>
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the webste,
                    and for marketing and promotional purposes
                  </li>
                  <li>Send you emails</li>
                  <li>Find and prevent fraud</li>
                </ul>
              </div>

              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">
                  Information from Third-Party Social Media Services
                </h2>
                <p>
                  You can create an account and log in to use the service
                  through the following Third-party Social Media Services:
                </p>
                <ul class="term-list">
                  <li>Google</li>
                  <li>Facebook</li>
                </ul>
                <p>
                  If you decide to register through or otherwise grant us access
                  to a Third-Party Social Media Service, We may collect Personal
                  data that is already associated with Your Third-Party Social
                  Media Service's account, such as your name, your email
                  address, your activities or your contact list associated with
                  that account.
                </p>
                <p>
                  You may also have the option of sharing additional information
                  with the Company through Your Third-Party Social Media
                  Service's account. If You choose to provide such information
                  and Personal Data, during registration or otherwise, You are
                  giving the Company permission to use, share, and store it in a
                  manner consistent with this Privacy Policy.
                </p>
              </div>

              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">Log Files</h2>
                <p>
                  Pixelied follows a standard procedure of using log files.
                  These files log visitors when they visit websites. All hosting
                  companies do this and a part of hosting services' analytics.
                  The information collected by log files include internet
                  protocol (IP) addresses, browser type, Internet Service
                  Provider (ISP), date and time stamp, referring/exit pages, and
                  possibly the number of clicks. These are not linked to any
                  information that is personally identifiable. The purpose of
                  the information is for analyzing trends, administering the
                  site, tracking users' movement on the website, and gathering
                  demographic information.
                </p>
              </div>
              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">
                  Advertising Partners Privacy Policies
                </h2>
                <p>
                  You may consult this list to find the Privacy Policy for each
                  of the advertising partners of Pixelied.
                </p>
                <p>
                  Third-party ad servers or ad networks uses technologies like
                  cookies, JavaScript, or Web Beacons that are used in their
                  respective advertisements and links that appear on Pixelied,
                  which are sent directly to users' browser. They automatically
                  receive your IP address when this occurs. These technologies
                  are used to measure the effectiveness of their advertising
                  campaigns and/or to personalize the advertising content that
                  you see on websites that you visit.
                </p>
                <p>
                  Note that Pixelied has no access to or control over these
                  cookies that are used by third-party advertisers.
                </p>
              </div>
              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">Third Party Privacy Policies</h2>
                <p>
                  Pixelied's Privacy Policy does not apply to other advertisers
                  or websites. Thus, we are advising you to consult the
                  respective Privacy Policies of these third-party ad servers
                  for more detailed information. It may include their practices
                  and instructions about how to opt-out of certain options.
                </p>
                <p>
                  You can choose to disable cookies through your individual
                  browser options. To know more detailed information about
                  cookie management with specific web browsers, it can be found
                  at the browsers' respective websites.
                </p>
              </div>

              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">Delete Your Personal Data</h2>
                <p>
                  You have the right to delete or request that we assist in
                  deleting the Personal Data that we have collected about you.
                </p>
                <p>
                  Our Service may give you the ability to delete certain
                  information about you from within the service.
                </p>
                <p>
                  You may update, amend, or delete your information at any time
                  by signing in to your account, if you have one, and visiting
                  the account settings section that allows you to manage your
                  personal information. you may also contact us to request
                  access to, correct, or delete any personal information that
                  you have provided to us.
                </p>
                <p>
                  Please note, however, that We may need to retain certain
                  information when we have a legal obligation or lawful basis to
                  do so.
                </p>
              </div>

              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">
                  CCPA Privacy Rights (Do Not Sell My Personal Information)
                </h2>
                <p>
                  Under the CCPA, among other rights, California consumers have
                  the right to:
                </p>
                <p>
                  Request that a business that collects a consumer's personal
                  data disclose the categories and specific pieces of personal
                  data that a business has collected about consumers.
                </p>
                <p>
                  Request that a business delete any personal data about the
                  consumer that a business has collected.
                </p>
                <p>
                  Request that a business that sells a consumer's personal data,
                  not sell the consumer's personal data.
                </p>
                <p>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </p>
              </div>
              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">GDPR Data Protection Rights</h2>
                <p>
                  We would like to make sure you are fully aware of all of your
                  data protection rights. Every user is entitled to the
                  following:
                </p>
                <p>
                  The right to access – You have the right to request copies of
                  your personal data. We may charge you a small fee for this
                  service.
                </p>
                <p>
                  The right to rectification – You have the right to request
                  that we correct any information you believe is inaccurate. You
                  also have the right to request that we complete the
                  information you believe is incomplete.
                </p>
                <p>
                  The right to erasure – You have the right to request that we
                  erase your personal data, under certain conditions.
                </p>
                <p>
                  The right to restrict processing – You have the right to
                  request that we restrict the processing of your personal data,
                  under certain conditions.
                </p>
                <p>
                  The right to object to processing – You have the right to
                  object to our processing of your personal data, under certain
                  conditions.
                </p>
                <p>
                  The right to data portability – You have the right to request
                  that we transfer the data that we have collected to another
                  organization, or directly to you, under certain conditions.
                </p>
                <p>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </p>
              </div>
              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">Children's Information</h2>
                <p>
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians
                  to observe, participate in, and/or monitor and guide their
                  online activity.
                </p>
                <p>
                  Pixelied does not knowingly collect any Personal Identifiable
                  Information from children under the age of 13. If you think
                  that your child provided this kind of information on our
                  website, we strongly encourage you to contact us immediately
                  and we will do our best efforts to promptly remove such
                  information from our records.
                </p>
              </div>

              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">Contact Us</h2>
                <p>
                  If you have any questions about this Privacy Policy, You can
                  contact us:
                </p>
                <p>
                  By email:
                  <a href="mailto:support@pixelied.com">support@pixelied.com</a>
                </p>
              </div>

              <div class="article-block mt--40 mt-md--50">
                <h2 class="block-title mb--30">Affiliation</h2>
                <p>
                  This Site is affiliated with Monumetric (dba for The Blogger
                  Network, LLC) for the purposes of placing advertising on the
                  Site, and Monumetric will collect and use certain data for
                  advertising purposes. To learn more about Monumetric's data
                  usage, click here:
                  <a
                    href="http://www.monumetric.com/publisher-advertising-privacy"
                    >Publisher Advertising Privacy</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
