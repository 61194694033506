import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from 'src/app/modules/auth/data/user.service';

@Injectable({
    providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

    constructor(private _userService: UserService, private _router: Router) { }

    canActivate(): boolean {
        if (this._userService.loggedIn()) {
            if (this._userService.getUserRole() == 'admin_designer' || this._userService.getUserRole() == 'va') {
                return true;
            }
            this._router.navigate(['/home']);
            return false;
        }
        this._router.navigate(['/login']);
        return false;
    }

}
