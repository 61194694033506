import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    toasts: any[] = [];

    // show(header: string, body: string, options: any = {}) {
    //     this.toasts.push({ header, body });
    // }

    show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
        this.toasts.push({ textOrTpl, ...options });
    }

    remove(toast) {
        this.toasts = this.toasts.filter(t => t != toast);
    }
}