import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../modules/auth/data/user.service'
import { Router } from '@angular/router';
import { NgbModal, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/modules/auth/data/user.model';
import { ToastService } from 'src/app/core/services/toast.service';
import { GoogleAnalyticsEventsService } from 'src/app/core/services/google-analytics-events.service';
import { Subscription } from 'rxjs';
import { Workspace } from '../../data/workspaces/workspace.model';
import { Constants } from '../../data/constants';
import { WorkspacesStoreService } from 'src/app/core/services/workspaces-store.service';
import { LtdCheckoutModalComponent } from '../ltd-checkout-modal/ltd-checkout-modal.component';
import { ProCheckoutModalComponent } from '../pro-checkout-modal/pro-checkout-modal.component';

@Component({
    selector: 'app-home-header',
    templateUrl: './home-header.component.html',
    styleUrls: ['./home-header.component.scss'],
    providers: [NgbTooltipConfig]
})
export class HomeHeaderComponent implements OnInit {

    // subscription: Subscription;
    // subscriptionsList: Subscription = new Subscription();
    isCollapsed = true;
    errorMessage: string = '';
    // user: User;
    // userWorkspaces: Workspace[];

    // currentWorkspace: Workspace;

    constructor(
        public _userService: UserService,
        public _workspacesStoreService: WorkspacesStoreService,
        private _router: Router,
        private _toastService: ToastService,
        private _modalService: NgbModal,
        private _gaEventsService: GoogleAnalyticsEventsService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        _tooltipConfig: NgbTooltipConfig,) {
        _tooltipConfig.openDelay = 500;
        this.matIconRegistry.addSvgIconSet(
            this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/home-header-icons-v3.svg")
        );
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnDestroy() {
        // this.subscriptionsList.unsubscribe();
    }

    ngOnInit() {
        // this._workspacesStoreService.initWorkspacesStore();
        console.log('current wk: ', this._workspacesStoreService.getCurrentWorkspace());
        // this.subscriptionsList
        //     .add(this._userService.user.subscribe((user: User) => {
        //         console.log('user home header: ', user)
        //         // this.user = user;
        //     }).add(this._workspacesStoreService.workspaces$.subscribe((workspaces: Workspace[]) => {
        //         this.userWorkspaces = workspaces;
        //         // console.log('user workspaces home header: ', workspaces)
        //     }).add(this._workspacesStoreService.currentWorkspace$.subscribe((workspace: Workspace) => {
        //         // console.log('current workspace home header: ', workspace)
        //         // this.currentWorkspace = workspace;
        //     }))));
    }

    goToBilling() {
        if (this._userService.isUserLifetimePro) {
            this._router.navigate(['/billing']);
        } else {
            this._router.navigate([`/workspaces/${this._workspacesStoreService.getCurrentUserWorkspaceId()}/billing`]);
        }
    }

    changeWorkspace(workspace: Workspace) {
        if (workspace) {
            this._workspacesStoreService.setCurrentWorkspace(workspace);
            this._toastService.show(`You have switched to ${workspace.name} workspace`, { classname: 'bg-success text-light', delay: 5000 });
            this._router.navigate([`/workspaces/${workspace._id}`]);
        } else {
            this._workspacesStoreService.setCurrentWorkspace(null);
            this._toastService.show(`You have switched to Personal Workspace`, { classname: 'bg-success text-light', delay: 5000 });
            if (this._router.url.includes('/my-designs')) {
                window.location.reload();
            } else {
                this._router.navigate([`/my-designs`]);
            }
        }
    }

    openBuyProModal() {
        this._gaEventsService.eventEmitter('Navbar', 'Navbar Upgrade Button Clicked', 'Navbar Upgrade Button Clicked', null);
        this._modalService.open(ProCheckoutModalComponent, { size: 'lg', centered: true, modalDialogClass: 'modal-upgrade' });
        // window.open(`/billing/checkout?ref=Navbar&action=Navbar Upgrade Button Clicked`, '_blank', 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes');
    }

    openEditorWithCustomSize(customWidth, customHeight) {
        if (customWidth === '' || customHeight === '') {
            this.errorMessage = 'Custom Dimensions cannot be empty';
            return;
        }
        if (isNaN(customWidth) || isNaN(customHeight)) {
            this.errorMessage = 'Custom Dimensions should be numbers only';
            return;
        }
        customWidth = parseInt(customWidth);
        customHeight = parseInt(customHeight);
        if (customWidth < Constants.MIN_CANVAS_WIDTH || customHeight < Constants.MIN_CANVAS_HEIGHT) {
            this.errorMessage = `Min width & height is ${Constants.MIN_CANVAS_WIDTH} pixels`;
            return;
        }
        this._router.navigate(['/editor'], { queryParams: { width: customWidth, height: customHeight } });
    }

    logout() {
        this._userService.logoutUserSession();
    }

}
