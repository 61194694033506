import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from 'src/app/modules/auth/data/user.service';

@Injectable({
  providedIn: 'root'
})
export class LandingPageGuard implements CanActivate {

  constructor(private _userService: UserService, private _router: Router) { }

  canActivate(): boolean {
    if (this._userService.loggedIn()) {
      this._router.navigate(['/home']);
      return false;
    }
    // this._router.navigate(['/login']);
    return true;
  }

}
