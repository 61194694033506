import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserService } from 'src/app/modules/auth/data/user.service';
import { Constants } from 'src/app/shared/data/constants';
import { Workspace } from 'src/app/shared/data/workspaces/workspace.model';


@Injectable({ providedIn: 'root' })
export class WorkspacesStoreService {
    // Make _workspacesSource private so it's not accessible from the outside,
    // expose it as workspaces$ observable (read-only) instead.
    // Write to _workspacesSource only through specified store methods below.

    public loading: boolean = true;
    private readonly _workspacesSource = new BehaviorSubject<Workspace[]>(null);

    // Exposed observable (read-only).
    readonly workspaces$ = this._workspacesSource.asObservable();

    currentWorkspace$: BehaviorSubject<Workspace> = new BehaviorSubject<Workspace>(null);
    workspaceSubscriptionStatus$: BehaviorSubject<{ planId?: string, active: boolean }> = new BehaviorSubject<{ planId?: string, active: boolean }>({
        active: false
    });

    constructor(private _localStorage: LocalStorageService, private _userService: UserService) {
        // this.getUserWorkspaces();

        _userService.login.subscribe((data) => {
            // console.log('login subscribed');
            this.getUserWorkspaces();
        });

        _userService.logout.subscribe((data) => {
            // console.log('logout subscribed');
            this.clearUserWorkspaces();
        });

    }

    public initWorkspacesStore = (): Promise<string> => {
        return new Promise((resolve) => {
            this.getUserWorkspaces().then(data => resolve(data));
        });
    }

    private getUserWorkspaces(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.getWorkspaces()) return resolve("done");
            let currentWorkspace: Workspace = JSON.parse(this._localStorage.getItem('currentWorkspace'));
            if (this._userService.getUserId() !== Constants.USER_NOT_LOGGED_IN) {
                this._userService.getUserWorkspaces(this._userService.getUserId()).subscribe((workspaces: Workspace[]) => {
                    if (workspaces.length === 0) return resolve("done");
                    workspaces.map((workspace, i) => {
                        workspace.class = `workspace-thumbnail-box-${i + 1}`;
                    })
                    this.setWorkspaces(workspaces);
                    if (currentWorkspace) {
                        this.setCurrentWorkspace(this.getWorkspaceDataById(currentWorkspace._id));
                    } else {
                        this.setCurrentWorkspace(workspaces[0]);
                    }
                    return resolve("done");
                });
            } else {
                return resolve("done");
            }
        })
    }

    getWorkspaceDataById(workspaceId: string) {
        if (!this.getWorkspaces()) {
            return null;
        }
        const workspace = this.getWorkspaces().filter((i => i._id === workspaceId));
        // console.log('workspace')
        // console.log(workspace)
        if (workspace.length > 0) {
            return workspace[0];
        } else {
            // console.log('workspace2')
            // console.log(this.getWorkspaces()[0])
            return this.getWorkspaces()[0];
        }
        // return null;
    }

    setCurrentWorkspace(workspace: Workspace) {
        this._userService.isUserLifetimePro = false;
        this.setWorkspaceSubscriptionStatus(workspace);
        this._localStorage.setItem('currentWorkspace', JSON.stringify(workspace));
        this.currentWorkspace$.next(workspace);
        this.loading = false;
        // console.log(this.getWorkspaceSubscriptionStatus());
    }

    setWorkspaceSubscriptionStatus(workspace: Workspace) {
        let currentDate: number = Date.now();
        // let currentDate: number = (1664053920 * 1000) + (1000 * 60 * 60 * 24 * 8); // to test, set in future with 8 days ahead
        let leeway: number = (1000 * 60 * 60 * 24 * 7); // 7 days
        if (Constants.LTD_PRO_TAGS.indexOf(workspace.owner?.user_subscription) !== -1) this._userService.isUserLifetimePro = true;
        if (this._userService.isUserLifetimePro) this.workspaceSubscriptionStatus$.next({ planId: 'lifetime_pro', active: true });
        else if (!workspace?.workspace_subscription) this.workspaceSubscriptionStatus$.next({ active: false });
        else if (!workspace?.workspace_subscription?.active) {
            if (
                currentDate >= Number(workspace?.workspace_subscription?.stripe_current_period_start) * 1000 &&
                currentDate <= (Number(workspace?.workspace_subscription?.stripe_current_period_end) * 1000) + leeway
            ) {
                this.workspaceSubscriptionStatus$.next({ planId: workspace?.workspace_subscription?.plan_id, active: true });
            } else {
                this.workspaceSubscriptionStatus$.next({ active: false });
            }
        } else if (workspace?.workspace_subscription?.active) {
            this.workspaceSubscriptionStatus$.next({ planId: workspace?.workspace_subscription?.plan_id, active: true });
        }
    }

    getWorkspaceSubscriptionStatus() {
        return this.workspaceSubscriptionStatus$.getValue() || null;
    }

    public canUserAccessProFeatures() {
        if (this._userService.getUserSubscription() === Constants.USER_NOT_LOGGED_IN) return false;
        else if (this._userService.isUserLifetimePro) return true;
        else if (this.getWorkspaceSubscriptionStatus()?.active) return true;
        // else if (this.getCurrentWorkspace()?.workspace_subscription?.active) return true;
        return false;
    }

    public canUserAccessProPlusFeatures() {
        if (this._userService.getUserSubscription() === Constants.USER_NOT_LOGGED_IN) return false;
        else if (this._userService.isUserLifetimePro) return true;
        else if (this.getWorkspaceSubscriptionStatus()?.active && this.getWorkspaceSubscriptionStatus()?.planId == 'pro_plus') return true;
        // else if (this.getCurrentWorkspace()?.workspace_subscription?.active && this.getCurrentWorkspace()?.workspace_subscription?.plan_id == 'pro_plus') return true;
        return false;
    }

    getCurrentUserWorkspaceId() {
        // let currentWorkspace: Workspace = JSON.parse(this._localStorage.getItem('currentWorkspace'));
        // return (currentWorkspace ? currentWorkspace._id : null);
        let currentWorkspace: Workspace = this.getCurrentWorkspace();
        return (currentWorkspace ? currentWorkspace._id : null);
    }

    getCurrentWorkspace(): Workspace {
        return this.currentWorkspace$.getValue() || null;
    }

    // Get last value without subscribing to the workspaces$ observable (synchronously).
    getWorkspaces(): Workspace[] {
        return this._workspacesSource.getValue() || null;
    }

    private _setWorkspaces(workspaces: Workspace[]): void {
        this._workspacesSource.next(workspaces);
    }

    setWorkspaces(workspaces: Workspace[]): void {
        this._setWorkspaces(workspaces);
    }

    hasWorkspacesData() {
        return this.getWorkspaces();
    }

    addWorkspaceToList(workspace: Workspace): void {
        const workspaces = [...this.getWorkspaces(), workspace];
        this._setWorkspaces(workspaces);
    }

    removeWorkspace(workspace: Workspace): void {
        const workspaces = this.getWorkspaces().filter(w => w._id !== workspace._id);
        this._setWorkspaces(workspaces);
    }

    clearUserWorkspaces(): void {
        this._setWorkspaces(null);
        // const workspaces = this.getWorkspaces().filter(w => w._id !== workspace._id);
        // this._setWorkspaces(workspaces);
    }

    // adoptPuppy(workspace: Workspace): void {
    //     const workspaces = this.getWorkspaces().map(w =>
    //         w._id === workspace._id ? new Workspace({ ...w, ...{ adopted: true } }) : p
    //     );
    //     this._setWorkspaces(workspaces);
    // }
}