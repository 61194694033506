import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'extractLettersFromName'
})
export class ExtractLettersFromNamePipe implements PipeTransform {
  transform(name: string): string {
    if (!name || name === '') return '';
    let words = name.split(' ');
    // console.log(words)
    if (words.length == 1) {
      if (words[0].length == 1) return words[0].charAt(0);
      return words[0].substring(0, 2).toUpperCase();
    }
    return words.map((item, index) => {
      if (index < 2) return item.charAt(0);
    }).join('').toUpperCase();
  }
}