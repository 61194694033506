import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteNotFoundComponent } from './components/route-not-found/route-not-found.component';
import { HomeFooterComponent } from './components/home-footer/home-footer.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule, NgbToastModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LandingPageHeaderComponent } from './components/landing-page-header/landing-page-header.component';
import { LandingPageFooterComponent } from './components/landing-page-footer/landing-page-footer.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { ToastsContainerComponent } from './components/toasts-container/toasts-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuyProCardComponent } from './components/buy-pro-card/buy-pro-card.component';
import { UpgradedProCardComponent } from './components/upgraded-pro-card/upgraded-pro-card.component';
import { UpgradedAppsumoCodeCardComponent } from './components/upgraded-appsumo-code-card/upgraded-appsumo-code-card.component';
import { MatIconModule } from '@angular/material/icon';
import { DesignsListComponent } from './components/designs-list/designs-list.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WalkthroughComponent } from './components/walkthrough/walkthrough.component';
import { MaintenanceAlertComponent } from './components/maintenance-alert/maintenance-alert.component';
import { StartNewBrandPresetComponent } from './components/start-new-brand-preset/start-new-brand-preset.component';
import { AddEditBrandPresetComponent } from './components/add-edit-brand-preset/add-edit-brand-preset.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FilterPipe } from './pipes/filter.pipe';
import { UpperCaseSpacePipe } from './pipes/uppercase-space.pipe';
import { MyDesignsDragDirective } from './directives/my-designs-drag.directive';
import { MyDesignsDropDirective } from './directives/my-designs-drop.directive';
import { CanvasDragDirective } from './directives/canvas-drag.directive';
import { CanvasDropDirective } from './directives/canvas-drop.directive';
import { WalkthroughArrowDirective, WalkthroughDialogDirective } from './directives/walkthrough.directive';
import { DeferLoadDirective } from './directives/video-lazy-load.directive';
import { FirstLetterFromNamePipe } from './pipes/first-letter-from-name.pipe';
import { FullNameOfUserPipe } from './pipes/full-name-of-user.pipe';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { FormatsFilterPipe } from './pipes/formats-filter.pipe';
import { UseCaseLandingPageHeaderComponent } from './components/use-case-landing-page-header/use-case-landing-page-header.component';
import { CreateNavItemComponent } from './components/create-nav-item/create-nav-item.component';
import { UpgradedSoloLtdCardComponent } from './components/upgraded-solo-ltd-card/upgraded-solo-ltd-card.component';
import { BuyLtdCardComponent } from './components/buy-ltd-card/buy-ltd-card.component';
import { FaqSectionComponent } from './components/faq-section/faq-section.component';
import { ExtractNameFromUrl } from './pipes/extract-name-from-url.pipe';
import { ImageUploadToCanvas } from './directives/image-upload-to-canvas.directive';
import { CopyMoveDesignToFoldersWorkspacesComponent } from './components/copy-move-design-to-folders-workspaces/copy-move-design-to-folders-workspaces.component';
import { UploadImageModalCreate } from './directives/upload-image-modal-create.directive';
import { BuyLtdIndividualComponent } from './components/buy-ltd-individual/buy-ltd-individual.component';
import { LtdCheckoutModalComponent } from './components/ltd-checkout-modal/ltd-checkout-modal.component';
import { ProCheckoutModalComponent } from './components/pro-checkout-modal/pro-checkout-modal.component';
import { PhotoroomAttributionComponent } from './components/photoroom-attribution/photoroom-attribution.component';
import { CancelWorkspaceSubscriptionComponent } from './components/cancel-workspace-subscription/cancel-workspace-subscription.component';
import { UpdateCardDetailsComponent } from './components/update-card-details/update-card-details.component';
import { TopBarAnnouncementComponent } from './components/top-bar-announcement/top-bar-announcement.component';
import { DataDeletionInstructionsUrlComponent } from './components/data-deletion-instructions-url/data-deletion-instructions-url.component';
import { SanitizeImagePipe } from './pipes/sanitize-image.pipe';
import { ExtractLettersFromNamePipe } from './pipes/extract-letters-from-name.pipe';
import { CreateWorkspaceComponent } from './modals/create-workspace/create-workspace.component';
// import { CrudOperationsComponent } from './modals/crud-operations/crud-operations.component';
// import { ArrayChunksPipe } from './pipes/array-chunks.pipe';

@NgModule({
    declarations: [
        // Components
        RouteNotFoundComponent,
        HomeFooterComponent,
        HomeHeaderComponent,
        LandingPageHeaderComponent,
        LandingPageFooterComponent,
        PrivacyPolicyComponent,
        TermsAndConditionsComponent,
        ToastsContainerComponent,
        BuyProCardComponent,
        UpgradedProCardComponent,
        UpgradedAppsumoCodeCardComponent,
        DesignsListComponent,
        WalkthroughComponent,
        MaintenanceAlertComponent,
        StartNewBrandPresetComponent,
        AddEditBrandPresetComponent,
        SkeletonLoaderComponent,
        UseCaseLandingPageHeaderComponent,
        CreateNavItemComponent,
        BuyLtdIndividualComponent,
        LtdCheckoutModalComponent,
        CancelWorkspaceSubscriptionComponent,
        CreateWorkspaceComponent,

        // Pipes
        FilterPipe,
        UpperCaseSpacePipe,
        FirstLetterFromNamePipe,
        FullNameOfUserPipe,
        FormatsFilterPipe,
        ExtractNameFromUrl,
        ExtractLettersFromNamePipe,
        SanitizeImagePipe,
        // ArrayChunksPipe,

        // Directives
        MyDesignsDragDirective,
        MyDesignsDropDirective,
        CanvasDragDirective,
        CanvasDropDirective,
        WalkthroughDialogDirective,
        WalkthroughArrowDirective,
        DeferLoadDirective,
        ImageUploadToCanvas,
        UploadImageModalCreate,

        UpgradedSoloLtdCardComponent,
        BuyLtdCardComponent,
        FaqSectionComponent,
        CopyMoveDesignToFoldersWorkspacesComponent,
        ProCheckoutModalComponent,
        PhotoroomAttributionComponent,
        UpdateCardDetailsComponent,
        TopBarAnnouncementComponent,
        DataDeletionInstructionsUrlComponent,
        // CrudOperationsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,

        FormsModule,
        ReactiveFormsModule,

        NgbCollapseModule,
        NgbDropdownModule,
        NgbToastModule,
        NgbModalModule,
        NgbTooltipModule,

        ColorPickerModule,
        MatIconModule,
        LazyLoadImageModule,
        InfiniteScrollModule,
    ],
    exports: [
        // Angular Modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

        // NgbModules
        NgbCollapseModule,
        NgbDropdownModule,
        NgbToastModule,
        NgbModalModule,
        NgbTooltipModule,

        // 3rd Party
        ColorPickerModule,
        MatIconModule,
        LazyLoadImageModule,
        InfiniteScrollModule,

        // Components
        RouteNotFoundComponent,
        HomeFooterComponent,
        HomeHeaderComponent,
        LandingPageHeaderComponent,
        LandingPageFooterComponent,
        ToastsContainerComponent,
        BuyProCardComponent,
        DesignsListComponent,
        WalkthroughComponent,
        MaintenanceAlertComponent,
        SkeletonLoaderComponent,
        UseCaseLandingPageHeaderComponent,
        CreateNavItemComponent,
        FaqSectionComponent,
        BuyLtdIndividualComponent,
        LtdCheckoutModalComponent,
        PhotoroomAttributionComponent,
        CancelWorkspaceSubscriptionComponent,
        TopBarAnnouncementComponent,
        CreateWorkspaceComponent,

        // Pipes
        FilterPipe,
        UpperCaseSpacePipe,
        FirstLetterFromNamePipe,
        FullNameOfUserPipe,
        FormatsFilterPipe,
        ExtractNameFromUrl,
        ExtractLettersFromNamePipe,
        SanitizeImagePipe,
        // ArrayChunksPipe,

        // Directives
        MyDesignsDragDirective,
        MyDesignsDropDirective,
        CanvasDragDirective,
        CanvasDropDirective,
        WalkthroughDialogDirective,
        WalkthroughArrowDirective,
        DeferLoadDirective,
        ImageUploadToCanvas,
        UploadImageModalCreate,
    ],
    providers: []
})
export class SharedModule { }
