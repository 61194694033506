import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { User } from "src/app/modules/auth/data/user.model";
import { UserService } from "src/app/modules/auth/data/user.service";
import { CancellationFeedback } from "src/app/shared/components/cancel-workspace-subscription/cancel-workspace-subscription.component";
import { Constants } from "src/app/shared/data/constants";
import { Workspace } from "src/app/shared/data/workspaces/workspace.model";
import { GoogleAnalyticsEventsService } from "./google-analytics-events.service";
import { LocalStorageService } from "./local-storage.service";
import { ToastService } from "./toast.service";
import { WorkspacesStoreService } from "./workspaces-store.service";

export interface PlanDetails {
  id: string;
  title: string;
  price: number;
  pricePerMonth?: number;
  oldPricePerMonth?: number;
  featuresIncluded: string[];
  // featuresIncluded: string[][];
}

@Injectable({
  providedIn: 'root'
})
export class WorkspaceBillingService {

  yearlyDiscountPercentage: number = 60;
  viewType: string = 'checkout'; // or checkout
  planDurationType: string = 'yearly';
  planOneDetails: PlanDetails = {
    id: Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id,
    title: Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.title,
    price: Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.price,
    // pricePerMonth: 0,
    featuresIncluded: Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.features,
  }
  planTwoDetails: PlanDetails = {
    id: Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.id,
    title: Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.title,
    price: Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.price,
    featuresIncluded: Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.features,
  }
  planSelected: PlanDetails;
  finalPrice: number = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.price;
  perTeamMemberPrice: number = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.pricePerTeamMember;

  monthlyPrice: number = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.price;
  yearlyPrice: number = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.price;

  downgradeToMonthlyDisabled: boolean = false;
  downgradeToProDisabled: boolean = false;

  userCardDetails: User;

  stripe: stripe.Stripe;
  elements: any;
  card: any;

  promoCodeApplied: boolean = false;
  promoCode: string = '';
  promoCodeDiscount: number = 0;
  promoCheck: boolean = false;
  promoCodeShown: boolean = true;
  errorMessage: string = '';
  billingName: string = '';

  cardDetailsInputTriggered: boolean = false;
  billingNameInputTriggered: boolean = false;

  isLoading: boolean = false;
  dataLoading: boolean = false;

  totalActiveTeamMembersInWorkspace: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedFeature: string = '';

  constructor(
    private _http: HttpClient,
    @Inject(DOCUMENT) private document,
    private _localStorageService: LocalStorageService,
    public _userService: UserService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    public _workspacesStoreService: WorkspacesStoreService,
    private _gaEventsService: GoogleAnalyticsEventsService
  ) {
    this.planSelected = this.planOneDetails;
    // console.log(_userService.user.getValue().stripe_customer_id);
  }

  createStripeCustomer() {
    this.isLoading = true;
    this.getUserStripeCustomerId(this._userService.getUserId()).subscribe((result: User) => {
      this.isLoading = false;
      this._userService.user.next({ ...this._userService.user.getValue(), stripe_customer_id: result.stripe_customer_id });
    }, (error) => {
      this.isLoading = false;
      console.error('error retreiving customer id');
      this._toastService.show('An error occurred, please refresh the page and try again', { classname: 'bg-danger text-light', delay: 5000 });
      throw error;
    });
  }

  resetUI() {
    this.downgradeToMonthlyDisabled = false;
    this.downgradeToProDisabled = false;
    this.setBillingPeriod('yearly');
    this.setSelectedPlan(this.planOneDetails);
    this.billingName = "";
    this.errorMessage = "";
    this.promoCodeApplied = false;
    this.promoCheck = false;
    this.promoCode = null;
    this.promoCodeDiscount = 0;
    this.yearlyDiscountPercentage = 60;
  }

  setInitialUI() {
    if (this._workspacesStoreService.getCurrentWorkspace()?.workspace_subscription?.active &&
      this._workspacesStoreService.getCurrentWorkspace()?.workspace_subscription?.plan_type == 'yearly') {
      this.downgradeToMonthlyDisabled = true;
      this.setBillingPeriod('yearly');
    }

    if (this._workspacesStoreService.getCurrentWorkspace()?.workspace_subscription?.active &&
      this._workspacesStoreService.getCurrentWorkspace()?.workspace_subscription?.plan_id == 'pro_plus') {
      this.downgradeToProDisabled = true;
      this.downgradeToMonthlyDisabled = true;
      this.setSelectedPlan(this.planTwoDetails);
      this.setBillingPeriod('yearly');
    }

    // if (this._workspacesStoreService.getCurrentWorkspace().workspace_subscription?.active &&
    //   this._workspacesStoreService.getCurrentWorkspace().workspace_subscription?.plan_id == 'pro') {
    //   // this.downgradeToMonthlyDisabled = true;
    //   // this.setBillingPeriod('yearly');
    // }
  }

  changePaymentMethodUsingCardDetails() {
    this.isLoading = true;
    if (this.billingName.trim() === '') {
      this.isLoading = false;
      return this.errorMessage = 'Please add your name';
    }
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        name: this.billingName,
      }
    }).then((result) => {
      // console.log(result)
      if (result.error) {
        this.displayError(result);
      } else {
        this.changePaymentMethod({
          paymentMethodId: result.paymentMethod.id,
        });
      }
    }).catch((error) => {
      throw error;
    });
  }

  selectPlanAndChangeViewType(planId: string) {
    if (planId === this.planOneDetails.id) {
      this.setSelectedPlan(this.planOneDetails);
    } else {
      this.setSelectedPlan(this.planTwoDetails);
    }
    this.changeViewType('checkout');
  }

  changeViewType(viewType: string) {
    this.viewType = viewType;
  }

  private changePaymentMethod({ paymentMethodId }) {
    return this.updateCustomerPaymentMethod(this._userService.getUserId(), this._userService.user.getValue().stripe_customer_id, paymentMethodId).subscribe((data: any) => {
      if (data.error) {
        return this.displayError(data);
      }
      this.isLoading = false;
      this._userService.setUser(data.user);
      this.userCardDetails = data.user;
      // this.sendUserDataBack.next(data.user);
      this._toastService.show(`Card details updated successfully!`, { classname: "bg-success text-light", delay: 5000 });
      this._modalService.dismissAll();
      this._gaEventsService.eventEmitter('Billing Page', 'Card details updated', 'Card details updated', null);
    }, (error) => {
      return this.displayError(error.error);
    });
  }

  setBillingPeriod(duration: string) {
    if (duration == 'monthly' && this.downgradeToMonthlyDisabled) return;
    // this._workspacesStoreService.getCurrentWorkspace().workspace_subscription.addons_subscription_details[0].plans
    this.planDurationType = duration;
    this.setFinalPrice();
    if (this.planDurationType == 'monthly') {
      this.planOneDetails.price = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.price;
      this.planTwoDetails.price = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.price;

      this.planOneDetails.pricePerMonth = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.pricePerMonth;
      this.planTwoDetails.pricePerMonth = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.pricePerMonth;

      this.planOneDetails.oldPricePerMonth = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.pricePerMonth;
      this.planTwoDetails.oldPricePerMonth = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.pricePerMonth;
    } else {
      this.planOneDetails.price = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.price;
      this.planTwoDetails.price = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLUS_PLAN.price;

      this.planOneDetails.pricePerMonth = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.pricePerMonth;
      this.planTwoDetails.pricePerMonth = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLUS_PLAN.pricePerMonth;

      this.planOneDetails.oldPricePerMonth = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.oldPricePerMonth;
      this.planTwoDetails.oldPricePerMonth = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLUS_PLAN.oldPricePerMonth;
    }
    this.setPerTeamMemberPrice();
  }

  setSelectedPlan(plan: PlanDetails) {
    if (plan.id == Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id && this.downgradeToProDisabled) return;
    this.planSelected = plan;
    // console.log(this.planSelected)
    this.setFinalPrice();
    this.setPerTeamMemberPrice();

    if (plan.id == Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id) {
      this.monthlyPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.price;
      this.yearlyPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.price;
    } else {
      this.monthlyPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.price;
      this.yearlyPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLUS_PLAN.price;
    }
    // if (removeLocalStorageData) {
    //   this.clearCache();
    // }
  }

  setPerTeamMemberPrice() {
    if (this.planSelected.id === Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id) {
      if (this.planDurationType == 'monthly') this.perTeamMemberPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.pricePerTeamMember;
      else this.perTeamMemberPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.pricePerTeamMember;
    } else if (this.planSelected.id === Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.id) {
      if (this.planDurationType == 'monthly') this.perTeamMemberPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.pricePerTeamMember;
      else this.perTeamMemberPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLUS_PLAN.pricePerTeamMember;
    }
  }

  setFinalPrice() {
    // let totalActiveMembers = this.getTotalActiveTeamMembers() == 0 ? 0 : this.getTotalActiveTeamMembers();
    if (this.planSelected.id === Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id) {
      if (this.planDurationType == 'monthly') {
        this.finalPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.price + (this.getTotalActiveTeamMembers() * Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.pricePerTeamMember)
      }
      else {
        this.finalPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.price + (this.getTotalActiveTeamMembers() * Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.pricePerTeamMember)
      }
    } else if (this.planSelected.id === Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.id) {
      if (this.planDurationType == 'monthly') {
        this.finalPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.price + (this.getTotalActiveTeamMembers() * Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.pricePerTeamMember)
      }
      else {
        this.finalPrice = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLUS_PLAN.price + (this.getTotalActiveTeamMembers() * Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLUS_PLAN.pricePerTeamMember)
      }
    }
    this.finalPrice = Number(this.finalPrice.toFixed(2));
  }

  setTotalActiveTeamMembers() {
    let totalActiveMembers: number = 0;
    for (let member of this._workspacesStoreService.getCurrentWorkspace()?.members || []) {
      if (member.status == 'active') totalActiveMembers += 1;
    }
    this.totalActiveTeamMembersInWorkspace.next(totalActiveMembers);
    this.setFinalPrice();
  }

  getTotalActiveTeamMembers() {
    return this.totalActiveTeamMembersInWorkspace.getValue();
  }


  onBillingNameKeypress(event) {
    if (this.billingNameInputTriggered) return;
    this.billingNameInputTriggered = true;
    // this._gaEventsService.ltdCheckoutStepsEvents(this.finalPlan, 2);
  }

  isScriptLoaded(lib) {
    return this.document.querySelectorAll('[src="' + lib + '"]').length > 0
  }

  initStripe() {
    (() => {
      var d = this.document;
      var s = d.createElement("script");
      s.src = Constants.STRIPE_SCRIPT_URL;
      // (s as any).async = 1;
      s.onload = () => {
        this.initStripeElements();
      };
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

  initStripeElements() {
    // console.log(this.customerId);
    // Set your publishable key: remember to change this to your live publishable key in production
    // See your keys here: https://dashboard.stripe.com/account/apikeys
    this.stripe = Stripe(Constants.STRIPE_PUBLISHABLE_KEY);

    // Create `card` element that will watch for updates
    // and display error messages
    this.elements = this.stripe.elements();

    // Set up Stripe.js and Elements to use in checkout form
    var style = {
      base: {
        color: "#495057",
        fontFamily: 'Inter, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#9f9f9f"
        }
      },
      invalid: {
        color: "#F72119",
        iconColor: "#F72119"
      }
    };
    // Create `card` element that will watch for updates
    // and display error messages
    setTimeout(() => {
      try {
        this.card = this.elements.create('card', { style });
        this.card.mount('#card-element');
        this.card.addEventListener('change', event => {
          this.displayError(event);
          if (this.cardDetailsInputTriggered) return;
          this.cardDetailsInputTriggered = true;
          // this._gaEventsService.ltdCheckoutStepsEvents(this.finalPlan, 3);
        });
      } catch (e) { }
    }, 2000);
  }

  applyPromoCode() {
    this.errorMessage = '';
    if (this.promoCode.trim() == '') return;
    this.applyPromoCodeCall(this.promoCode).subscribe((result) => {
      // this.promoDiscountChange.emit(result.promoCodeDiscount);
      this.promoCodeDiscount = result.promoCodeDiscount;
      this.promoCodeApplied = true;
      let discountValue = ((1 - result.promoCodeDiscount) * 100).toFixed(0);
      this.yearlyDiscountPercentage = Number(discountValue);
      this._toastService.show(`🚀 Your ${discountValue}% discount has been applied!`, { classname: 'bg-success text-light', delay: 5000 });
    }, (error) => {
      this.displayError(error.error);
      throw error;
    });
  }

  /**
   * 1. get card details from user
   * 2. createPaymentMethodUsingCardDetails
   * 3. create subscription
   * 4.
   */
  public startPaymentProcess() {
    this.errorMessage = '';
    this.isLoading = true;
    if (this.billingName.trim() === '') {
      this.isLoading = false;
      return this.errorMessage = 'Please enter your name';
    }
    if (!this.promoCheck) {
      this.promoCode = '';
    }
    // const latestInvoicePaymentIntentStatus = this._localStorageService.getItem('latestInvoicePaymentIntentStatus');

    // if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
    //   console.log('retry');
    //   const invoiceId = this._localStorageService.getItem('latestInvoiceId');
    //   const isPaymentRetry = true;
    //   // create new payment method & retry payment on invoice with new payment method
    //   this.createPaymentMethodUsingCardDetails(this.card, isPaymentRetry, invoiceId);
    // } else {
    // create new payment method & create subscription
    this.createPaymentMethodUsingCardDetails(this.card, false, null);
    // }
  }

  // private coonfirmCardPayment(clientSecret: string, paymentIntentId: string) {
  //   this.stripe.confirmCardPayment(clientSecret, {
  //     // payment_method: this.paymentMethodId,
  //     payment_method: {
  //       card: this.card,
  //       billing_details: {
  //         name: this.billingName
  //       }
  //     }
  //   }).then((result) => {
  //     if (result.error) {
  //       this.isLoading = false;
  //       console.log('in error');
  //       console.log(result);
  //       // alert(result.error.message);
  //       // this.displayError(result);
  //       if (result.error.code === 'payment_intent_unexpected_state') {
  //         this._localStorageService.removeItem('clientSecret');
  //         this._localStorageService.removeItem('paymentIntentId');
  //       } else {
  //         this._localStorageService.setItem('clientSecret', clientSecret);
  //         this._localStorageService.setItem('paymentIntentId', paymentIntentId);
  //       }
  //     } else {
  //       this._localStorageService.removeItem('clientSecret');
  //       this._localStorageService.removeItem('paymentIntentId');
  //       console.log('in result');
  //       console.log(result);
  //       if (result.paymentIntent.status === 'succeeded') {
  //         // let label = `Plan:${this.finalPlan.planSelected.workspaceSubscriptionPriceId}`;
  //         // if (this.sourceFeature !== '') label = `Plan:${this.finalPlan.planSelected.workspaceSubscriptionPriceId}:${this.sourceFeature}`;
  //         // this._gaEventsService.eventEmitter('solo-ltd', `ltd:purchase-${this.sourcePage}`, label, this.finalPlan.totalPrice);
  //         // this._gaEventsService.sendPurchaseEvent(this.finalPlan, result.paymentIntent.id);
  //         // payment succeeded,
  //         // give user ltd access,
  //         // show congrats popup and
  //         // direct them to billing page
  //       }
  //     }
  //   }).catch((error) => {
  //     this.isLoading = false;
  //     throw error;
  //   });
  // }

  private createPaymentMethodUsingCardDetails(card, isPaymentRetry, invoiceId) {
    this.stripe.createPaymentMethod({
      type: 'card',
      card: card,
      billing_details: {
        name: this.billingName,
      }
    }).then((result) => {
      // console.log(result);
      if (result.error) {
        this.displayError(result);
      } else {
        if (isPaymentRetry) {
          this.retryInvoiceWithNewPaymentMethod(result.paymentMethod.id, invoiceId);
        } else {
          if (this._workspacesStoreService.getCurrentWorkspace().workspace_subscription?.active &&
            this._workspacesStoreService.getCurrentWorkspace().workspace_subscription?.stripe_subscription_items?.length >= 1) {
            this.upgradeSubscription(result.paymentMethod.id);
          } else {
            this.createSubscription(result.paymentMethod.id);
          }
        }
      }
    }).catch((error) => {
      throw error;
    });
  }

  private retryInvoiceWithNewPaymentMethod(paymentMethodId, invoiceId) {
    return this.retryInvoice(this._userService.user.getValue().stripe_customer_id, paymentMethodId, invoiceId).subscribe((invoice) => {
      if (invoice.error) {
        // The card had an error when trying to attach it to a customer
        console.error(invoice.error);
        // throw invoice;
        this.displayError(invoice);
      } else {
        console.log('INVOICE OBJECT');
        console.log(invoice);
        console.log('SUBSCRIPTION STATUS: ' + invoice.status);
        console.log('PAYMENT INTENT STATUS: ' + invoice.payment_intent.status);
        return this.stripe.confirmCardPayment(invoice.payment_intent.client_secret, {
          payment_method: paymentMethodId,
        }).then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            console.log('IN CONFIRM CARD PAYMENT ERROR INVOICE');
            return this.displayError(result);
          } else {
            console.log(result);
            if (result.paymentIntent.status === 'succeeded') {
              return this.onSubscriptionComplete({ invoice: invoice, subscription: null, paymentMethodId, workspaceSubscriptionPriceId: this.planSelected.id });
            } else {
              console.error('In else of INVOICE RETRY: ' + result.paymentIntent.status);
            }
          }
        }).catch((error) => {
          throw error;
        });
      }
    });
  }

  private createSubscription(paymentMethodId: string) {
    let workspaceSubscriptionPriceId: string = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id;
    let teamMemberSubscriptionPriceId: string = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.MONTHLY_TEAM_MEMBER_PRO_PLAN.id;
    if (this.planSelected.id === Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id) {
      if (this.planDurationType == 'monthly') {
        workspaceSubscriptionPriceId = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id;
        teamMemberSubscriptionPriceId = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.MONTHLY_TEAM_MEMBER_PRO_PLAN.id;
      } else {
        workspaceSubscriptionPriceId = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.id;
        teamMemberSubscriptionPriceId = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.YEARLY_TEAM_MEMBER_PRO_PLAN.id;
      }
    } else if (this.planSelected.id === Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.id) {
      if (this.planDurationType == 'monthly') {
        workspaceSubscriptionPriceId = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.id;
        teamMemberSubscriptionPriceId = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.MONTHLY_TEAM_MEMBER_PRO_PLUS_PLAN.id;
      } else {
        workspaceSubscriptionPriceId = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLUS_PLAN.id;
        teamMemberSubscriptionPriceId = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.YEARLY_TEAM_MEMBER_PRO_PLUS_PLAN.id;
      }
    }
    this.createSubscriptionApiCall(this._userService.user.getValue().stripe_customer_id, this._workspacesStoreService.getCurrentUserWorkspaceId(), paymentMethodId, workspaceSubscriptionPriceId, teamMemberSubscriptionPriceId, this.promoCode).subscribe((subscription: any) => {
      if (subscription.error) {
        // The card had an error when trying to attach it to a customer
        // console.error(subscription.error)
        // throw subscription;
        this.displayError(subscription);
        return;
      }
      console.log('SUBSCRIPTION OBJECT');
      console.log(subscription);

      console.log('SUBSCRIPTION STATUS: ' + subscription.status);
      console.log('PAYMENT INTENT STATUS: ' + subscription.latest_invoice.payment_intent.status);

      if (subscription.status === 'active') {
        return this.onSubscriptionComplete({ invoice: null, subscription: subscription, paymentMethodId, workspaceSubscriptionPriceId: this.planSelected.id });
      }
      else if (subscription.status === 'incomplete' && subscription.latest_invoice.payment_intent.status === 'requires_action') {
        return this.stripe.confirmCardPayment(subscription.latest_invoice.payment_intent.client_secret, {
          payment_method: paymentMethodId,
        }).then((result) => {
          if (result.error) {
            console.log(result);
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            console.log('IN CONFIRM CARD PAYMENT ERROR');
            // this._localStorageService.setItem('latestInvoiceId', subscription.latest_invoice.id);
            // this._localStorageService.setItem('latestInvoicePaymentIntentStatus', result.error.payment_intent.status); // 'requires_payment_method'
            // this._localStorageService.setItem('invoiceTimePeriod', String(new Date().getTime() + (23 * 60 * 60 * 1000)));
            return this.displayError(result);
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              return this.onSubscriptionComplete({ invoice: null, subscription: subscription, paymentMethodId, workspaceSubscriptionPriceId: this.planSelected.id });
            } else {
              console.error('In else: ' + result.paymentIntent.status);
            }
          }
        }).catch((error) => {
          throw error;
        });
      } else if (subscription.status === 'incomplete' && subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        // this._localStorageService.setItem('latestInvoiceId', subscription.latest_invoice.id);
        // this._localStorageService.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status);
        // this._localStorageService.setItem('invoiceTimePeriod', String(new Date().getTime() + (23 * 60 * 60 * 1000)));
        // this.latestInvoiceId = subscription.latest_invoice.id;
        // this.latestInvoicePaymentIntentStatus = subscription.latest_invoice.payment_intent.status;
        this.displayError({ error: { message: 'Your card was declined' } });
      }
    }, (error) => {
      console.log(error)
      this.displayError(error.error);
      throw error;
    });
  }

  private upgradeSubscription(paymentMethodId: string) {
    let workspaceSubscriptionPriceId: string = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id;
    let teamMemberSubscriptionPriceId: string = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.MONTHLY_TEAM_MEMBER_PRO_PLAN.id;
    if (this.planSelected.id === Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id) {
      if (this.planDurationType == 'monthly') {
        workspaceSubscriptionPriceId = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLAN.id;
        teamMemberSubscriptionPriceId = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.MONTHLY_TEAM_MEMBER_PRO_PLAN.id;
      } else {
        workspaceSubscriptionPriceId = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLAN.id;
        teamMemberSubscriptionPriceId = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.YEARLY_TEAM_MEMBER_PRO_PLAN.id;
      }
    } else if (this.planSelected.id === Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.id) {
      if (this.planDurationType == 'monthly') {
        workspaceSubscriptionPriceId = Constants.PIXELIED_SUBSCRIPTION_PLANS.MONTHLY_PRO_PLUS_PLAN.id;
        teamMemberSubscriptionPriceId = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.MONTHLY_TEAM_MEMBER_PRO_PLUS_PLAN.id;
      } else {
        workspaceSubscriptionPriceId = Constants.PIXELIED_SUBSCRIPTION_PLANS.YEARLY_PRO_PLUS_PLAN.id;
        teamMemberSubscriptionPriceId = Constants.TEAM_MEMBER_SUBSCRIPTION_PLANS.YEARLY_TEAM_MEMBER_PRO_PLUS_PLAN.id;
      }
    }
    this.upgradeSubscriptionApiCall(this._userService.user.getValue().stripe_customer_id, this._workspacesStoreService.getCurrentUserWorkspaceId(), this._workspacesStoreService.getCurrentWorkspace().workspace_subscription, paymentMethodId, workspaceSubscriptionPriceId, teamMemberSubscriptionPriceId, this.promoCode).subscribe((subscription: any) => {
      if (subscription.error) {
        // The card had an error when trying to attach it to a customer
        // console.error(subscription.error)
        // throw subscription;
        this.displayError(subscription);
        return;
      }
      console.log('SUBSCRIPTION OBJECT');
      console.log(subscription);

      console.log('SUBSCRIPTION STATUS: ' + subscription.status);
      console.log('PAYMENT INTENT STATUS: ' + subscription.latest_invoice.payment_intent.status);

      if (subscription.status === 'active') {
        return this.onSubscriptionComplete({ invoice: null, subscription: subscription, paymentMethodId, workspaceSubscriptionPriceId: this.planSelected.id });
      }
      else if (subscription.status === 'incomplete' && subscription.latest_invoice.payment_intent.status === 'requires_action') {
        return this.stripe.confirmCardPayment(subscription.latest_invoice.payment_intent.client_secret, {
          payment_method: paymentMethodId,
        }).then((result) => {
          if (result.error) {
            console.log(result);
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            console.log('IN CONFIRM CARD PAYMENT ERROR');
            // this._localStorageService.setItem('latestInvoiceId', subscription.latest_invoice.id);
            // this._localStorageService.setItem('latestInvoicePaymentIntentStatus', result.error.payment_intent.status); // 'requires_payment_method'
            // this._localStorageService.setItem('invoiceTimePeriod', String(new Date().getTime() + (23 * 60 * 60 * 1000)));
            return this.displayError(result);
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              return this.onSubscriptionComplete({ invoice: null, subscription: subscription, paymentMethodId, workspaceSubscriptionPriceId: this.planSelected.id });
            } else {
              console.error('In else: ' + result.paymentIntent.status);
            }
          }
        }).catch((error) => {
          throw error;
        });
      } else if (subscription.status === 'incomplete' && subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        // this._localStorageService.setItem('latestInvoiceId', subscription.latest_invoice.id);
        // this._localStorageService.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status);
        // this._localStorageService.setItem('invoiceTimePeriod', String(new Date().getTime() + (23 * 60 * 60 * 1000)));
        // this.latestInvoiceId = subscription.latest_invoice.id;
        // this.latestInvoicePaymentIntentStatus = subscription.latest_invoice.payment_intent.status;
        this.displayError({ error: { message: 'Your card was declined' } });
      }
    }, (error) => {
      console.log(error)
      this.displayError(error.error);
      throw error;
    });
  }

  private onSubscriptionComplete({ invoice, subscription, paymentMethodId, workspaceSubscriptionPriceId }) {

    let subscriptionId;
    if (subscription) subscriptionId = subscription.id;
    else subscriptionId = invoice.subscription;

    this.upgradeWorkspaceSubscription(this._userService.getUserId(), this._userService.user.getValue().stripe_customer_id, subscriptionId, this._workspacesStoreService.getCurrentUserWorkspaceId())
      .subscribe(({ message, user, workspace }) => {
        // console.log(data);
        this.isLoading = false;
        // this._workspacesStoreService.setCurrentWorkspace(data.workspace);
        this._workspacesStoreService.setCurrentWorkspace({
          ...this._workspacesStoreService.getCurrentWorkspace(), workspace_subscription: workspace.workspace_subscription
        });
        this._workspacesStoreService.setWorkspaces(
          this._workspacesStoreService.getWorkspaces().map((w) => {
            if (w._id === this._workspacesStoreService.getCurrentUserWorkspaceId()) { return { ...w, workspace_subscription: workspace.workspace_subscription } }
            return { ...w }
          })
        );
        this._modalService.dismissAll();
        this._gaEventsService.eventEmitter('upgrade-popup-purchase', this.selectedFeature, this.selectedFeature);
      })
  }

  private upgradeWorkspaceSubscription(userId, customerId, subscriptionId, workspaceId) {
    return this._http.post<any>(`/api/stripe/billing/upgrade-workspace-subscription`, { userId, customerId, subscriptionId, workspaceId });
  }

  displayError(event) {
    this.isLoading = false;
    if (event.error) this.errorMessage = event.error.message;
    else this.errorMessage = '';
  }

  clearCache() {
    this._localStorageService.removeItem('latestInvoicePaymentIntentStatus');
    this._localStorageService.removeItem('latestInvoiceId');
    this._localStorageService.removeItem('invoiceTimePeriod');
  }

  private createSubscriptionApiCall(customerId, workspaceId, paymentMethodId, workspaceSubscriptionPriceId, teamMemberSubscriptionPriceId, promoCode) {
    return this._http.post<any>(`/api/stripe/billing/create-subscription`, { customerId, workspaceId, paymentMethodId, workspaceSubscriptionPriceId, teamMemberSubscriptionPriceId, promoCode });
  }

  private upgradeSubscriptionApiCall(customerId, workspaceId, workspaceSubscription, paymentMethodId, workspaceSubscriptionPriceId, teamMemberSubscriptionPriceId, promoCode) {
    return this._http.post<any>(`/api/stripe/billing/upgrade-subscription`, { customerId, workspaceId, workspaceSubscription, paymentMethodId, workspaceSubscriptionPriceId, teamMemberSubscriptionPriceId, promoCode });
  }

  private retryInvoice(customerId: string, paymentMethodId: string, invoiceId: string) {
    return this._http.post<any>('/api/stripe/billing/retry-invoice', { customerId, paymentMethodId, invoiceId });
  }

  private getUserStripeCustomerId(userId: string) {
    return this._http.get<any>(`/api/stripe/billing/users/${userId}/customer-id`);
  }

  private applyPromoCodeCall(promoCode: string) {
    return this._http.get<any>(`/api/stripe/billing/apply-promo-code/${promoCode}`);
  }

  public getUserCardDetails(userId: string) {
    return this._http.get<any>(`/api/stripe/billing/users/${userId}/card-details`);
  }

  private updateCustomerPaymentMethod(userId: string, customerId: string, paymentMethodId: string) {
    return this._http.post<any>('/api/stripe/billing/update-customer-payment-method', { userId, customerId, paymentMethodId });
  }

  public getInvoicesOfWorkspace(workspaceId: string, pageNum: number, pageSize: number) {
    return this._http.get<any>(`/api/invoices/workspaces/${workspaceId}?pageNum=${pageNum}&pageSize=${pageSize}`);
  }

  public cancelWorkspaceSubscription(workspaceId: string, cancellationFeedback: CancellationFeedback) {
    return this._http.put<any>(`/api/stripe/billing/cancel-workspace-subscription/${workspaceId}`, { cancellationFeedback });
  }

  public getPxLTDUserSubscriptionDetails(userId: string) {
    return this._http.get<any>(`/api/stripe/billing/users/${userId}/ltd-subscription`);
  }

  public getUserAppSumoSubscriptionPlans(userId: string) {
    return this._http.get<any>(`/api/stripe/billing/users/${userId}/appsumo-subscription`);
  }

  public getUserRemainingCredits(userId: string) {
    return this._http.get<any>(`/api/ai-image-processor/users/${userId}/credits`);
  }

  public getWorkspaceRemainingCredits(workspaceId: string) {
    return this._http.get<any>(`/api/ai-image-processor/workspaces/${workspaceId}/credits`);
  }

  public stackAppSumoCode(userId: string, appsumoCode: string) {
    return this._http.put<any>(`/api/billing/users/${userId}/appsumo-subscription`, { appsumoCode: appsumoCode });
  }

  public stackVieDeDingueCode(userId: string, vdCode: string) {
    return this._http.put<any>(`/api/billing/users/${userId}/vd-subscription`, { vdCode: vdCode });
  }
}