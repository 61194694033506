<!-- <app-maintenance-alert></app-maintenance-alert> -->
<nav class="navbar navbar-expand-lg navbar-light bg-white px-navbar">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="['/home']">
      <img
        src="https://static-assets.pixelied.com/logos-v2/px-logo-black.svg"
        width="40"
        height="40"
        alt="Pixelied Logo Black"
      />
    </a>

    <button
      class="navbar-toggler"
      type="button"
      (click)="isCollapsed = !isCollapsed"
    >
      &#9776;
    </button>

    <div [ngbCollapse]="isCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav">
        <li [routerLinkActive]="['active']" class="nav-item">
          <a
            *ngIf="_userService.user | async"
            [routerLink]="['/home']"
            class="nav-link"
            (click)="isCollapsed = true"
            >Home</a
          >
          <span class="active-line"></span>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a
            *ngIf="!(_userService.user | async)"
            class="nav-link"
            [routerLink]="['/create']"
            (click)="isCollapsed = true"
            >Create</a
          >
          <span class="active-line"></span>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a
            *ngIf="!(_userService.user | async)"
            class="nav-link"
            [routerLink]="['/features']"
            (click)="isCollapsed = true"
            >Features</a
          >
          <span class="active-line"></span>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a
            class="nav-link"
            [routerLink]="['/templates']"
            (click)="isCollapsed = true"
            >Templates</a
          >
          <span class="active-line"></span>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a
            *ngIf="_userService.user | async"
            class="nav-link"
            [routerLink]="['/my-designs']"
            (click)="isCollapsed = true"
            >My Designs</a
          >
          <span class="active-line"></span>
        </li>
        <li class="nav-item position-relative" [routerLinkActive]="['active']">
          <div class="new-feature-tag">NEW</div>
          <a
            class="nav-link"
            [routerLink]="['/mockups']"
            (click)="isCollapsed = true"
            >Mockups</a
          >
          <span class="active-line"></span>
        </li>
        <li class="nav-item position-relative" [routerLinkActive]="['active']">
          <a
            class="nav-link"
            href="https://pixelied.com/convert/"
            (click)="isCollapsed = true"
            >Convert</a
          >
          <span class="active-line"></span>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a
            *ngIf="!(_userService.user | async)"
            class="nav-link"
            [routerLink]="['/pricing']"
            (click)="isCollapsed = true"
            >Pricing</a
          >
          <span class="active-line"></span>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a
            *ngIf="!(_userService.user | async)"
            class="nav-link"
            href="https://pixelied.com/blog/"
            (click)="isCollapsed = true"
            >Blog</a
          >
          <span class="active-line"></span>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li
          *ngIf="
            (_userService.user | async) &&
            !(_workspacesStoreService.workspaceSubscriptionStatus$ | async)
              .active
          "
          class="nav-item"
          style="padding: 12px 0px"
        >
          <button
            (click)="openBuyProModal()"
            class="btn btn-yellow-gradient"
            style="padding: 9px 18px"
          >
            Upgrade
          </button>
        </li>

        <li class="nav-item" placement="bottom-right" style="padding: 12px 8px">
          <a
            [routerLink]="['/upload']"
            class="d-flex flex-row justify-content-center align-items-center btn btn-primary no-caret-dropdown"
            style="font-weight: 500; padding: 9px 18px"
          >
            <mat-icon
              class="mr-2"
              svgIcon="header-add"
              style="width: 12px; height: 12px; font-size: 8px"
            >
            </mat-icon>
            Create a design
          </a>
        </li>

        <li
          *ngIf="(_userService.user | async) === null"
          class="nav-item"
          style="padding: 12px 0px"
        >
          <a
            [routerLink]="['/login']"
            class="btn btn-outline-primary"
            style="padding: 9px 18px; font-weight: 600"
            >Sign In
          </a>
        </li>

        <!-- <li
          *ngIf="_userService.user | async"
          class="nav-item"
          ngbDropdown
          container="body"
          display="dynamic"
          placement="bottom-right"
        >
          <a
            class="nav-link dropdown-nav"
            title="{{ (_userService.user | async).email }}"
            style="
              cursor: pointer;
              padding: 12px 0px;
              display: flex;
              flex-direction: row;
              align-items: center;
            "
            ngbDropdownToggle
            id="navbarDropdown1"
            role="button"
          >
            <span
              class="user-circle"
              [class.user-profile-image]="
                (_userService.user | async).profile_thumbnail_url
              "
              [style.background-image]="
                (_userService.user | async).profile_thumbnail_url
                  ? 'url(' +
                    (_userService.user | async).profile_thumbnail_url +
                    ')'
                  : 'none'
              "
            >
              <span
                *ngIf="!(_userService.user | async).profile_thumbnail_url"
                class="user-letter"
                >{{ _userService.user | async | firstLetterFromName }}</span
              >
            </span>
          </a>

          <div
            ngbDropdownMenu
            aria-labelledby="navbarDropdown1"
            class="px-dropdown-menu"
          >
            <div class="header-area d-flex">
              <span
                class="user-circle"
                [class.user-profile-image]="
                  (_userService.user | async).profile_thumbnail_url
                "
                [style.background-image]="
                  (_userService.user | async).profile_thumbnail_url
                    ? 'url(' +
                      (_userService.user | async).profile_thumbnail_url +
                      ')'
                    : 'none'
                "
              >
                <span
                  *ngIf="!(_userService.user | async).profile_thumbnail_url"
                  class="user-letter"
                  >{{ _userService.user | async | firstLetterFromName }}</span
                >
              </span>
              <div
                class="d-flex flex-column"
                style="width: 80%; margin-left: 6px"
              >
                <span class="headline text-truncate">{{
                  _userService.user | async | fullNameOfUser
                }}</span>
                <span class="subheadline text-truncate">{{
                  (_userService.user | async).email
                }}</span>
              </div>
            </div>
            <hr class="divider" />
            <a
              ngbDropdownItem
              [routerLink]="['/account-settings']"
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="header-settings" class="item-icon"></mat-icon>
              <span class="item-text">Account Settings</span>
            </a>
            <hr class="divider" />
            <span
              *ngIf="_userService.isUserLifetimePro"
              ngbDropdownItem
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="header-plan" class="item-icon"></mat-icon>
              <span class="item-text flex-grow-1">Plan</span>
              <span
                [class.pro-tag]="
                  (_userService.user | async)?.user_subscription !== 'free'
                "
                class="tag free-tag"
                >{{
                  (_userService.user | async)?.user_subscription
                    | uppercaseSpace
                }}</span
              >
            </span>
            <a
              (click)="goToBilling()"
              ngbDropdownItem
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="header-invoice" class="item-icon"></mat-icon>
              <span class="item-text">Billing</span>
            </a>

            <hr class="divider" />
            <a
              ngbDropdownItem
              href="https://www.facebook.com/groups/417169315953663/"
              rel="noopener"
              target="_blank"
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="header-fb" class="item-icon"></mat-icon>
              <span class="item-text">Join Facebook Group</span>
            </a>

            <hr class="divider" />
            <a
              ngbDropdownItem
              href="https://help.pixelied.com"
              target="_blank"
              rel="noopener"
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="header-help" class="item-icon"></mat-icon>
              <span class="item-text">Help Center</span>
            </a>

            <hr class="divider" />
            <a
              ngbDropdownItem
              href="javascript:void(0)"
              (click)="logout()"
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="header-logout" class="item-icon"></mat-icon>
              <span class="item-text">Logout</span>
            </a>
          </div>
        </li> -->
        <li
          class="user-settings-dropdown nav-item"
          *ngIf="_userService.user | async"
          ngbDropdown
          container="body"
          display="dynamic"
          placement="bottom-right"
        >
          <a
            class="no-caret-dropdown d-flex align-items-center cursor-pointer"
            style="
              cursor: pointer;
              padding: 12px 0px;
              display: flex;
              flex-direction: row;
              align-items: center;
            "
            ngbDropdownToggle
          >
            <div
              class="px-avatar"
              [class.profile-image]="
                (_userService.user | async).profile_thumbnail_url
              "
              [style.background-image]="
                (_userService.user | async).profile_thumbnail_url
                  ? 'url(' +
                    (_userService.user | async).profile_thumbnail_url +
                    ')'
                  : 'none'
              "
            >
              <span
                *ngIf="!(_userService.user | async).profile_thumbnail_url"
                class="letter"
                >{{ _userService.user | async | firstLetterFromName }}</span
              >
            </div>
            <mat-icon
              svgIcon="down-arrow"
              class="item-icon text-light-600 ml-6px"
            ></mat-icon>
          </a>

          <div ngbDropdownMenu class="px-dropdown-menu">
            <div class="header-area d-flex">
              <div
                class="px-avatar"
                [class.profile-image]="
                  (_userService.user | async).profile_thumbnail_url
                "
                [style.background-image]="
                  (_userService.user | async).profile_thumbnail_url
                    ? 'url(' +
                      (_userService.user | async).profile_thumbnail_url +
                      ')'
                    : 'none'
                "
              >
                <span
                  *ngIf="!(_userService.user | async).profile_thumbnail_url"
                  class="letter"
                  >{{ _userService.user | async | firstLetterFromName }}</span
                >
              </div>

              <div class="d-flex flex-column ml-12px" style="max-width: 200px">
                <span
                  class="fs-body-sm fw-medium text-dark-300 text-truncate"
                  >{{ _userService.user | async | fullNameOfUser }}</span
                >
                <span class="fs-label-sm text-dark-100 text-truncate">{{
                  (_userService.user | async).email
                }}</span>
              </div>
            </div>

            <hr class="divider" />

            <a
              ngbDropdownItem
              [routerLink]="['/account-settings']"
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="cog-wheel" class="item-icon"></mat-icon>
              <span class="item-text text-dark-300 fs-body-sm"
                >Account Settings</span
              >
            </a>
            <a
              ngbDropdownItem
              href="https://help.pixelied.com"
              rel="noopener"
              target="_blank"
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="help-circle" class="item-icon"></mat-icon>
              <span class="item-text text-dark-300 fs-body-sm">Help</span>
            </a>
            <a
              ngbDropdownItem
              href="https://www.facebook.com/groups/417169315953663/"
              rel="noopener"
              target="_blank"
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="help-circle" class="item-icon"></mat-icon>
              <span class="item-text text-dark-300 fs-body-sm"
                >Join Facebook Group</span
              >
            </a>
            <hr class="divider" />
            <a
              href="javascript:void(0)"
              (click)="_userService.logoutUserSession()"
              ngbDropdownItem
              class="dropdown-item d-flex"
            >
              <mat-icon svgIcon="logout" class="item-icon"></mat-icon>
              <span class="item-text text-dark-300 fs-body-sm">Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- <header class="header">
  <nav class="w-100 d-flex align-items-center">
    <a [routerLink]="['/home']">
      <img
        alt="Pixelied Logo Black"
        src="https://static-assets.pixelied.com/logos-v2/px-logo-black.svg"
        width="40"
        height="40"
      />
    </a>

    <div class="left-nav d-flex align-items-center ml-32px">
      <a class="fs-body-sm text-dark-300" href="/home">Home</a>
      <a class="fs-body-sm text-dark-300" href="/templates">Templates</a>
      <a class="fs-body-sm text-dark-300" href="/templates">My Designs</a>
      <a class="fs-body-sm text-dark-300" href="/mockups">Mockups</a>
      <a class="fs-body-sm text-dark-300" href="/convert/">Convert</a>
    </div>

    <div class="right-nav d-flex align-items-center">
      <button
        *ngIf="
          (_userService.user | async) &&
          !(_workspacesStoreService.workspaceSubscriptionStatus$ | async).active
        "
        (click)="openProCheckoutModal()"
        class="px-btn px-btn-secondary px-btn-md"
      >
        <mat-icon
          svgIcon="bolt"
          class="text-yellow-300 w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-4px">Upgrade Plan</span>
      </button>
      <div
        *ngIf="
          (_userService.user | async) &&
          !(_workspacesStoreService.workspaceSubscriptionStatus$ | async).active
        "
        class="vr-separator ml-12px mr-12px"
      ></div>

      <button class="px-btn px-btn-primary px-btn-md mr-12px">
        <mat-icon
          svgIcon="plus-thin"
          class="text-white w-20px h-20px fs-label-sm"
        ></mat-icon>
        <span class="ml-4px">Create a design</span>
      </button>
      <div
        class="user-settings-dropdown"
        *ngIf="_userService.user | async"
        ngbDropdown
        container="body"
        display="dynamic"
        placement="bottom-right"
      >
        <div
          class="no-caret-dropdown d-flex align-items-center cursor-pointer"
          ngbDropdownToggle
        >
          <div
            class="px-avatar"
            [class.profile-image]="
              (_userService.user | async).profile_thumbnail_url
            "
            [style.background-image]="
              (_userService.user | async).profile_thumbnail_url
                ? 'url(' +
                  (_userService.user | async).profile_thumbnail_url +
                  ')'
                : 'none'
            "
          >
            <span
              *ngIf="!(_userService.user | async).profile_thumbnail_url"
              class="letter"
              >{{ _userService.user | async | firstLetterFromName }}</span
            >
          </div>
          <mat-icon
            svgIcon="down-arrow"
            class="item-icon text-light-600 ml-6px"
          ></mat-icon>
        </div>

        <div ngbDropdownMenu class="px-dropdown-menu">
          <div class="header-area d-flex">
            <div
              class="px-avatar"
              [class.profile-image]="
                (_userService.user | async).profile_thumbnail_url
              "
              [style.background-image]="
                (_userService.user | async).profile_thumbnail_url
                  ? 'url(' +
                    (_userService.user | async).profile_thumbnail_url +
                    ')'
                  : 'none'
              "
            >
              <span
                *ngIf="!(_userService.user | async).profile_thumbnail_url"
                class="letter"
                >{{ _userService.user | async | firstLetterFromName }}</span
              >
            </div>

            <div class="d-flex flex-column ml-12px" style="max-width: 200px">
              <span class="fs-body-sm fw-medium text-dark-300 text-truncate">{{
                _userService.user | async | fullNameOfUser
              }}</span>
              <span class="fs-label-sm text-dark-100 text-truncate">{{
                (_userService.user | async).email
              }}</span>
            </div>
          </div>

          <hr class="divider" />

          <a
            ngbDropdownItem
            [routerLink]="['/account-settings']"
            class="dropdown-item d-flex"
          >
            <mat-icon svgIcon="cog-wheel" class="item-icon"></mat-icon>
            <span class="item-text text-dark-300 fs-body-sm"
              >Account Settings</span
            >
          </a>
          <a
            ngbDropdownItem
            href="https://help.pixelied.com"
            rel="noopener"
            target="_blank"
            class="dropdown-item d-flex"
          >
            <mat-icon svgIcon="help-circle" class="item-icon"></mat-icon>
            <span class="item-text text-dark-300 fs-body-sm">Help</span>
          </a>
          <a
            ngbDropdownItem
            href="https://www.facebook.com/groups/417169315953663/"
            rel="noopener"
            target="_blank"
            class="dropdown-item d-flex"
          >
            <mat-icon svgIcon="help-circle" class="item-icon"></mat-icon>
            <span class="item-text text-dark-300 fs-body-sm"
              >Join Facebook Group</span
            >
          </a>
          <hr class="divider" />
          <a
            href="javascript:void(0)"
            (click)="_userService.logoutUserSession()"
            ngbDropdownItem
            class="dropdown-item d-flex"
          >
            <mat-icon svgIcon="logout" class="item-icon"></mat-icon>
            <span class="item-text text-dark-300 fs-body-sm">Logout</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</header> -->
