import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserService } from 'src/app/modules/auth/data/user.service';
import { StepIds } from '../../data/onboarding.model';
import { OnboardingService } from '../../data/onboarding.service';

@Component({
  selector: 'app-onboarding-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss']
})
export class CreateWorkspaceComponent implements OnInit {

  errorMsg: string = "";
  loading: boolean = false;
  constructor(
    public onboardingService: OnboardingService,
    private _userService: UserService,
    private _toastService: ToastService) { }

  ngOnInit(): void { }

  createWorkspace(workspaceName: string) {
    if (this.loading) return;
    if (workspaceName.trim() == "") return this.errorMsg = "Please enter a workspace name";
    this.loading = true;
    this.onboardingService.createOrUpdateWorkspace({
      name: workspaceName.trim(),
      owner: this._userService.getUserId()
    }).subscribe((data: any) => {
      this.loading = false;
      this.onboardingService.currentOnboardingStep = this.onboardingService.getStepByStepId(StepIds.USER_DESCRIPTION);
    }, (error: any) => {
      this.loading = false;
      this._toastService.show("An error occurred, please try again", { classname: 'bg-danger text-light', delay: 5000 });
    })
  }

}
