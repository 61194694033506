import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
    selector: 'app-toasts-container',
    templateUrl: './toasts-container.component.html',
    styleUrls: ['./toasts-container.component.scss']
})
export class ToastsContainerComponent implements OnInit {

    constructor(public _toastService: ToastService) { }

    ngOnInit() {
    }

}